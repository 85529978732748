<template>
  <b-form-group :label="attributes['replace-label']">
    <b-form-radio-group
      id="radio-group"
      v-model="context.model"
      v-bind="attributes">
      <b-form-radio
        v-for="(option, i) in attributes['radio-options']"
        :key="i"
        :value="option.value"
        :disabled="option.disabled">
        {{ option.text }}
      </b-form-radio>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'

export default {
  name: 'BoostrapRadio',
  mixins: [
    FormulateInputMixin
  ]
}
</script>
