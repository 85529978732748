export interface UsersModuleState {
  users: any[],
  currentUser: any,
  user: any
}

export const state: UsersModuleState = {
  users: [],
  currentUser: null,
  user: null
}

export default state
