import moment from 'moment'

export function activityIsParent (crmId: string, activities: Array<any>) {
  return activities.some(activity => activity.parentId === crmId)
}

export function capitalizeFirstLetter (string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getFirstDayOfYear () {
  return moment().startOf('year').format('YYYY-MM-DD')
}

export function getLastDayOfYear () {
  return moment().endOf('year').format('YYYY-MM-DD')
}

export function monthDiff (d1, d2) {
  let months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}

export function dateRange (startDate, endDate) {
  const start = startDate.split('-')
  const end = endDate.split('-')
  const startYear = parseInt(start[0])
  const endYear = parseInt(end[0])
  const dates: any[] = []

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1
    const startMon = i === startYear ? parseInt(start[1]) - 1 : 0
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1
      const displayMonth = month < 10 ? '0' + month : month
      const date = new Date(`${i}-${displayMonth}-01`)
      dates.push(capitalizeFirstLetter(date.toLocaleString('default', { month: 'long' })) + ` (${i})`)
    }
  }
  return dates
}
