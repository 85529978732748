export const ActivityState = {
  NOT_STARTED: 'NotStarted',
  STARTED: 'Started',
  FINISHED: 'Finished',
  DELAYED: 'Delayed',
  DISCARDED: 'Discarded'
}

export const CalendarViewRange = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
}

export const GraphColors = [
  'rgb(54,73,93)',
  'rgb(255,99,132)',
  'rgb(71, 183,132)',
  'rgb(255,156,37)'
]
