<template>
  <Widget :title="title">
    <template #headerRight>
      <slot name="headerRight"></slot>
    </template>
    <BarChart
      :chart-data="chartData"
      :options="options"
      :stacked="stacked"
    />
  </Widget>
</template>

<script>
import { Widget } from '../Widget'
import BarChart from './BarChart'

export default {
  name: 'BarChartWidget',
  props: ['title', 'chartData', 'stacked', 'options'],
  components: { Widget, BarChart }
}
</script>

<style scoped>

</style>
