<template>
  <b-modal
    :id="id"
    :title="this.title"
    centered>
    {{ message }}
    <template #modal-footer>
      <div class="float-right">
        <b-button
          size="sm"
          style="margin-right: .5rem"
          @click="() => handleCancel()">
          Cancelar
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="() => handleDelete()">
          Eliminar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteDialog',
  props: ['id', 'title', 'message', 'element', 'show'],
  methods: {
    handleDelete () {
      this.$emit('delete', this.element)
    },
    handleCancel () {
      this.$emit('cancel', this.element)
    }
  }
}
</script>
