<template>
  <li class="menu">
    <router-link :to="redirectTo" data-active="false" class="menu-toggle">
      <div class="base-menu">
        <div class="base-icons">
          <slot name="icon" />
        </div>
        <span>{{ label }}</span>
      </div>
    </router-link>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-chevron-left">
      <polyline points="15 18 9 12 15 6"></polyline>
    </svg>
  </li>
</template>

<script>
export default {
  name: 'SideBarButton',
  props: ['label', 'redirectTo']
}
</script>

<style src="./_SideBar.scss" lang="scss" scoped />
