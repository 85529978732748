<template>
  <AdminPageContainer title='Crear Contacto'>
      <Paper  :style="height">
        <FormulateForm
          @submit="onSubmit"
          v-model="form">
          <div class="row">
          <FormulateInput
            name="name"
            label="Nombre"
            placeholder="Nombre"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="lastname"
            label="Apellidos"
            placeholder="Apellidos"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row">
          <FormulateInput
            name="dni"
            label="DNI"
            placeholder="DNI"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="fechaNacimiento"
            label="Fecha Nacimiento"
            placeholder="YYYY-mm-dd"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="prefijo"
            label="Prefijo"
            type="autocomplete"
            :options="prefixes"
            :multiple="false"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            track-by="id"
            deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
            placeholder="Prefijo"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="phone"
            label="Móvil"
            placeholder="Móvil"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="email"
            label="Correo"
            placeholder="Correo"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="userStatus"
            label="Estado"
            type="autocomplete"
            :options="userStates"
            :multiple="false"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            track-by="id"
            deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
            placeholder="Estado"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="profesion"
            label="Profesión"
            placeholder="Profesión"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="corredorHabitual"
            type="checkbox"
            label="Corredor Habitual"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="voluntarioConAnteriodidad"
            type="checkbox"
            label="Voluntario Anteriores Eventos"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="evento_anterior"
            label="Evento Anterior y función"
            placeholder="Evento Anterior y función"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
          name="informacion_alimentaria"
          label="Información alimentaria de tus día de voluntario"
          type="autocomplete"
          :options="informacion_alimentaria"
          :multiple="false"
          :preserve-search="true"
          :close-on-select="true"
          :clear-on-select="false"
          track-by="id"
          deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
          placeholder="Información alimentaria de tus día de voluntario"
          class="col-md-6 col-12"
          />
          <FormulateInput
            name="alergias_alimentarias"
            label="Alergias Alimentarias"
            placeholder="Alergias Alimentarias"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="camiseta"
            label="Talla de camiseta"
            placeholder="Talla de camiseta"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="asociacion"
            label="Grupo, club o asociación"
            placeholder="Grupo, club o asociación"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="disponeConducir"
            type="checkbox"
            label="Dispone de Carnet"
            class="col-md-6 col-12"
          />
          <FormulateInput
          name="aportaVehiculo"
          label="Puede Aportar Vehículo"
          type="autocomplete"
          :options="vehiculoOptions"
          :multiple="false"
          :preserve-search="true"
          :close-on-select="false"
          :clear-on-select="false"
          track-by="id"
          deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
          placeholder="Puede Aportar Vehículo"
          class="col-md-6 col-12"
        />
        </div>
        <div class="row mt-3">
          <FormulateInput
              name="disponeAlojamiento"
              type="checkbox"
              label="Dispone de alojamiento en la comarca"
              class="col-md-6 col-12"
            />
          <FormulateInput
              name="opcionAlojamiento"
              label="Opciones Alojamiento"
              type="autocomplete"
              :options="alojamientoOptions"
              :multiple="false"
              :preserve-search="true"
              :close-on-select="true"
              :clear-on-select="false"
              track-by="id"
              placeholder="Opciones Alojamiento"
              class="col-md-6 col-12"
            />
        </div>
        <div class="row mt-3">
          <FormulateInput
          name="days"
          label="Disponibilidad Horaria"
          type="autocomplete"
          :options="dayOptions"
          :multiple="true"
          :preserve-search="true"
          :close-on-select="false"
          :clear-on-select="false"
          track-by="id"
          deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
          placeholder="Disponibilidad Horaria"
          class="col-md-6 col-12"
        />
        <FormulateInput
            name="informacion_disponibilidad_c"
            label="Información Disponibilidad"
            type="textarea"
            placeholder="Información Disponibilidad"
            class="col-md-12 col-12"
          />
        <FormulateInput
            name="zone"
            label="Zona preferente"
            type="autocomplete"
            :options="locationOptions"
            :multiple="true"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            track-by="id"
            deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
            placeholder="Zona preferente"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="type"
            label="Tipo de usuario"
            type="autocomplete"
            :options="userTypes"
            :multiple="false"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            track-by="id"
            deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
            placeholder="Tipo de usuario"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="actividades"
            label="Actividades en las que quiere participar"
            type="autocomplete"
            :options="actividadesOptions"
            :multiple="true"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            track-by="id"
            deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
            placeholder="Actividades en las que quiere participar"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="observaciones"
            label="Observaciones"
            type="textarea"
            placeholder="Observaciones"
            class="col-md-12 col-12"
          />
        </div>
            <hr>
          <FormulateInput
              v-show="showButton"
              style="display: inline-block;"
              type="submit"
              variant="primary"
              label="Crear Contacto"
              class="mt-2"
            />
        </FormulateForm>
      </Paper>
      <div class="loader-container"  v-if="loader">
        <vue-loaders name="ball-clip-rotate-pulse" color="black" scale="1"></vue-loaders>
      </div>
  </AdminPageContainer>
  </template>

<script>
import { mapGetters } from 'vuex'
import { AdminPageContainer, Paper } from '@/components'
import { CheckDisponibilidad } from '../ProjectsPage/components/CheckDisponibilidad'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'CreateContact',
  components: { AdminPageContainer, Paper },
  data () {
    return {
      userStates: [
        { id: 'pendiente', name: 'Pendiente de revisar' },
        { id: 'activo', name: 'Activo' },
        { id: 'inactivo', name: 'Inactivo' }
      ],
      userTypes: [
        { id: 'director', name: 'Director Utmac' },
        { id: 'coordinador', name: 'Coordinador de voluntarios' },
        { id: 'voluntario', name: 'Voluntario' }
      ],
      informacion_alimentaria: [
        { id: 'Normal', name: 'Normal' },
        { id: 'Vegetariana', name: 'Vegetariana' },
        { id: 'Vegana', name: 'Vegana' },
        { id: 'Celiaco', name: 'Celiaco' },
        { id: 'Alergias Alimentarias (especificar en el siguiente apartado)', name: 'Alergias Alimentarias' }
      ],
      actividadesOptions: [
        { id: 'DIRECCIÓN (NO RELLENAR, SOLO ORGANIZACIÓN)', name: 'DIRECCIÓN (NO RELLENAR, SOLO ORGANIZACIÓN)' },
        { id: 'AVITUALLAMENTOS - PUNTOS CONTROL', name: 'AVITUALLAMENTOS - PUNTOS CONTROL' },
        { id: 'EQUIPO TRAZA -  VIAS - CARRETERA', name: 'EQUIPO TRAZA -  VIAS - CARRETERA' },
        { id: 'EQUIPO MONTAÑA - MARCAJE - SEGURIDAD', name: 'EQUIPO MONTAÑA - MARCAJE - SEGURIDAD' },
        { id: 'DORSALES - INSCRIPCIONES - ATENCION CORREDOR', name: 'DORSALES - INSCRIPCIONES - ATENCION CORREDOR' },
        { id: 'ATENCION VOLUNTARIOS', name: 'ATENCION VOLUNTARIOS' },
        { id: 'EQUIPO INFRAESTRUCTURAS SALIDAS', name: 'EQUIPO INFRAESTRUCTURAS SALIDAS' },
        { id: 'EQUIPO INFRAESTRUCTURAS META PRINCIPAL', name: 'EQUIPO INFRAESTRUCTURAS META PRINCIPAL' }
      ],
      dayOptions: [
        { id: 'Lunes 22 todo el dia', name: 'Lunes 22 todo el dia' },
        { id: 'Lunes 22 Mañana', name: 'Lunes 22 Mañana' },
        { id: 'Lunes 22 Tarde', name: 'Lunes 22 Tarde' },
        { id: 'Lunes 22 Noche', name: 'Lunes 22 Noche' },
        { id: 'Martes 23 todo el dia', name: 'Martes 23 todo el dia' },
        { id: 'Martes 23 Mañana', name: 'Martes 23 Mañana' },
        { id: 'Martes 23 Tarde', name: 'Martes 23 Tarde' },
        { id: 'Martes 23 Noche', name: 'Martes 23 Noche' },
        { id: 'Miercoles 24 todo el dia', name: 'Miercoles 24 todo el dia' },
        { id: 'Miercoles 24 Mañana', name: 'Miercoles 24 Mañana' },
        { id: 'Miercoles 24 Tarde', name: 'Miercoles 24 Tarde' },
        { id: 'Miercoles 24 Noche', name: 'Miercoles 24 Noche' },
        { id: 'Jueves 25 todo el dia', name: 'Jueves 25 todo el dia' },
        { id: 'Jueves 25 Mañana', name: 'Jueves 25 Mañana' },
        { id: 'Jueves 25 Tarde', name: 'Jueves 25 Tarde' },
        { id: 'Jueves 25 Noche', name: 'Jueves 25 Noche' },
        { id: 'Viernes 26 todo el dia', name: 'Viernes 26 todo el dia' },
        { id: 'Viernes 26 Mañana', name: 'Viernes 26 Mañana' },
        { id: 'Viernes 26 Tarde', name: 'Viernes 26 Tarde' },
        { id: 'Viernes 26 Noche', name: 'Viernes 26 Noche' },
        { id: 'Sabado 27 todo el dia', name: 'Sabado 27 todo el dia' },
        { id: 'Sabado 27 Mañana', name: 'Sabado 27 Mañana' },
        { id: 'Sabado 27 Tarde', name: 'Sabado 27 Tarde' },
        { id: 'Sabado 27 Noche', name: 'Sabado 27 Noche' },
        { id: 'Domingo 28 todo el dia', name: 'Domingo 28 todo el dia' },
        { id: 'Domingo 28 Mañana', name: 'Domingo 28 Mañana' },
        { id: 'Domingo 28 Tarde', name: 'Domingo 28 Tarde' },
        { id: 'Domingo 28 Noche', name: 'Domingo 28 Noche' },
        { id: 'Disponibilidad Completa', name: 'Disponibilidad Completa' }
      ],
      locationOptions: [
        { id: 'agres', name: 'Agres' },
        { id: 'alcoi', name: 'Alcoi' },
        { id: 'Alcoleja', name: 'Alcoleja' },
        { id: 'banyeres', name: 'Banyeres' },
        { id: 'benassau', name: 'Benassau' },
        { id: 'beniarres', name: 'Beniarres' },
        { id: 'benifallim', name: 'Benifallim' },
        { id: 'beniatjar', name: 'Beniatjar' },
        { id: 'cocentaina', name: 'Cocentaina' },
        { id: 'gorga', name: 'Gorga' },
        { id: 'millena', name: 'Millena' },
        { id: 'muro', name: 'Muro' },
        { id: 'penaguila', name: 'Penaguila' },
        { id: 'quatretondeta', name: 'Quatretondeta' }
      ],
      prefixes: [
        { id: '93', name: '+93 Afganistán' },
        { id: '355', name: '+355 Albania' },
        { id: '49', name: '+49 Alemania' },
        { id: '213', name: '+213 Algeria' },
        { id: '376', name: '+376 Andorra' },
        { id: '244', name: '+244 Angola' },
        { id: '1 264', name: '+1 264 Anguila' },
        { id: '672', name: '+672 Antártida / Isla Norfolk' },
        { id: '1 268', name: '+1 268 Antigua y Barbuda' },
        { id: '599', name: '+599 Antillas Neerlandesas' },
        { id: '966', name: '+966 Arabia Saudita' },
        { id: '54', name: '+54 Argentina' },
        { id: '374', name: '+374 Armenia' },
        { id: '297', name: '+297 Aruba' },
        { id: '61', name: '+61 Australia / Islas Heard y McDonald' },
        { id: '43', name: '+43 Austria' },
        { id: '994', name: '+994 Azerbayán' },
        { id: '32', name: '+32 Bélgica' },
        { id: '1 242', name: '+1 242 Bahamas' },
        { id: '973', name: '+973 Bahrein' },
        { id: '880', name: '+880 Bangladesh' },
        { id: '1 246', name: '+1 246 Barbados' },
        { id: '501', name: '+501 Belice' },
        { id: '229', name: '+229 Benín' },
        { id: '975', name: '+975 Bhután' },
        { id: '375', name: '+375 Bielorrusia' },
        { id: '95', name: '+95 Birmania' },
        { id: '591', name: '+591 Bolivia' },
        { id: '387', name: '+387 Bosnia y Herzegovina' },
        { id: '267', name: '+267 Botsuana' },
        { id: '55', name: '+55 Brasil' },
        { id: '673', name: '+673 Brunéi' },
        { id: '359', name: '+359 Bulgaria' },
        { id: '226', name: '+226 Burkina Faso' },
        { id: '257', name: '+257 Burundi' },
        { id: '238', name: '+238 Cabo Verde' },
        { id: '855', name: '+855 Camboya' },
        { id: '237', name: '+237 Camerún' },
        { id: '1', name: '+1 Canadá / USA / Puerto Rico' },
        { id: '235', name: '+235 Chad' },
        { id: '56', name: '+56 Chile' },
        { id: '86', name: '+86 China' },
        { id: '357', name: '+357 Chipre' },
        { id: '39', name: '+39 Ciudad del Vaticano' },
        { id: '57', name: '+57 Colombia' },
        { id: '269', name: '+269 Comoras' },
        { id: '242', name: '+242 Congo' },
        { id: '243', name: '+243 Congo' },
        { id: '850', name: '+850 Corea del Norte' },
        { id: '82', name: '+82 Corea del Sur' },
        { id: '225', name: '+225 Costa de Marfil' },
        { id: '506', name: '+506 Costa Rica' },
        { id: '385', name: '+385 Croacia' },
        { id: '53', name: '+53 Cuba' },
        { id: '45', name: '+45 Dinamarca' },
        { id: '1 767', name: '+1 767 Dominica' },
        { id: '593', name: '+593 Ecuador' },
        { id: '20', name: '+20 Egipto' },
        { id: '503', name: '+503 El Salvador' },
        { id: '971', name: '+971 Emiratos Árabes Unidos' },
        { id: '291', name: '+291 Eritrea' },
        { id: '421', name: '+421 Eslovaquia' },
        { id: '386', name: '+386 Eslovenia' },
        { id: '34', name: '+34 España' },
        { id: '372', name: '+372 Estonia' },
        { id: '251', name: '+251 Etiopía' },
        { id: '63', name: '+63 Filipinas' },
        { id: '358', name: '+358 Finlandia' },
        { id: '679', name: '+679 Fiyi' },
        { id: '33', name: '+33 Francia / Territorios Australes y Antárticas Franceses' },
        { id: '241', name: '+241 Gabón' },
        { id: '220', name: '+220 Gambia' },
        { id: '995', name: '+995 Georgia' },
        { id: '233', name: '+233 Ghana' },
        { id: '350', name: '+350 Gibraltar' },
        { id: '1 473', name: '+1 473 Granada' },
        { id: '30', name: '+30 Grecia' },
        { id: '299', name: '+299 Groenlandia' },
        { id: '1 671', name: '+1 671 Guam' },
        { id: '502', name: '+502 Guatemala' },
        { id: '594', name: '+594 Guayana Francesa' },
        { id: '224', name: '+224 Guinea' },
        { id: '240', name: '+240 Guinea Ecuatorial' },
        { id: '245', name: '+245 Guinea-Bissau' },
        { id: '592', name: '+592 Guyana' },
        { id: '509', name: '+509 Haití' },
        { id: '504', name: '+504 Honduras' },
        { id: '852', name: '+852 Hong kong' },
        { id: '36', name: '+36 Hungría' },
        { id: '91', name: '+91 India' },
        { id: '62', name: '+62 Indonesia' },
        { id: '98', name: '+98 Irán' },
        { id: '964', name: '+964 Irak' },
        { id: '353', name: '+353 Irlanda' },
        { id: '44', name: '+44 Isla de Man / Reino Unido / Guernsey / Jersey' },
        { id: '354', name: '+354 Islandia' },
        { id: '1 441', name: '+1 441 Islas Bermudas' },
        { id: '1 345', name: '+1 345 Islas Caimán' },
        { id: '682', name: '+682 Islas Cook' },
        { id: '298', name: '+298 Islas Feroe' },
        { id: '960', name: '+960 Islas Maldivas' },
        { id: '500', name: '+500 Islas Malvinas / Islas Georgias del Sur y Sandwich del Sur' },
        { id: '1 670', name: '+1 670 Islas Marianas del Norte' },
        { id: '692', name: '+692 Islas Marshall' },
        { id: '870', name: '+870 Islas Pitcairn' },
        { id: '677', name: '+677 Islas Salomón' },
        { id: '1 649', name: '+1 649 Islas Turcas y Caicos' },
        { id: '246', name: '+246 Islas Ultramarinas Menores de Estados Unidos / Territorio Británico del Océano Índico' },
        { id: '1 284', name: '+1 284 Islas Vírgenes Británicas' },
        { id: '1 340', name: '+1 340 Islas Vírgenes de los Estados Unidos' },
        { id: '972', name: '+972 Israel' },
        { id: '39', name: '+39 Italia' },
        { id: '1 876', name: '+1 876 Jamaica' },
        { id: '81', name: '+81 Japón' },
        { id: '962', name: '+962 Jordania' },
        { id: '7', name: '+7 Kazajistán' },
        { id: '254', name: '+254 Kenia' },
        { id: '996', name: '+996 Kirgizstán' },
        { id: '686', name: '+686 Kiribati' },
        { id: '965', name: '+965 Kuwait' },
        { id: '961', name: '+961 Líbano' },
        { id: '856', name: '+856 Laos' },
        { id: '266', name: '+266 Lesoto' },
        { id: '371', name: '+371 Letonia' },
        { id: '231', name: '+231 Liberia' },
        { id: '218', name: '+218 Libia' },
        { id: '423', name: '+423 Liechtenstein' },
        { id: '370', name: '+370 Lituania' },
        { id: '352', name: '+352 Luxemburgo' },
        { id: '52', name: '+52 México' },
        { id: '377', name: '+377 Mónaco' },
        { id: '853', name: '+853 Macao' },
        { id: '389', name: '+389 Macedonia' },
        { id: '261', name: '+261 Madagascar' },
        { id: '60', name: '+60 Malasia' },
        { id: '265', name: '+265 Malawi' },
        { id: '223', name: '+223 Mali' },
        { id: '356', name: '+356 Malta' },
        { id: '212', name: '+212 Marruecos / Sahara Occidental' },
        { id: '596', name: '+596 Martinica' },
        { id: '230', name: '+230 Mauricio' },
        { id: '222', name: '+222 Mauritania' },
        { id: '262', name: '+262 Mayotte / Reunión' },
        { id: '691', name: '+691 Micronesia' },
        { id: '373', name: '+373 Moldavia' },
        { id: '976', name: '+976 Mongolia' },
        { id: '382', name: '+382 Montenegro' },
        { id: '1 664', name: '+1 664 Montserrat' },
        { id: '258', name: '+258 Mozambique' },
        { id: '264', name: '+264 Namibia' },
        { id: '674', name: '+674 Nauru' },
        { id: '977', name: '+977 Nepal' },
        { id: '505', name: '+505 Nicaragua' },
        { id: '227', name: '+227 Níger' },
        { id: '234', name: '+234 Nigeria' },
        { id: '683', name: '+683 Niue' },
        { id: '47', name: '+47 Noruega / Isla Bouvet / Svalbard y Jan Mayen' },
        { id: '687', name: '+687 Nueva Caledonia' },
        { id: '64', name: '+64 Nueva Zelanda' },
        { id: '968', name: '+968 Omán' },
        { id: '31', name: '+31 Países Bajos' },
        { id: '92', name: '+92 Pakistán' },
        { id: '680', name: '+680 Palaos' },
        { id: '970', name: '+970 Palestina' },
        { id: '507', name: '+507 Panamá' },
        { id: '675', name: '+675 Papúa Nueva Guinea' },
        { id: '595', name: '+595 Paraguay' },
        { id: '51', name: '+51 Perú' },
        { id: '689', name: '+689 Polinesia Francesa' },
        { id: '48', name: '+48 Polonia' },
        { id: '351', name: '+351 Portugal' },
        { id: '974', name: '+974 Qatar' },
        { id: '236', name: '+236 República Centroafricana' },
        { id: '420', name: '+420 República Checa' },
        { id: '1 809', name: '+1 809 República Dominicana' },
        { id: '250', name: '+250 Ruanda' },
        { id: '40', name: '+40 Rumanía' },
        { id: '7', name: '+7 Rusia' },
        { id: '685', name: '+685 Samoa' },
        { id: '1 684', name: '+1 684 Samoa Americana' },
        { id: '590', name: '+590 San Bartolomé / Guadalupe' },
        { id: '1 869', name: '+1 869 San Cristóbal y Nieves' },
        { id: '378', name: '+378 San Marino' },
        { id: '1 599', name: '+1 599 San Martín (Francia)' },
        { id: '508', name: '+508 San Pedro y Miquelón' },
        { id: '1 784', name: '+1 784 San Vicente y las Granadinas' },
        { id: '290', name: '+290 Santa Elena' },
        { id: '1 758', name: '+1 758 Santa Lucía' },
        { id: '239', name: '+239 Santo Tomé y Príncipe' },
        { id: '221', name: '+221 Senegal' },
        { id: '381', name: '+381 Serbia' },
        { id: '248', name: '+248 Seychelles' },
        { id: '232', name: '+232 Sierra Leona' },
        { id: '65', name: '+65 Singapur' },
        { id: '963', name: '+963 Siria' },
        { id: '252', name: '+252 Somalia' },
        { id: '94', name: '+94 Sri Lanka' },
        { id: '27', name: '+27 Sudáfrica' },
        { id: '249', name: '+249 Sudán' },
        { id: '46', name: '+46 Suecia' },
        { id: '41', name: '+41 Suiza' },
        { id: '597', name: '+597 Surinám' },
        { id: '268', name: '+268 Swazilandia' },
        { id: '992', name: '+992 Tadjikistán' },
        { id: '66', name: '+66 Tailandia' },
        { id: '886', name: '+886 Taiwán' },
        { id: '255', name: '+255 Tanzania' },
        { id: '670', name: '+670 Timor Oriental' },
        { id: '228', name: '+228 Togo' },
        { id: '690', name: '+690 Tokelau' },
        { id: '676', name: '+676 Tonga' },
        { id: '1 868', name: '+1 868 Trinidad y Tobago' },
        { id: '216', name: '+216 Tunez' },
        { id: '993', name: '+993 Turkmenistán' },
        { id: '90', name: '+90 Turquía' },
        { id: '688', name: '+688 Tuvalu' },
        { id: '380', name: '+380 Ucrania' },
        { id: '256', name: '+256 Uganda' },
        { id: '598', name: '+598 Uruguay' },
        { id: '998', name: '+998 Uzbekistán' },
        { id: '678', name: '+678 Vanuatu' },
        { id: '58', name: '+58 Venezuela' },
        { id: '84', name: '+84 Vietnam' },
        { id: '681', name: '+681 Wallis y Futuna' },
        { id: '967', name: '+967 Yemen' },
        { id: '253', name: '+253 Yibuti' },
        { id: '260', name: '+260 Zambia' },
        { id: '263', name: '+263 Zimbabue' }
      ],
      vehiculoOptions: [
        { id: 'Si', name: 'Si' },
        { id: 'No', name: 'No' },
        { id: 'Furgoneta con posibilidad de trasladar voluntarios, llevar materiales, etc.', name: 'Furgoneta con posibilidad de trasladar voluntarios, llevar materiales, etc.' },
        { id: 'Furgón o camioneta de carga de materiales, etc.', name: 'Furgón o camioneta de carga de materiales, etc.' }
      ],
      alojamientoOptions: [
        { id: 'ofrecer', name: 'Que se te ofrezca uno de los alojamientos de la organización a coste cero' },
        { id: 'encontrar', name: 'Encontrar su propio alojamiento' }
      ],
      tag: '',
      form: {
        etiquetas: []
      },
      tags: [
      ],
      showButton: true,
      defaultPrefixe: [{ id: '34', name: '+34 España' }],
      loader: false
    }
  },
  methods: {
    onSubmit () {
      this.loader = true
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      this.showButton = false
      formData.append('action', 'createUser')
      formData.append('userData', JSON.stringify(this.form))
      axios
        .post(entryPoint, formData)
        .then((response) => {
          if (response.data) {
            if (response.data.error) {
              this.loader = false
              Swal.fire({
                icon: 'warning',
                title: response.data.error,
                showConfirmButton: false,
                timer: 1500
              })
            } else {
              this.loader = false
              Swal.fire({
                icon: 'success',
                title: response.data.error,
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                this.$router.push('/contacts')
              })
            }
          } else {
            this.loader = false
            Swal.fire({
              icon: 'error',
              title: 'Unexpected response from the server',
              showConfirmButton: false,
              timer: 1500
            })
          }
        })
      this.showButton = true
    }
  },
  computed: {
    height () {
      return document.body.clientWidth < 1000 ? 'height: 100%' : 'height: auto'
    },
    filteredItems () {
      return this.autocompleteItems?.filter(i => {
        return i.text?.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
      })
    }
  }
}
</script>
<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.loader {
  width: 50px;
  height: 50px;
}
</style>
