<template>
  <div class="widget-root">
    <div v-if="title" class="widget__header">
      <div class="widget__header-left">
        <h5>{{ title }}</h5>
      </div>
      <div class="widget__header-right">
        <slot name="headerRight"></slot>
      </div>
    </div>
    <div :class="`widget__body ${!title ? 'upper-padding' : ''}`" :style="paddingBody ? { padding: paddingBody } : {}">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Widget',
  props: ['title', 'paddingBody']
}
</script>

<style scoped lang="scss">
.widget-root {
  flex-grow: 1;
  min-height: 0;
  height: 100%;

  border-radius: .5rem;
  background-color: white;

  .widget__header {
    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    margin-bottom: 1rem;

    h5 {
      margin: 0;
      padding: 0;
    }

    .widget__header-left {
      display: flex;
      flex: 2;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .widget__header-right {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .widget__body {
    padding: 0 2rem 2rem;

    &.upper-padding {
      padding: 2rem 2rem;
    }
  }
}
</style>
