<template>
  <b-modal
    id="upload-file"
    size="lg"
    title="Subir archivos"
    scrollable
    no-close-on-backdrop
    no-close-on-esc
    centered>

      <UploadFile @fileschange="handleFilesChange"></UploadFile>
      <template #modal-footer>
        <div class="w-100">
          <div class="w-50 float-left">
            <select class="custom-select" v-model="activity">
              <option selected value="0">Selecciona un proyecto</option>
              <option v-for="(act, index) in getactivities" :key="index" :value="act"> {{ act.name }}</option>
            </select>
          </div>
          <div class="float-right mt-1">
            <b-button
              size="sm"
              style="margin-right: .5rem"
              @click="handleCancel"
              >
              Cancelar
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              @click="handleSubmit">
              Guardar
            </b-button>
          </div>
        </div>
      </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UploadFileDialog',
  data () {
    return {
      files: [],
      activity: 0
    }
  },
  methods: {
    handleSubmit () {
      this.$emit('submit', this.files, this.activity)
      this.activity = 0
    },
    handleCancel () {
      this.$emit('cancel')
    },
    handleFilesChange (files) {
      this.files = files
    }
  },
  computed: {
    ...mapGetters(['getProject']),
    getactivities: function () {
      return this.getProject.activities
    }

  }
}
</script>
