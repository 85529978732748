<template>
  <AdminPageContainer title='Actualizar Contacto'>
    <Paper :style="height">
      <h4 class="ml-3">Perfil del Contacto</h4>
      <FormulateForm
        @submit="onSubmit"
        v-model="form">
        <div class="row">
          <FormulateInput
            name="name"
            label="Nombre"
            placeholder="Nombre"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="lastname"
            label="Apellidos"
            placeholder="Apellidos"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="userStatus"
            label="Estado"
            type="autocomplete"
            :options="userStates"
            :multiple="false"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            track-by="id"
            deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
            placeholder="Estado"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="disponeConducir"
            type="checkbox"
            label="Dispone de Carnet"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="phone"
            label="Móvil"
            placeholder="Móvil"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            type="date"
            name="fechaNacimiento"
            label="Fecha nacimiento"
            help="Fecha nacimiento"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="dni"
            label="DNI"
            placeholder="DNI"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="asociacion"
            label="Grupo, club o asociación"
            placeholder="Grupo, club o asociación"
            class="col-md-6 col-12"
          />
          <FormulateInput
            name="camiseta"
            label="Talla de camiseta"
            placeholder="Talla de camiseta"
            class="col-md-6 col-12"
          />
        </div>
        <div class="row mt-3">
          <FormulateInput
            name="email"
            label="Correo"
            placeholder="Correo"
            class="col-md-6 col-12"
          />
        </div>
        <hr />
        <h4 class="ml-3">Función</h4>
        <div class="row mt-3">
          <FormulateInput
            name="type"
            label="Tipo de usuario"
            type="autocomplete"
            :options="userTypes"
            :multiple="false"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            track-by="id"
            deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
            placeholder="Tipo de usuario"
            class="col-md-6 col-12"
          />
        </div>
        <hr />
        <h4 class="ml-3">Disponibilidad</h4>
        <div class="row mt-3">
          <FormulateInput
          name="aportaVehiculo"
          label="Puede Aportar Vehículo"
          type="autocomplete"
          :options="vehiculoOptions"
          :multiple="true"
          :preserve-search="true"
          :close-on-select="false"
          :clear-on-select="false"
          track-by="id"
          deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
          placeholder="Puede Aportar Vehículo"
          class="col-md-6 col-12"
        />
        <FormulateInput
          name="days"
          label="Disponibilidad Horaria"
          type="autocomplete"
          :options="dayOptions"
          :multiple="true"
          :preserve-search="true"
          :close-on-select="false"
          :clear-on-select="false"
          track-by="id"
          deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
          placeholder="Disponibilidad Horaria"
          class="col-md-6 col-12"
        />
        </div>
        <hr>
        <FormulateInput
            v-show="showButton"
            style="display: inline-block;"
            type="submit"
            variant="primary"
            label="Actualizar Contacto"
            class="mt-2"
          />
          <b-button variant="primary" class="ml-2" @click="enviarCorreoRecuperacion"> Enviar correo recuperación</b-button>
      </FormulateForm>
    </Paper>
  </AdminPageContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdminPageContainer, Paper } from '@/components'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'UpdateContact',
  components: { AdminPageContainer, Paper },
  data () {
    return {
      userStates: [
        { id: 'pendiente', name: 'Pendiente de revisar' },
        { id: 'activo', name: 'Activo' },
        { id: 'inactivo', name: 'Inactivo' },
        { id: 'duplicado', name: 'Duplicado' }
      ],
      userTypes: [
        { id: 'director', name: 'Director MMUA' },
        { id: 'coordinador', name: 'Coordinador de voluntarios' },
        { id: 'voluntario', name: 'Voluntario' }
      ],
      dayOptions: [
        { id: 'Lunes 11 todo el dia', name: 'Lunes 11 todo el dia' },
        { id: 'Lunes 11 Mañana', name: 'Lunes 11 Mañana' },
        { id: 'Lunes 11 Tarde', name: 'Lunes 11 Tarde' },
        { id: 'Lunes 11 Noche', name: 'Lunes 11 Noche' },
        { id: 'Martes 12 todo el dia', name: 'Martes 12 todo el dia' },
        { id: 'Martes 12 Mañana', name: 'Martes 12 Mañana' },
        { id: 'Martes 12 Tarde', name: 'Martes 12 Tarde' },
        { id: 'Martes 12 Noche', name: 'Martes 12 Noche' },
        { id: 'Miercoles 13 todo el dia', name: 'Miercoles 13 todo el dia' },
        { id: 'Miercoles 13 Mañana', name: 'Miercoles 13 Mañana' },
        { id: 'Miercoles 13 Tarde', name: 'Miercoles 13 Tarde' },
        { id: 'Miercoles 13 Noche', name: 'Miercoles 13 Noche' },
        { id: 'Jueves 14 todo el dia', name: 'Jueves 14 todo el dia' },
        { id: 'Jueves 14 Mañana', name: 'Jueves 14 Mañana' },
        { id: 'Jueves 14 Tarde', name: 'Jueves 14 Tarde' },
        { id: 'Jueves 14 Noche', name: 'Jueves 14 Noche' },
        { id: 'Viernes 15 todo el dia', name: 'Viernes 15 todo el dia' },
        { id: 'Viernes 15 Mañana', name: 'Viernes 15 Mañana' },
        { id: 'Viernes 15 Tarde', name: 'Viernes 15 Tarde' },
        { id: 'Viernes 15 Noche', name: 'Viernes 15 Noche' },
        { id: 'Sabado 16 todo el dia', name: 'Sabado 16 todo el dia' },
        { id: 'Sabado 16 Mañana', name: 'Sabado 16 Mañana' },
        { id: 'Sabado 16 Tarde', name: 'Sabado 16 Tarde' },
        { id: 'Sabado 16 Noche', name: 'Sabado 16 Noche' },
        { id: 'Domingo 17 todo el dia', name: 'Domingo 17 todo el dia' },
        { id: 'Domingo 17 Mañana', name: 'Domingo 17 Mañana' },
        { id: 'Domingo 17 Tarde', name: 'Domingo 17 Tarde' },
        { id: 'Domingo 17 Noche', name: 'Domingo 17 Noche' }
      ],

      vehiculoOptions: [
        { id: 'Si', name: 'Si' },
        { id: 'No', name: 'No' },
        { id: 'Furgoneta con posibilidad de trasladar voluntarios, llevar materiales, etc.', name: 'Furgoneta con posibilidad de trasladar voluntarios, llevar materiales, etc.' },
        { id: 'Furgón o camioneta de carga de materiales, etc.', name: 'Furgón o camioneta de carga de materiales, etc.' }
      ],
      locationOptions: [
        { id: 'agres', name: 'Agres' },
        { id: 'alcoi', name: 'Alcoi' },
        { id: 'Alcoleja', name: 'Alcoleja' },
        { id: 'banyeres', name: 'Banyeres' },
        { id: 'benassau', name: 'Benassau' },
        { id: 'beniarres', name: 'Beniarres' },
        { id: 'benifallim', name: 'Benifallim' },
        { id: 'beniatjar', name: 'Beniatjar' },
        { id: 'cocentaina', name: 'Cocentaina' },
        { id: 'gorga', name: 'Gorga' },
        { id: 'millena', name: 'Millena' },
        { id: 'muro', name: 'Muro' },
        { id: 'penaguila', name: 'Penaguila' },
        { id: 'quatretondeta', name: 'Quatretondeta' }
      ],
      funcionOptions: [
        { id: 'director', name: 'Director' },
        { id: 'coordinador', name: 'Coordinador' },
        { id: 'voluntario', name: 'Voluntario' }
      ],
      form: {},
      showButton: true,
      loader: false

    }
  },
  methods: {
    onSubmit () {
      this.loader = true
      const userId = this.$route.params.contactId
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      this.showButton = false
      formData.append('action', 'modifyUser')
      formData.append('userData', JSON.stringify(this.form))
      formData.append('userId', userId)
      axios
        .post(entryPoint, formData)
        .then((response) => {
          if (response.data.error) {
            this.loader = false
            Swal.fire({
              icon: 'warning',
              title: response.data.error,
              showConfirmButton: false,
              timer: 1500
            })
          } else {
            this.loader = false
            Swal.fire({
              icon: 'success',
              title: 'Contacto Actualizado',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.$router.push('/contacts')
            })
          }
        })
      this.showButton = true
    },
    loadUserData () {
      this.loader = true
      const userId = this.$route.params.contactId
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'getUserData')
      formData.append('userId', userId)
      axios
        .post(entryPoint, formData)
        .then((response) => {
          this.loader = false
          console.log(response.data[0])
          this.form = response.data[0]
        })
    },
    enviarCorreoRecuperacion () {
      this.loader = true
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'recover_pass_send_mail')
      formData.append('email', this.form.email)
      axios
        .post(entryPoint, formData)
        .then((response) => {
          this.loader = true
          Swal.fire({
            icon: 'success',
            title: 'Correo Enviado con éxito',
            showConfirmButton: false,
            timer: 1500
          })
        })
    }
  },
  mounted () {
    this.loadUserData()
  },
  computed: {
    height () {
      return document.body.clientWidth < 1000 ? 'height: 100%' : 'height: auto'
    }
  }
}
</script>
<style scoped lang="scss">
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.loader {
  width: 50px;
  height: 50px;
}
</style>
