<template>
  <FormulateForm
    name="activity-form"
    @submit="handleSubmit"
    v-model="form">
    <FormulateInput
      name="project"
      label="Proyecto"
      type="select"
      v-model="project"
      placeholder="Seleccione un proyecto"
      :options="projectStatusActive.map(g => ({ value: g, text: g.name }))"
    />
    <FormulateInput
      name="activity"
      label="Actividad"
      type="select"
      placeholder="Seleccione una actividad"
      :disabled="project == null"
      :options="activities.map(g => ({ value: g, text: g.name }))"
    />
    <FormulateInput
      name="responsible"
      label="Responsable"
      type="autocomplete"
      :options="getUsers"
      :preserve-search="true"
      :close-on-select="true"
      :clear-on-select="false"
      track-by="id"
      placeholder="Selecciona un usuario"
    />
    <FormulateInput
      name="createdAt"
      label="Fecha"
      type="date"
      label-no-date-selected="Seleccione una fecha"
    />
    <FormulateInput
      name="isWeek"
      type="radio"
      :radio-options="[
        {
          text: 'Semana',
          value: true
        },
        {
          text: 'Día',
          value: false
        }
      ]"
    />
    <p style="margin: .5rem 0" v-if="!!form.isWeek">Semana {{ weekNumber }} del año {{ yearNumber }} </p>
    <b-row>
      <b-col>
        <FormulateInput
          name="hours"
          label="Horas"
          type="number"
          min="0"
        />
      </b-col>
      <b-col>
        <FormulateInput
          name="minutes"
          label="Minutos"
          type="number"
          min="0"
          max="59"
        />
      </b-col>
    </b-row>
    <FormulateInput
      name="description"
      label="Descripción"
      type="textarea"
      placeholder="Ingrese una descripción"
    />
    <p style="margin: .5rem 0">Estado de la Actividad: <ActivityStatusTag :status="form.activity ? form.activity.state : undefined" /></p>
    <FormulateInput
      name="changeActivityState"
      placeholder="¿Cambiar estado de la actividad?"
      type="checkbox"
      style="margin-bottom: .5rem"
    />
    <b-button class="mb-2"
      @click="toggleShowDisponibilidadModal(true)">
      Disponibilidad
    </b-button>
    <div v-if="form.changeActivityState">
      <FormulateInput
        name="newState"
        label="Estado de la actividad"
        type="activityStatusSelect"
        placeholder="Seleccione un estado"
      />
    </div>
    <div v-if="form.newState === ActivityState.STARTED || form.newState === ActivityState.FINISHED">
      <FormulateInput
        name="date"
        :label="form.newState === ActivityState.STARTED ? 'Fecha de Inicio Real' : 'Fecha de Fin Real'"
        type="date"
        label-no-date-selected="Seleccione una fecha"
      />
    </div>
    <hr />
    <div style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center;">
      <b-button
        style="margin-right: .5rem"
        @click="handleCancel">
        Cancelar
      </b-button>
      <FormulateInput
        type="submit"
        variant="primary"
        label="Crear Registro"
      />
    </div>
  </FormulateForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { ActivityState } from '@/utils/constants'
import { ActivityStatusTag } from '@/components'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  name: 'ActivityRegistryForm',
  props: ['projectId', 'activityId', 'data'],
  components: { ActivityStatusTag },
  data () {
    return {
      activities: [],
      weekNumber: 0,
      yearNumber: 0,
      selectedProject: null,
      form: {
        activity: null,
        responsible: [],
        createdAt: new Date().toISOString().split('T')[0],
        description: '',
        hours: 0,
        minutes: 0,
        newState: ActivityState.NOT_STARTED,
        changeActivityState: false,
        date: new Date().toISOString().split('T')[0],
        isWeek: true
      }
    }
  },
  computed: {
    ...mapGetters(['getProjects', 'getUsers']),
    ActivityState: () => ActivityState,
    project: {
      get () {
        const { projectId } = this.$route.query
        if (projectId === null) {
          const projects = this.getProjects

          return projects.find(p => p.id === Number(projectId))
        } else {
          return this.selectedProject
        }
      },
      set (value) {
        this.selectedProject = value
        this.handleChangeProject(value)
        return value
      }
    },
    projectStatusActive: function () {
      return this.getProjects.filter(project => project.status !== 'Finished')
    }
  },
  watch: {
    project (value) {
      this.handleChangeProject(value)
    },
    form ({ createdAt, isWeek }) {
      if (isWeek) {
        const dateMoment = moment(createdAt)
        this.weekNumber = dateMoment.week()
        this.yearNumber = dateMoment.year()
      }
    }
  },
  created () {
    const projects = this.$store.getters.getProjects
    const { projectId } = this.$route.query
    this.$store.dispatch('getProjectById', { projectId, projectsArray: projects })
    this.selectedProject = this.$store.getters.getProject
    this.$store.dispatch('fetchProjects', projects)
    this.$store.dispatch('fetchUsers')
    this.form.responsible = this.$store.getters.getcurrentUser
  },
  methods: {
    handleChangeProject (project) {
      const { activityId } = this.$route.query
      let activities = []

      if (project != null) {
        activities.push(...project.activities)
      }

      activities = activities.filter((a) => {
        return !activities.find(i => i.parentId === a.id)
      })

      this.form.activity = activityId != null
        ? activities.find(a => a.id === activityId)
        : null

      activities = activities.filter(activity => activity.state !== 'Finished')
      this.activities = activities
    },
    handleSubmit (data) {
      if (data.activity.state === 'Started') {
        this.$emit('submit', data)
      } else if (data.newState === 'NotStarted' && data.activity.state === 'NotStarted') {
        Swal.fire({
          icon: 'warning',
          title: 'Debes seleccionar un estado'
        })
      } else {
        this.$emit('submit', data)
      }
    },
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>
