
























import Vue from 'vue'
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'
import { ActivityForm } from '@/views/ProjectsPage/components'
import { TreeView } from '@/components'

export default Vue.extend({
  name: 'ActivityTree',
  mixins: [FormulateInputMixin],
  components: { TreeView, ActivityForm },
  props: {
    value: Array,
    readOnly: Boolean
  },
  data () {
    return {
      itemData: null
    }
  },
  methods: {
    handleFormSubmit (data) {
      console.log(data)
    },
    handleDialogOk () {
      // this.$refs['activity-form'].submit()
    }
  }
})
