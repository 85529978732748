<template>
  <b-form-datepicker
    class="mb-2"
    v-model="context.model"
    v-bind="attributes"
    :start-weekday="1">
  </b-form-datepicker>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'

export default {
  name: 'BootstrapDatePicker',
  mixins: [
    FormulateInputMixin
  ]
}
</script>
