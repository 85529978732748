<template>
  <FormulateForm
    name="activity-form"
    @submit="handleSubmit"
    v-model="form">
    <b-container>
      <b-row>
        <b-col md="12" lg="6">
          <div>
            <label>Prioridad</label>
            <input type="color" v-model="form.color" id="head" name="head" value="#e66465"/>
            <small id="passwordHelpBlock" class="form-text text-muted">Elige un color que identifique la importancia</small>

          </div>
          <FormulateInput
            name="numeracion"
            label="Numeración"
            validation="required"
            placeholder="Ingrese una Numeración"
          />
          <FormulateInput
            name="name"
            label="Nombre"
            validation="required"
            placeholder="Ingrese un nombre"
          />
          <FormulateInput
            name="assignedTo"
            label="Asignado a"
            type="autocomplete"
            :options="[...voluntarios, ...responsables]"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            track-by="id"
            placeholder="Selecciona un usuario"
          />

          <FormulateInput
            name="invited"
            label="Invitados"
            type="autocomplete"
            :options="[...voluntarios, ...responsables]"
            :multiple="true"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            track-by="id"
            placeholder="Selecciona un usuario"
          />

        </b-col>
        <b-col md="12" lg="6">
          <FormulateInput
            name="startDate"
            label="Fecha Estimada de Inicio"
            type="datetime-local"
            label-no-date-selected="Seleccione una fecha"
          />

          <FormulateInput
            name="endDate"
            label="Fecha Estimada de Vencimiento"
            type="datetime-local"
            label-no-date-selected="Seleccione una fecha"
          />

          <FormulateInput
            name="budgetedHours"
            label="Horas presupuestadas"
            type="number"
          />

          <FormulateInput
            name="expectedHours"
            label="Horas previstas"
            type="number"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <FormulateInput
            name="description"
            label="Descripción"
            type="textarea"
            placeholder="Ingrese una descripción"
          />
        </b-col>
      </b-row>
    </b-container>
  </FormulateForm>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import { ActivityState } from '@/utils/constants'
import axios from 'axios'

export default {
  name: 'ActivityForm',
  props: ['data', 'voluntarios', 'responsables'],
  data () {
    return {
      invited: [],
      form: {
        id: this.data?.id,
        name: this.data?.name || '',
        reference: this.data?.reference,
        numeracion: this.data?.numeracion,
        assignedTo: this.data?.assignedTo || [],
        invited: this.data?.invited || [],
        state: ActivityState.NOT_STARTED,
        startDate: this.data?.startDate || '',
        endDate: this.data?.endDate || '',
        description: this.data?.description || '',
        budgetedHours: this.data?.budgetedHours || 0,
        expectedHours: this.data?.expectedHours || 0,
        crmId: this.data?.crmId,
        color: this.data?.color
      }
    }
  },
  watch: {
    'form.assignedTo.id' (val) {
      if (this.form.startDate !== '' & this.form.endDate !== '' && this.form.assignedTo !== '') {
        var result = this.checkAvailability(val, this.form.startDate, this.form.endDate)
        if (!result) {
          Swal.fire({
            text: 'El usuario se encuentra en otra Actividad en ese periodo de tiempo',
            icon: 'error',
            timer: 2000
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUsers'])
  },
  created () {
    this.$store.dispatch('fetchUsers')
  },
  methods: {
    submit () {
      this.$formulate.submit('activity-form')
    },
    handleSubmit (data) {
      this.$emit('submit', data)
    },
    refillData (data) {
      this.$formulate.setValues('activity-form', {
        ...data
      })
    },
    toggleShowDisponibilidadModal (show) {
      if (show) {
        this.$bvModal.show('check-disponibilidad')
      } else {
        this.$bvModal.hide('check-disponibilidad')
      }
    },
    checkAvailability (user, dateStart, dateEnd) {
      this.loader = true
      const formData = new URLSearchParams()
      formData.append('action', 'checkAvailability')
      formData.append('user', user)
      formData.append('fechaIni', dateStart)
      formData.append('fechaFin', dateEnd)
      axios
        .post(process.env.VUE_APP_BACKEND_ENTRYPOINT, formData)
        .then(response => {
          console.log(response)
          return true
        }).catch(error => {
          this.loader = false
          console.error('Error borrando enlace:', error)
        })
    }
  }
}
</script>
