import { ProjectsModuleState } from './state'

export const getProjects = (state: ProjectsModuleState): any[] => {
  return state.projects
}

export const getProject = (state: ProjectsModuleState): any => {
  return state.project
}

export default {
  getProjects,
  getProject
}
