import { ProjectsModuleState } from './state'

const root = 'projects'
export const GET_PROJECTS = `${root}/GET_PROJECTS`
export const ADD_PROJECT = `${root}/ADD_PROJECT`

export const GET_PROJECT = `${root}/GET_PROJECT`
export const UPDATE_PROJECT = `${root}/UPDATE_PROJECT`
export const DELETE_PROJECT = `${root}/DELETE_PROJECT`

export default {
  [GET_PROJECTS] (state: ProjectsModuleState, projects: any[]) {
    state.projects = projects
  },
  [ADD_PROJECT] (state: ProjectsModuleState, projects: any[]) {
    state.projects = projects
  },
  [GET_PROJECT] (state: ProjectsModuleState, project: any) {
    state.project = project
  },
  [UPDATE_PROJECT] (state: ProjectsModuleState, projects: any[]) {
    state.projects = projects
  },
  [DELETE_PROJECT] (state: ProjectsModuleState, projects: any[]) {
    state.projects = projects
  }
}
