<template>
   <div>
    <template v-if="!funcion">
      <p>You don't have access to Actividades module.</p>
    </template>
    <template v-else>
      <AdminPageContainer title="Actividades">
          <DataTable :columns="columns" :items="getProyectoDeActividad">
            <template #headerLeft>
              <FormulateForm id="formFinal" v-model="search" class="col-12 no-gutters" style="display: flex ; flex-direction: row; gap:5px" @submit="handleSearch">
                <FormulateInput v-model="name" name="name" placeholder="Buscar por nombre" ref="search_input" :class="getColumnClass"/>
                <FormulateInput v-model="project" name="project" type="autocomplete" :options="[
                  {
                    name: 'Sin Proyecto',
                    value: '',
                    id: ''

                  },
                  ...projects
                ]" :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
                  placeholder="Selecciona un Proyecto" :class="getColumnClass" />
                <FormulateInput v-model="assigned_user_id" name="assigned_user_id" type="autocomplete" :options="[
                  {
                    name: 'Sin Responsable',
                    value: '',
                    id: ''
                  },
                  ...customers
                ]" :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
                  placeholder="Selecciona un Responsable" :class="getColumnClass" />
                <FormulateInput v-model="customer" name="customer" type="autocomplete" :options="[
                  {
                    name: 'Sin Invitados',
                    value: '',
                    id: ''

                  },
                  ...customers
                ]" :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
                  placeholder="Selecciona un Invitado" :class="getColumnClass" />
                <FormulateInput
                  style="display: inline-block;"
                  type="submit"
                  variant="primary"
                  label="Buscar"
                  class="col d-block col-2"/>
                </FormulateForm>
                <div class="col d-sm-block" style="display: flex; flex-direction: row; gap: 5px; margin-top: 1rem;" >
                  <b-button class="d-none d-sm-block" style="display: flex; flex-direction: row; gap: 5px; margin-top: 1rem;"
                    @click="clearFiltro()">
                    Limpiar Filtro
                  </b-button>
                </div>
            </template>
            <template #cell(name)="row">
              <div>
                <div class="buttons-groups"  v-show="funcion !== 'voluntario'">
                  <a :style="{ color: row.item.color }" :href="'#/projects/' + row.item.id_proyecto">
                    <b class="">{{ row.item.name }}</b>
                  </a>
                  </div>
                <div class="buttons-groups" v-show="funcion === 'voluntario'">
                    <b class="">{{ row.item.name }}</b>
                </div>
              </div>
            </template>
          </DataTable>
          <div class="loader-container"  v-if="loader">
            <vue-loaders name="ball-clip-rotate-pulse" color="black" scale="1"></vue-loaders>
          </div>
          <CheckDisponibilidad
          :camposFiltro="fieldsFiltro"
          v-on:submit="findActividad"
          v-on:cancel="() => this.toggleShowDisponibilidadModal(false)"
          ></CheckDisponibilidad>
      </AdminPageContainer>
    </template>
   </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdminPageContainer, DataTable, createTextColumn } from '@/components'
import axios from 'axios'
import Swal from 'sweetalert2'
import moment from 'moment'

const columns = [
  createTextColumn('numeracion', 'Numeración', null, true),
  createTextColumn('project', 'Misión', null, true),
  createTextColumn('name', 'Nombre', null, true),
  createTextColumn('createdAt',
    'Fecha de Inicio',
    (x) => moment(x).format('DD/MM/YYYY'),
    true
  ),
  createTextColumn('endsAt',
    'Fecha de Fin',
    (x) => moment(x).format('DD/MM/YYYY'),
    true
  ),
  createTextColumn(
    'responsible',
    'Responsable',
    (x) => {
      return x?.name || 'Sin responsable'
    }
  ),
  createTextColumn(
    'customer',
    'Invitados',
    (c) => `${(c && c.length > 0) ? `${c[0].name}${c.length > 1 ? ` y ${c.length - 1} más` : ''}` : 'Sin invitados'}`,
    true
  )
]

const actividades = []

const actividadesSinFiltrar = []

export default {
  name: 'Actividades',
  components: { AdminPageContainer, DataTable, createTextColumn },
  data () {
    return {
      userStates: [
        { id: 'pendiente', name: 'Pendiente de revisar' },
        { id: 'activo', name: 'Activo' },
        { id: 'inactivo', name: 'Inactivo' },
        { id: 'duplicado', name: 'Duplicado' }
      ],
      userTypes: [
        { id: 'director', name: 'Director MMUA' },
        { id: 'coordinador', name: 'Coordinador de voluntarios' },
        { id: 'voluntario', name: 'Voluntario' }
      ],
      columns,
      actividadesList: [],
      actividadesSinFiltrar: [],
      fieldsFiltro: [],
      funcion: localStorage.getItem('funcion'),
      userId: localStorage.getItem('id'),
      isAdmin: localStorage.getItem('is_admin'),
      search: {},
      name: '',
      status_c: '',
      customer: '',
      project: '',
      assigned_user_id: '',
      loader: false
    }
  },
  methods: {
    findActividad (filtro) {
      this.loader = true
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'findActividades')
      formData.append('camposFiltrados', JSON.stringify(filtro))
      axios
        .post(entryPoint, formData)
        .then(async (response) => {
          this.actividades = response.data
          this.toggleShowDisponibilidadModal(false)
          this.loader = false
        })
    },
    toggleShowDisponibilidadModal (show) {
      if (show) {
        this.$bvModal.show('check-disponibilidad')
      } else {
        this.$bvModal.hide('check-disponibilidad')
      }
    },
    getFilterFields () {
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'getFilterFields')
      axios
        .post(entryPoint, formData)
        .then((response) => {
          this.fieldsFiltro = response.data
        })
    },
    clearFiltro () {
      this.actividadesList = this.actividadesSinFiltrar
      this.name = ''
      this.customer = ''
      this.assigned_user_id = ''
      this.project = ''
    },
    handleSearch () {
      this.loader = true
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'searchActividad')
      formData.append('searchData', JSON.stringify(this.search))
      axios
        .post(entryPoint, formData)
        .then((response) => {
          this.actividadesList = response.data
          this.loader = false
        })
      this.showButton = true
    }
  },
  mounted () {
    this.getFilterFields()
  },
  computed: {
    ...mapGetters({
      projects: 'getProjects',
      actividades: 'getActividades',
      customers: 'getCustomers'
    }),
    getColumnClass () {
      const mediaQuery = window.matchMedia('(min-width: 600px)')
      if (mediaQuery.matches) {
        return 'col-4 pl-0'
      } else {
        return 'col-12'
      }
    },
    getProyectoDeActividad () {
      const projectsArray = this.$store.getters.getProjects
      const actividadesArray = this.actividadesList
      let userActividades = []
      actividadesArray.forEach(activity => {
        projectsArray.forEach(proyecto => {
          const actividadIsInProject = proyecto.activities.some((actividad) => actividad.id === activity.id_crm)
          if (actividadIsInProject) {
            activity.id_proyecto = proyecto.id
          }
        })
      })
      if (this.isAdmin === '0') {
        if (!Array.isArray(actividadesArray)) {
          console.log('projectsArray.projects is either empty, undefined, or not an array.')
        } else if (localStorage.getItem('funcion') === 'director') {
          userActividades = this.actividadesList
        } else if (localStorage.getItem('funcion') === 'coordinador') {
          actividadesArray.forEach(actividad => {
            if (actividad.customer && actividad.customer.length > 0) {
              const currentUserIsInProject = actividad.customer.some((invitado) => invitado.id === localStorage.getItem('id'))
              if (currentUserIsInProject) {
                userActividades.push(actividad)
              }
              if (localStorage.getItem('id') === actividad.responsible?.id && !currentUserIsInProject) {
                userActividades.push(actividad)
              }
            } else {
              userActividades.push(actividad)
            }
          })
        } else {
          actividadesArray.forEach(actividad => {
            if (actividad.customer && actividad.customer.length > 0) {
              const currentUserIsInProject = actividad.customer.some((invitado) => invitado.id === localStorage.getItem('id'))
              if (currentUserIsInProject) {
                userActividades.push(actividad)
              }
              if (localStorage.getItem('id') === actividad.responsible?.id && !currentUserIsInProject) {
                userActividades.push(actividad)
              }
            }
          })
        }
      } else {
        userActividades = this.actividadesList
      }
      return userActividades
    }
  },
  created () {
    const projects = this.$store.getters.getProjects
    const actividades = this.$store.getters.getActividades
    this.$store.dispatch('fetchActividades', actividades)
    this.$store.dispatch('fetchProjects', projects)
    this.$store.dispatch('fetchCustomers')
  },
  watch: {
    actividades: {
      deep: true,
      handler (newActividades) {
        this.actividadesList = newActividades.map((activity) => {
          const matchingProject = this.projects.find(
            project => project.name === activity.name
          )
          if (matchingProject) {
            return {
              ...activity,
              projectId: matchingProject.id
            }
          } else {
            return activity
          }
        })
        this.actividadesSinFiltrar = newActividades
      }
    }
  }
}
</script>

<style scoped lang="scss">
.widgets-container {
  border: 1px solid black;
  max-width: 100%;

  & > div {
    height: 40rem;
    border: 1px solid red;
  }
}
.buttons-groups {
  display: inline-block;
}
@media (max-width: 500px) {
    #formFinal {
      flex-direction: column !important;
    }
  }

@media (min-width: 501px) {
  #formFinal {
    flex-direction: row !important;
  }
}
.actions-dropdown {
  display: none;
}

@media (max-width: 1440px) {
  .buttons-groups {
    display: inline-block;
  }

  .actions-dropdown {
    display: inline-block;
  }
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.loader {
  width: 50px;
  height: 50px;
}
</style>
