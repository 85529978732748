<template>
  <Widget>
    <FormulateForm
      name="filter-form"
      @submit="handleSubmit"
      @input="handleChange"
      class="filter-form"
      v-model="form">
      <FormulateInput
        name="customer"
        label="Empresa"
        type="autocomplete"
        :options="getCustomers"
        :preserve-search="true"
        :close-on-select="true"
        :clear-on-select="true"
        track-by="id"
        placeholder="Selecciona una empresa"
      />

      <FormulateInput
        name="status"
        label="Estado del proyecto"
        type="activityStatusSelect"
        placeholder="Seleccione un estado"
      />

      <FormulateInput
        name="responsible"
        label="Responsable"
        type="autocomplete"
        :options="getUsers"
        :preserve-search="true"
        :close-on-select="true"
        :clear-on-select="false"
        track-by="id"
        placeholder="Selecciona un usuario"
      />

      <FormulateInput
        name="startDate"
        label="Fecha de Inicio"
        type="date"
        label-no-date-selected="Seleccione una fecha"
      />

      <FormulateInput
        name="endDate"
        label="Fecha de Fin"
        type="date"
        label-no-date-selected="Seleccione una fecha"
      />

    </FormulateForm>
    <b-row>
      <b-col offset="10">
        <div class="d-flex justify-content-end">
          <b-button @click="cleanForm">Limpar filtro</b-button>
        </div>
      </b-col>
    </b-row>
  </Widget>
</template>

<style scoped>
.filter-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 960px) {
  .filter-form {
    flex-direction: column;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'
import { Widget } from '../Widget'

export default {
  name: 'FiltersWidget',
  components: { Widget },
  data () {
    return {
      activities: [],
      form: {
        project: null,
        activity: null,
        startDate: null,
        endDate: null,
        responsible: null
      }
    }
  },
  created () {
    this.$store.dispatch('fetchCustomers')
    this.$store.dispatch('fetchUsers')
  },
  computed: {
    ...mapGetters(['getCustomers', 'getUsers'])
  },
  methods: {
    handleChange (data) {
      this.$emit('change', data)
    },
    handleSubmit (data) {
      this.$emit('change', data)
    },
    cleanForm () {
      this.form = {
        project: null,
        activity: null,
        plannedTime: null,
        realTime: null,
        state: null,
        responsible: null,
        activityLevel: 0
      }
    }
  }
}
</script>
