const root = 'customers'

export const GET_CUSTOMERS = `${root}/GET_CUSTOMERS`

export const GET_CUSTOMER = `${root}/GET_CUSTOMER`

export default {
  [GET_CUSTOMERS] (state, customers: any[]) {
    state.customers = customers
  },
  [GET_CUSTOMER] (state, customer: any) {
    state.customer = customer
  }
}
