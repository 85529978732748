<template>
  <div style="min-height: 40vh;">
    <ul>
      <PDFActivityListItem v-for="activity in project.activities" :activity="activity" :key="activity.crmId"></PDFActivityListItem>
    </ul>
    <b-row>
      <b-col>
        <FormulateInput
          name="registries_date_start"
          label="Fecha inicio"
          type="date"
          v-model="registriesDateStart"
        />
      </b-col>
      <b-col>
        <FormulateInput
          name="registries_date_end"
          label="Fecha fin"
          type="date"
          v-model="registriesDateEnd"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1)
firstDayOfYear.setHours(firstDayOfYear.getHours() + 3)

const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31)
lastDayOfYear.setHours(lastDayOfYear.getHours() + 3)

export default {
  name: 'DownloadPDFForm',
  props: ['project'],
  data: function () {
    return {
      registriesDateStart: firstDayOfYear.toISOString().split('T')[0],
      registriesDateEnd: lastDayOfYear.toISOString().split('T')[0]
    }
  },
  watch: {
    registriesDateStart: function () {
      this.project.registriesDateStart = this.registriesDateStart
    },
    registriesDateEnd: function () {
      this.project.registriesDateEnd = this.registriesDateEnd
    }
  },
  created: function () {
    this.project.registriesDateStart = this.registriesDateStart
    this.project.registriesDateEnd = this.registriesDateEnd
  }
}
</script>
