import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { Multiselect as VueMultiSelect } from 'vue-multiselect'
import VueFormulate from '@braid/vue-formulate'
import '@/assets/Theme.scss'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'vue-loaders/dist/vue-loaders.css'
import VueLoaders from 'vue-loaders'

import {
  TreeView,
  ALMultiSelect,
  BootstrapInput,
  BootstrapDatePicker,
  BootstrapButton,
  BootstrapTextArea,
  BootstrapNumber,
  BootstrapSelect,
  BootstrapActivityStatusSelect,
  BootstrapCheckbox,
  BootstrapRadio
} from '@/components'
import { ActivityTree, UploadFile, DownloadPDFForm, PDFActivityListItem, CheckDisponibilidad, MapsView } from '@/views/ProjectsPage/components'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import JsonExcel from 'vue-json-excel'
import VueTagsInput from '@johmun/vue-tags-input'

moment.locale('es')
Vue.component('vue-multiselect', VueMultiSelect)
Vue.component('downloadExcel', JsonExcel)
Vue.component('DownloadPDFForm', DownloadPDFForm)
Vue.component('UploadFile', UploadFile)
Vue.component('PDFActivityListItem', PDFActivityListItem)
Vue.component('CheckDisponibilidad', CheckDisponibilidad)
Vue.component('MapsView', MapsView)

Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTagsInput)
Vue.use(VueLoaders)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA8KXTaRbrKpIcw5OruT6yDXEC0vdTz7DY'
  }
})
Vue.use(VueFormulate, {
  library: {
    text: {
      component: BootstrapInput
    },
    number: {
      component: BootstrapNumber
    },
    textarea: {
      component: BootstrapTextArea
    },
    date: {
      component: BootstrapDatePicker
    },
    select: {
      component: BootstrapSelect
    },
    activityStatusSelect: {
      component: BootstrapActivityStatusSelect
    },
    autocomplete: {
      component: ALMultiSelect
    },
    checkbox: {
      component: BootstrapCheckbox
    },
    radio: {
      component: BootstrapRadio
    },
    treeView: {
      component: TreeView,
      classification: 'array'
    },
    activityTree: {
      component: ActivityTree,
      classification: 'array'
    },
    submit: {
      component: BootstrapButton
    }
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
