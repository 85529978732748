<template>
  <div>
      <Paper title="Mapa Proyectos Utmac">
        <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 75vh;">
          <gmap-marker v-for="(marker, index) in markers" :key="index" :position="marker" :title="marker.title" :clickable="true" @click="toggleInfo(index)" />
          <GmapInfoWindow :opened="infoWindowOpened" :position="infoWindowPosition" @closeclick="closeInfoWindow">
            <div>{{ infoWindowContent }}</div>
          </GmapInfoWindow>
        </gmap-map>
      </Paper>
  </div>
</template>

<script>
import { Paper } from '@/components'
import axios from 'axios'
import Swal from 'sweetalert2'
import * as VueGoogleMaps from 'vue2-google-maps'

export default {
  name: 'MapsView',
  components: { Paper },
  data () {
    // Se puede generar un enlace y que el enlace sea el que te lleve a la vista
    return {
      center: { lat: 38.701219, lng: -0.475430 },
      zoom: 10,
      markers: [],
      selectedElement: null,
      infoWindowOpened: false,
      infoWindowPosition: { lat: 0, lng: 0 },
      infoWindowContent: ''
    }
  },
  methods: {
    getAllMarkers () {
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'getAllMarkers')
      axios
        .post(entryPoint, formData)
        .then((response) => {
          // Necesitamos que sea un float para los marcadores
          response.data.forEach(element => {
            this.markers.push({ lat: parseFloat(element.lat), lng: parseFloat(element.lng), title: element.title })
          })
        })
    },
    toggleInfo (index) {
      this.infoWindowOpened = true
      this.infoWindowContent = this.markers[index].title
      this.infoWindowPosition.lat = this.markers[index].lat
      this.infoWindowPosition.lng = this.markers[index].lng
    },
    closeInfoWindow () {
      this.infoWindowOpened = false
    }
  },
  computed: {},
  mounted () {
    this.getAllMarkers()
  }
}
</script>
<style>
.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container h2 {
  margin-right: 40px;
  font-size: 30px;
}
</style>
