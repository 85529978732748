







































import Vue from 'vue'

export default Vue.extend({
  name: 'TreeViewItem',
  props: {
    addItem: Boolean,
    label: String,
    treeNode: Object,
    nodes: Array
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    toggleOpen () {
      const { open } = this
      this.open = !open
      this.$emit(!open ? 'open' : 'close')
    },
    handleHeaderClick (node) {
      // const { treeNode } = this
      this.$emit('click', node)
    },
    handleClickAdd (parentNode?) {
      this.$emit('add', parentNode)
    },
    handleClickSubNode (treeNode) {
      this.$emit('click-sub-node', treeNode)
    },
    handleClickAddSubNode (node) {
      this.$emit('add-sub-node', node)
    }
  }
})
