<template>
  <div class="progress-bar">
    <div class="progress-bar__content">
      <div class="progress-bar__background" />
      <div class="progress-bar__fill" :style="`width: ${this.progressInPercentage}%`" />
    </div>
    <div class="progress-bar__info">
      {{ this.currentProgress }} / {{ this.totalProgress }}
      ({{ this.progressInPercentage }}%)
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityProgressBar',
  props: {
    activity: Object,
    activities: Array,
    percentage: {
      type: Number,
      default: () => 0
    },
    width: {
      type: Number,
      default: () => 150
    }
  },
  data () {
    return {
      currentProgress: 0,
      totalProgress: 1,
      progressInPercentage: 0
    }
  },
  mounted () {
    this.handleChangeActivity(this.activity)
  },
  watch: {
    activity (value) {
      this.handleChangeActivity(value)
    }
  },
  methods: {
    handleChangeActivity (node) {
      const {
        currentProgress,
        totalProgress,
        progressInPercentage
      } = this.getProgress(node)

      this.currentProgress = currentProgress
      this.totalProgress = totalProgress
      this.progressInPercentage = progressInPercentage
    },
    getChildrenActivities (activity) {
      return this.activities.filter(a => a.parentId === activity.id)
    },
    getProgress (node) {
      const children = this.getChildrenActivities(node)
      let currentProgress = 0
      let totalProgress = 0

      if (children.length) {
        children.forEach((c) => {
          const {
            currentProgress: childrenProgress,
            totalProgress: childrenTotal
          } = this.getProgress(c)

          currentProgress += childrenProgress
          totalProgress += childrenTotal
        })
      } else {
        if (node.registries) {
          node.registries.forEach((r) => {
            currentProgress += Number(r.hours) + Number(r.minutes / 60)
          })
        }

        totalProgress = Number(node.budgetedHours)
      }

      let progressInPercentage = Math.round(Number((currentProgress / totalProgress) * 100))
      if (isNaN(progressInPercentage)) {
        progressInPercentage = 0
      } else if (!isFinite(progressInPercentage)) {
        progressInPercentage = 100
      }

      currentProgress = Number(currentProgress.toFixed(2))

      return {
        currentProgress,
        totalProgress,
        progressInPercentage
      }
    }
  }
}
</script>

<style scoped lang="scss">
.progress-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: .5rem;
  background: none;

  .progress-bar__content {
    position: relative;
    width: 75px;
    height: 8px;

    .progress-bar__background {
      border-radius: .5rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #b2b2b2;
    }

    .progress-bar__fill {
      border-radius: .5rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      max-width: 100%;
      height: 100%;
      background-color: black;
    }
  }

  .progress-bar__info {
    text-align: center;
    min-width: 100px;
    margin-left: .5rem;
    color: gray;
  }
}
</style>
