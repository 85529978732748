<template>
  <b-modal
    id="download-pdf"
    size="lg"
    title="Descargar PDF"
    scrollable
    no-close-on-backdrop
    no-close-on-esc
    centered>
      <DownloadPDFForm
        :project="pdfProject"></DownloadPDFForm>
      <template #modal-footer>
        <div class="w-100">
          <div class="float-right">
            <b-button
              size="sm"
              style="margin-right: .5rem"
              @click="handleCancel">
              Cancelar
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              @click="handleSubmit">
              Descargar
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
</template>
<script>
export default {
  name: 'DownloadPDFDialog',
  props: ['project'],
  data () {
    return {
      pdfProject: JSON.parse(JSON.stringify(this.project))
    }
  },
  methods: {
    handleCancel () {
      this.$emit('cancel')
    },
    handleSubmit () {
      this.$emit('submit', this.pdfProject)
    }
  },
  mounted () {
    this.pdfProject.activities.forEach(activity => { activity.download = true })
    this.pdfProject.activities.forEach(activity => {
      if (activity.parentId) {
        const idsArray = this.pdfProject.activities.map(a => a.crmId)
        const indexParentActivity = idsArray.indexOf(activity.parentId)
        const parentActivity = this.pdfProject.activities[indexParentActivity]

        if (!parentActivity.activities) {
          parentActivity.activities = []
        }
        parentActivity.activities.push(activity)
      }
    })
    this.pdfProject.activities = this.pdfProject.activities.filter(activity => !activity.parentId)
  }
}
</script>
