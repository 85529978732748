<template>
  <div class="calendar-root">
    <div class="calendar-controls">
      <div style="display: flex; flex: 1; align-items: center">
        <button @click="backPage">«</button>
        <button @click="nextPage">»</button>
        <h4 style="margin: 0 0 0 1rem;">{{ `${ date.format('MMMM YYYY') }` }}</h4>
        <h5 class="week-counter">
          {{ `- Semana ${ date.week() }` }}
        </h5>
      </div>
      <div style="display: inline-block;">
        <b-form-select v-model="viewRange" @change="handleChangeViewRange">
          <b-form-select-option :value="CalendarViewRange.DAY">
            Día
          </b-form-select-option>
          <b-form-select-option :value="CalendarViewRange.WEEK">
            Semana
          </b-form-select-option>
        </b-form-select>
      </div>
    </div>
    <div class="calendar-container">
      <CalendarGroup
        v-for="(n, index) in (viewRange === CalendarViewRange.DAY ? 1 : viewRange === CalendarViewRange.WEEK ? 7 : date.daysInMonth())"
        :key="date.clone().startOf(viewRange.toLowerCase()).add(index, 'day').toISOString()"
        :date="viewRange === CalendarViewRange.DAY ? date : date.clone().startOf(viewRange.toLowerCase()).add(index, 'day')"
        :activities="calendarPayload.filter((i) => moment(i.startDate).startOf('day').isSame(date.clone().startOf(viewRange.toLowerCase()).add(index, 'day')))"
      />
    </div>
  </div>
</template>

<script>
import CalendarGroup from './CalendarGroup'
import { CalendarViewRange } from '@/utils/constants'
import moment from 'moment'

export default {
  name: 'Calendar',
  components: { CalendarGroup },
  props: ['calendarPayload'],
  data () {
    return {
      viewRange: this.isMobile() ? CalendarViewRange.DAY : CalendarViewRange.WEEK,
      date: moment()
    }
  },
  computed: {
    CalendarViewRange: () => CalendarViewRange,
    moment: () => moment
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    buildRequestParams () {
      const { viewRange, date } = this
      return {
        viewRange,
        date: date.toDate()
      }
    },
    requestCalendarUpdate () {
      this.$emit('requestCalendarUpdate', this.buildRequestParams())
    },
    handleChangeViewRange (viewRange) {
      this.viewRange = viewRange
      this.requestCalendarUpdate()
    },
    nextPage () {
      const { viewRange } = this
      const unit = viewRange?.toLowerCase() || 'week'

      // noinspection JSCheckFunctionSignatures
      const date = this.date.add(1, unit)
      this.date = null
      this.date = date
      this.requestCalendarUpdate()
    },
    backPage () {
      const { viewRange } = this
      const unit = viewRange?.toLowerCase() || 'week'

      // noinspection JSCheckFunctionSignatures
      const date = this.date.subtract(1, unit)
      this.date = null
      this.date = date
      this.requestCalendarUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
.calendar-root {
  display: flex;
  overflow-y: visible;
  width: 100%;
  height: 45rem;
  flex-direction: column;
}
.calendar-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  text-transform: capitalize;

  button {
    width: 2rem;
    height: 2rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: .5rem;
    border-radius: 10rem;
    font-weight: bold;
    background-color: #e1e1e1;
  }
}
.calendar-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.week-counter {
  color: gray;
  margin: 0 0 0 .5rem;
}
@media (max-width: 960px) {
  .calendar-controls {
    flex-direction: column;

    & > div:first-child {
      padding-bottom: 1rem;
    }
  }
  .week-counter {
    display: none;
  }
}
</style>
