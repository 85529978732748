<template>
  <AdminPageContainer title="Edición de Proyecto">
    <Paper>
      <FormulateForm
        @submit="onSubmit"
        v-model="form">
        <FormulateInput
          name="numeracion"
          label="Numeración"
          placeholder="Ingrese una Numeración"
        />
        <FormulateInput
          name="name"
          label="Nombre del proyecto"
          placeholder="Ingrese un nombre"
          validation="required"
        />
        <FormulateInput
          name="fechaIni"
          label="Fecha de Inicio"
          type="datetime-local"
          label-no-date-selected="Seleccione una fecha"
        />
        <FormulateInput
          name="fechaFin"
          label="Fecha de Fin"
          type="datetime-local"
          label-no-date-selected="Seleccione una fecha"
        />
        <FormulateInput
          name="typeMission"
          label="Modalidad Mission"
          type="autocomplete"
          :options="missionOptions"
          :multiple="true"
          :preserve-search="true"
          :close-on-select="false"
          :clear-on-select="false"
          track-by="id"
          deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
          placeholder="Selecciona Modalidad"
        />
        <label>Comprobar disponibilidad</label><br>
        <label>Para poder asignar responsable y voluntarios al proyecto primero comprueba la disponibilidad</label><br>
         <b-button class="mb-2"
            @click="toggleShowDisponibilidadModal(true)">
            Disponibilidad
          </b-button>
        <FormulateInput
          name="responsible"
          label="Responsable"
          type="autocomplete"
          :options="responsables"
          :multiple="false"
          :preserve-search="true"
          :close-on-select="false"
          :clear-on-select="false"
          track-by="id"
          deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
          placeholder="Selecciona Responsables"
        />
        <FormulateInput
          name="customer"
          label="Invitados"
          type="autocomplete"
          :options="voluntarios"
          :multiple="true"
          :preserve-search="true"
          :close-on-select="true"
          :clear-on-select="false"
          track-by="id"
          placeholder="Selecciona un Invitado"
        />
        <FormulateInput
          name="status"
          label="Estado del proyecto"
          type="activityStatusSelect"
          placeholder="Seleccione un estado"
        />
        <FormulateInput
          name="type"
          label="Tipo de proyecto"
          type="select"
          placeholder="Seleccione el tipo de proyecto"
          :options="projectOptions"
        />
        <FormulateInput
          name="activities"
          label="Actividades"
          type="treeView"
          v-model="form.activities"
          new-label="Agregar Actividad"
          v-on:click-node="handleClickNode"
          v-on:click-add-node="handleClickAddNode"
          v-on:click-sub-node="handleClickSubNode"
          v-on:click-add-sub-node="handleClickAddSubNode"
        />
        <span>
          Categorias <span style="font-size: 11px;">(Para añadir categorias existentes añade algún carácter, por ejemplo, "a". O bien, añade nuevas categorías escribiendo el texto que necesites)</span>
        </span>
        <a @click="showAllCategories" style="cursor: pointer; margin-left: 10px; color: #13afe0;">Mostrar Etiquetas</a>
        <vue-tags-input
          v-model="tag"
          :tags="tags"
          :autocomplete-items="filteredItems"
          @tags-changed="newTags => form.etiquetas = newTags"
          :placeholder="'Añadir categorias'"
          style="max-width: 100%;"
        />
        <input type="checkbox" name="sendEmail" v-model="form.sendEmail" style="margin-right: 0.5rem; margin-top: 0.5rem"><span>Avisar por correo</span>
        <hr />
        <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 400px;" @click="handleMapClick">
          <gmap-marker :position="markerPosition"/>
        </gmap-map>
        <div class="mt-3">
          <b-button
            @click="handleBack">
            Volver
          </b-button>
          <FormulateInput
            style="display: inline-block; margin-left: .5rem;"
            type="submit"
            variant="primary"
            label="Guardar Proyecto"
          />
        </div>
      </FormulateForm>
    </Paper>
    <CheckDisponibilidad
    :camposFiltro="fieldsFiltro"
    v-on:submit="findNewVoluntarios"
    v-on:cancel="() => this.toggleShowDisponibilidadModal(false)"
    ></CheckDisponibilidad>
    <div class="loader-container" v-if="loader">
      <vue-loaders name="ball-clip-rotate-pulse" color="black" scale="1"></vue-loaders>
    </div>
    <div class="loader-container" v-if="loader">
      <vue-loaders name="ball-clip-rotate-pulse" color="black" scale="1"></vue-loaders>
    </div>
    <ActivityFormDialog
    :activity="selectedActivity"
    :responsables="form.responsible"
    :voluntarios="form.customer"
    v-on:submit="handleActivityFormSubmit"
    v-on:delete="handleDeleteActivity"
    v-on:cancel="() => this.toggleShowActivityModal(false)"
    />
  </AdminPageContainer>
</template>

<script>
import { ActivityState } from '@/utils/constants'
import { AdminPageContainer, Paper, Tree } from '@/components'
import { mapGetters } from 'vuex'
import { ActivityFormDialog, CheckDisponibilidad } from './components'
import { GET_PROJECT } from '@/store/modules/projects/mutations'
import axios from 'axios'
import Swal from 'sweetalert2'
import * as VueGoogleMaps from 'vue2-google-maps'

export default {
  name: 'ProjectsUpdatePage',
  components: { AdminPageContainer, Paper, ActivityFormDialog },
  data () {
    return {
      showFormModal: false,
      parentActivity: null,
      selectedActivity: null,
      showInputVoluntarios: false,
      showInputResponsables: false,
      voluntarios: [],
      responsables: [],
      projectOptions: [
        {
          text: 'Organizacion interna',
          value: 'interno'
        },
        {
          text: 'Voluntariado',
          value: 'voluntario'
        }
      ],
      missionOptions: [
        {
          name: '164k',
          id: '164k'
        },
        {
          name: '42k',
          id: '42k'
        },
        {
          name: '24k',
          id: '24k'
        },
        {
          name: '15k',
          id: '15k'
        },
        {
          name: 'Vertical',
          id: 'vertical'
        }
      ],
      tree: new Tree({
        labelProperty: 'name',
        childrenProperty: 'activities'
      }),
      form: {
        numeracion: '',
        name: '',
        createdAt: '',
        fechaFin: '',
        fechaIni: '',
        observaciones: '',
        responsible: [],
        typeMission: [],
        activities: [],
        customer: [],
        status: '',
        marcador: {},
        etiquetas: [],
        sendEmail: false
      },
      autocompleteItems: [
        { text: 'DIRECCIÓ CURSA' },
        { text: 'AVITUALLAMENTS-PC' },
        { text: 'TRAZA-VIES' },
        { text: 'EQUIP MUNTANYA-MARCAT' },
        { text: 'DORSALS-INSCRIPCIONS' },
        { text: 'TAULA DE VOLUNTARIS' },
        { text: 'EIXIDES CURSES' },
        { text: 'INFRAESTRUCTURES' },
        { text: 'LOGÍSTICA' },
        { text: 'BOSSES DE VIDA' },
        { text: 'MOVILITAT' },
        { text: 'EQUIP MÈDIC' },
        { text: 'SC CIRCUIT' },
        { text: 'EQUIP “ESCOBAS”' },
        { text: 'SEGURETAT META' },
        { text: 'ANIMACIÓ' },
        { text: 'EQUIP RECOLLIDES' },
        { text: 'CONTROL MAT-COMP' },
        { text: 'PODIUMS' },
        { text: 'COMUNICACIÓ I MARKETING' },
        { text: 'EQUIP AUDIOVISUAL' },
        { text: 'ALLOTJAMENTS' },
        { text: 'VIPS' }
      ],
      tags: [
      ],
      tag: '',
      fieldsFiltro: [],
      center: { lat: 38.701219, lng: -0.475430 },
      zoom: 10,
      markerPosition: { lat: 0, lng: 0 },
      deleteActivitiesIds: [],
      loader: false
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'getProject', 'getCustomers']),
    filteredItems () {
      return this.autocompleteItems?.filter(i => {
        return i.text?.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
      })
    }
  },
  mounted () {
    this.getFilterFields()
  },
  methods: {
    loadData (data) {
      const { name, createdAt, fechaFin, fechaIni, responsible, activities, customer, etiquetas, latitud, longitud, status, type, sendEmail, observaciones, typeMission, numeracion } = data
      this.form = {
        name,
        createdAt,
        fechaFin,
        fechaIni,
        responsible,
        activities,
        customer,
        etiquetas,
        status,
        type,
        sendEmail,
        observaciones,
        typeMission,
        numeracion
      }
      this.markerPosition = { lat: parseFloat(latitud), lng: parseFloat(longitud) }
      this.tags = etiquetas
    },
    handleBack () {
      this.$router.push('/projects')
    },
    toggleShowActivityModal (show) {
      if (show) {
        this.$bvModal.show('activity-modal')
      } else {
        this.$bvModal.hide('activity-modal')
      }
    },
    handleClickNode (node) {
      const { activities } = this.form
      this.parentActivity = node.parentId ? activities.find(i => i.id === node?.parentId) : null
      node.reference = this.generateReference(
        activities,
        node,
        activities.filter(i => i.parentId === node.parentId)
      )
      this.selectedActivity = node
      this.toggleShowActivityModal(true)
    },
    handleClickAddNode (parentNode) {
      const { activities } = this.form
      this.parentActivity = parentNode || null
      this.selectedActivity = {
        reference: this.generateReference(
          activities,
          parentNode,
          activities.filter(i => parentNode ? i.parentId === parentNode.parentId : i.parentId == null),
          true
        )
      }
      this.toggleShowActivityModal(true)
    },
    handleClickSubNode (node) {
      const { activities } = this.form
      this.parentActivity = activities.find(i => i.id === node.parentId)
      node.reference = this.generateReference(
        activities,
        node,
        activities.filter(i => i.parentId === node.parentId)
      )
      this.selectedActivity = node
      this.toggleShowActivityModal(true)
    },
    showAllCategories () {
      const allCategories = this.filteredItems.map(item => item.text).join(', ')
      Swal.fire({
        title: 'Todas las Categorías',
        text: allCategories,
        icon: 'info'
      })
    },
    handleClickAddSubNode (parentNode) {
      this.parentActivity = parentNode
      this.selectedActivity = null
      this.toggleShowActivityModal(true)
    },
    generateTreeIndex (node, array) {
      return array.indexOf(node) + 1
    },
    generateReference (treeArray, node, siblingsArray = [], add = false) {
      const index = this.generateTreeIndex(node, siblingsArray)
      let path = ''

      const parentNode = treeArray.find(i => i.id === node?.parentId)
      if (parentNode != null) {
        const parentSiblings = treeArray.filter(i => parentNode ? i.parentId === parentNode.parentId : i.parentId == null)
        path = this.generateReference(treeArray, parentNode, parentSiblings)
      }

      const nodeChildren = treeArray.filter(i => node ? i.parentId === node.id : i.parentId == null)
      return node
        ? `${path}${index}.${add ? `${nodeChildren.length + 1}.` : ''}`
        : `${add ? `${nodeChildren.length + 1}.` : ''}`
    },
    handleActivityFormSubmit (data) {
      const { parentActivity, form } = this
      const { activities } = form

      if (parentActivity) {
        data.parentId = parentActivity.id
      }

      const existingNode = activities.find(a => a.id === data.id)
      if (existingNode) {
        const index = activities.indexOf(existingNode)
        const newList = this.form.activities
        newList[index] = data

        this.form.activities = []
        this.form.activities = newList
      } else {
        data.id = Math.round(Math.random() * 10000000000)
        this.form.activities.push(data)
      }

      this.toggleShowActivityModal(false)
    },
    handleDeleteActivity (data) {
      const { activities } = this.form
      this.deleteActivitiesIds.push(data.id)
      this.getDeleteIds(data.id, activities)
      this.form.activities = activities.filter(i => {
        let save = true

        for (let j = 0; j < this.deleteActivitiesIds.length; j++) {
          if (i.id === this.deleteActivitiesIds[j]) {
            save = false
          }
        }

        return save
      })
      this.toggleShowActivityModal(false)
    },
    onSubmit (data) {
      data.id = Number(this.$route.params.projectId)
      const projectsArray = this.$store.getters.getProjects
      const project = projectsArray.find(p => Number(p.id) === data.id)
      data.crmId = project.crmId
      data.marcador = this.form.marcador
      data.etiquetas = this.form.etiquetas
      this.loader = true
      this.$store.dispatch('updateProject',
        {
          project: data,
          projectsArray,
          deleteActivitiesIds: this.deleteActivitiesIds,
          cb: () => {
            this.loader = false
            this.$router.push('/projects')
          }
        })
    },
    getDeleteIds (id, activities) {
      activities.forEach(a => {
        if (a.parentId === id) {
          this.deleteActivitiesIds.push(a.id)
          const activitiesFiltered = activities.filter(b => b.id !== a.id)
          this.getDeleteIds(a.id, activitiesFiltered)
        }
      })
    },
    handleMapClick (event) {
      const lat = event.latLng.lat()
      const lng = event.latLng.lng()
      console.log('Latitud:', lat, 'Longitud:', lng)
      this.markerPosition = { lat, lng }
      this.form.marcador = this.markerPosition
    },
    findNewVoluntarios (filtro) {
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      this.resetFiltro()
      formData.append('action', 'filtrarNuevosVoluntarios')
      formData.append('camposFiltrados', JSON.stringify(filtro))
      filtro = {}
      axios
        .post(entryPoint, formData)
        .then(async (response) => {
          console.log(response.data)
          if (!this.showInputVoluntarios) {
            if (!response.data.voluntarios) {
              await Swal.fire({
                icon: 'warning',
                title: 'No se han encontrado voluntarios',
                text: 'Prueba a buscar con otros valores',
                showConfirmButton: false,
                timer: 2000
              })
            } else {
              this.voluntarios = response.data.voluntarios
              await Swal.fire({
                icon: 'success',
                title: 'Se han encontrado ' + this.voluntarios.length + ' voluntarios/as',
                showConfirmButton: false,
                timer: 3000
              })
              this.showInputVoluntarios = true
            }
          }
          if (!this.showInputResponsables) {
            if (!response.data.responsables) {
              await Swal.fire({
                icon: 'warning',
                title: 'No se han encontrado responsables',
                text: 'Prueba a buscar con otros valores',
                showConfirmButton: false,
                timer: 2000
              })
            } else {
              this.responsables = response.data.responsables
              await Swal.fire({
                icon: 'success',
                title: 'Se han encontrado ' + this.responsables.length + ' responsables',
                showConfirmButton: false,
                timer: 3000
              })
              this.showInputResponsables = true
            }
          }
          this.toggleShowDisponibilidadModal(false)
        })
    },
    toggleShowDisponibilidadModal (show) {
      if (show) {
        this.$bvModal.show('check-disponibilidad')
      } else {
        this.$bvModal.hide('check-disponibilidad')
      }
    },
    getFilterFields () {
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'getFilterFields')
      axios
        .post(entryPoint, formData)
        .then((response) => {
          console.log(response.data)
          this.fieldsFiltro = response.data
        })
    },
    resetFiltro () {
      this.responsables = []
      this.voluntarios = []
      this.showInputVoluntarios = false
      this.showInputResponsables = false
    }
  },
  created () {
    const { projectId } = this.$route.params
    const projectsArray = this.$store.getters.getProjects
    this.$store.subscribe((mutation) => {
      if (mutation.type === GET_PROJECT) {
        this.loadData(mutation.payload)
      }
    })
    this.$store.dispatch('fetchUsers')
    this.$store.dispatch('fetchCustomers')
    this.$store.dispatch('getProjectById', { projectId, projectsArray })
  }
}
</script>
<style>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loader {
  width: 50px;
  height: 50px;
}
</style>
