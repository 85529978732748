<template>
  <FormulateForm
    class="calendar-filter-form"
    name="calendar-filter-form"
    @submit="handleSubmit"
    @input="handleChange"
    v-model="form">
    <FormulateInput
      name="project"
      label="Proyecto"
      type="select"
      placeholder="Selecciona un proyecto"
      :options="getProjects.map(g => ({ value: g, text: g.name }))"
    />
    <div class="separator" />
    <FormulateInput
      name="state"
      label="Estado de la actividad"
      type="activityStatusSelect"
      placeholder="Seleccione un estado"
      :disabled="form.project == null"
    />
    <FormulateInput
      name="responsible"
      label="Responsable"
      type="autocomplete"
      :options="getUsers"
      :preserve-search="true"
      :close-on-select="true"
      :clear-on-select="false"
      track-by="id"
      placeholder="Selecciona un usuario"
      :disabled="form.project == null"
    />
  </FormulateForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { activityIsParent } from '@/utils/functionsUtils'

export default {
  name: 'CalendarFiltersForm',
  data () {
    return {
      activities: [],
      form: {
        project: null,
        activity: null,
        plannedTime: null,
        realTime: null,
        state: null,
        responsible: null,
        activityLevel: 0
      }
    }
  },
  created () {
    const projects = this.$store.getters.getProjects
    this.$store.dispatch('fetchProjects', projects)
    this.$store.dispatch('fetchUsers')
  },
  computed: {
    ...mapGetters(['getProjects', 'getUsers'])
  },
  watch: {
    'form.project' (value) {
      this.handleChangeProject(value)
    }
  },
  methods: {
    handleChangeProject (project) {
      let activities = []

      if (project != null) {
        activities.push(...project.activities)
      }

      activities = activities.filter((a) => activityIsParent(a.id, activities))

      this.activities = activities
    },
    handleChange (data) {
      this.$emit('onFiltersUpdate', data)
    },
    handleSubmit (data) {
      this.$emit('onFiltersUpdate', data)
    }
  }
}
</script>

<style scoped lang="scss">
.calendar-filter-form {
  div {
    margin-bottom: 1rem;
  }
}
.separator {
  margin: 1rem 0;
  border-bottom: 1px solid #dcdcdc;
}
</style>
