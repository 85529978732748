<template>
  <b-form-select
    v-model="context.model"
    v-bind="attributes"
    :options="context.options">
    <template v-if="!!attributes.placeholder" #first>
      <b-form-select-option value="" disabled>
        {{ attributes.placeholder }}
      </b-form-select-option>
    </template>
  </b-form-select>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'

export default {
  name: 'BootstrapSelect',
  mixins: [
    FormulateInputMixin
  ]
}
</script>
