// Actions

export interface ColumnMenuAction {
  label: string
  onClick: CallableFunction
}

// Column types

export interface Column {
  key: string
}

export interface TextColumn extends Column {
  label?: string
  formatter?: CallableFunction
  sortable?: boolean
}

export interface MenuColumn extends Column {
  label?: string
  actions: ColumnMenuAction[]
}

// Function helpers

export const createTextColumn = (
  key: string,
  label?: string,
  formatter?: CallableFunction,
  sortable?: boolean
): TextColumn => ({ key, label, formatter, sortable })

export const createActionsColumn = (
  actions: ColumnMenuAction[],
  label: string
): MenuColumn => ({ key: label, label, actions })
