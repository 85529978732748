var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{attrs:{"name":"activity-form"},on:{"submit":_vm.handleSubmit},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('FormulateInput',{attrs:{"name":"project","label":"Proyecto","type":"select","placeholder":"Seleccione un proyecto","options":_vm.projectStatusActive.map(function (g) { return ({ value: g, text: g.name }); })},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}}),_c('FormulateInput',{attrs:{"name":"activity","label":"Actividad","type":"select","placeholder":"Seleccione una actividad","disabled":_vm.project == null,"options":_vm.activities.map(function (g) { return ({ value: g, text: g.name }); })}}),_c('FormulateInput',{attrs:{"name":"responsible","label":"Responsable","type":"autocomplete","options":_vm.getUsers,"preserve-search":true,"close-on-select":true,"clear-on-select":false,"track-by":"id","placeholder":"Selecciona un usuario"}}),_c('FormulateInput',{attrs:{"name":"createdAt","label":"Fecha","type":"date","label-no-date-selected":"Seleccione una fecha"}}),_c('FormulateInput',{attrs:{"name":"isWeek","type":"radio","radio-options":[
      {
        text: 'Semana',
        value: true
      },
      {
        text: 'Día',
        value: false
      }
    ]}}),(!!_vm.form.isWeek)?_c('p',{staticStyle:{"margin":".5rem 0"}},[_vm._v("Semana "+_vm._s(_vm.weekNumber)+" del año "+_vm._s(_vm.yearNumber)+" ")]):_vm._e(),_c('b-row',[_c('b-col',[_c('FormulateInput',{attrs:{"name":"hours","label":"Horas","type":"number","min":"0"}})],1),_c('b-col',[_c('FormulateInput',{attrs:{"name":"minutes","label":"Minutos","type":"number","min":"0","max":"59"}})],1)],1),_c('FormulateInput',{attrs:{"name":"description","label":"Descripción","type":"textarea","placeholder":"Ingrese una descripción"}}),_c('p',{staticStyle:{"margin":".5rem 0"}},[_vm._v("Estado de la Actividad: "),_c('ActivityStatusTag',{attrs:{"status":_vm.form.activity ? _vm.form.activity.state : undefined}})],1),_c('FormulateInput',{staticStyle:{"margin-bottom":".5rem"},attrs:{"name":"changeActivityState","placeholder":"¿Cambiar estado de la actividad?","type":"checkbox"}}),_c('b-button',{staticClass:"mb-2",on:{"click":function($event){return _vm.toggleShowDisponibilidadModal(true)}}},[_vm._v(" Disponibilidad ")]),(_vm.form.changeActivityState)?_c('div',[_c('FormulateInput',{attrs:{"name":"newState","label":"Estado de la actividad","type":"activityStatusSelect","placeholder":"Seleccione un estado"}})],1):_vm._e(),(_vm.form.newState === _vm.ActivityState.STARTED || _vm.form.newState === _vm.ActivityState.FINISHED)?_c('div',[_c('FormulateInput',{attrs:{"name":"date","label":_vm.form.newState === _vm.ActivityState.STARTED ? 'Fecha de Inicio Real' : 'Fecha de Fin Real',"type":"date","label-no-date-selected":"Seleccione una fecha"}})],1):_vm._e(),_c('hr'),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"flex-end","align-items":"center"}},[_c('b-button',{staticStyle:{"margin-right":".5rem"},on:{"click":_vm.handleCancel}},[_vm._v(" Cancelar ")]),_c('FormulateInput',{attrs:{"type":"submit","variant":"primary","label":"Crear Registro"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }