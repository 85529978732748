
















import Vue from 'vue'

export default Vue.extend({
  name: 'Paper',
  props: {
    title: String,
    padding: {
      type: String,
      default: () => '1rem'
    },
    borderLess: {
      type: Boolean,
      default: () => false
    },
    shadowLess: {
      type: Boolean,
      default: () => false
    }
  }
})
