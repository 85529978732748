export const getCustomers = (state): any[] => {
  return state.customers
}

export const getCustomer = (state): any => {
  return state.customer
}

export default {
  getCustomers,
  getCustomer
}
