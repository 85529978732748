<template>
  <div class="d-flex justify-content-center align-items-center" style="height: 70vh;">
    <div :class="width">
      <Paper title="Recuperación de contraseña">
        <FormulateForm name="login-user" @submit="resetPassword">
          <b-container>
            <b-row class="justify-content-center align-items-center">
              <b-col md="12" lg="8">
                <label for="password">Contraseña</label>
                <input name="password" id="password" class="form-control" type="password" placeholder="Contraseña"
                  v-model="pass" required>
              </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center">
              <b-col md="12" lg="8">
                <label for="password2">Contraseña</label>
                <input name="password2" id="password2" class="form-control" type="password" placeholder="Contraseña"
                  v-model="pass2" required>
              </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center">
              <b-col md="12" lg="4">
                <FormulateInput style="display: inline-block; margin-left: .5rem; margin-top: 1rem;" type="submit"
                  variant="primary" label="Restablecer contraseña" />
              </b-col>
            </b-row>
          </b-container>
        </FormulateForm>
      </Paper>
      <div class="mt-2">
        <b-button @click="handleBackLogin"> Volver al login </b-button>
        <b-button @click="handleInstrucciones" class="ml-2">Ayuda</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Paper } from '@/components'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'SelectDepartmentPage',
  components: { Paper },
  data () {
    return {
      userId: this.$route.query.id,
      pass: null,
      pass2: null,
      msg: '',
      error: false,
      success: false
    }
  },
  methods: {
    resetPassword () {
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      if (this.pass !== '' && this.pass !== null) {
        if (this.pass === this.pass2) {
          const formData = new URLSearchParams()
          formData.append('action', 'recover_pass')
          formData.append('id', this.userId)
          formData.append('pass', this.pass)
          axios.post(entryPoint, formData).then((response) => {
            this.error = response.data.error
            this.msg = response.data.msg
            if (this.error === false) {
              this.success = true
              Swal.fire({
                icon: 'success',
                title: 'Tu contraseña ha sido cambiada',
                showConfirmButton: false,
                timer: 1000
              })
              this.handleBackLogin()
            } else {
              Swal.fire({
                icon: 'warning',
                title: this.msg,
                showConfirmButton: false,
                timer: 1000
              })
            }
          })
        } else {
          this.error = true
          this.msg = 'Las contraseñas no coinciden.'
        }
      } else {
        this.error = true
        this.msg = 'La contraseña no puede estar vacia.'
      }
    },
    handleBackLogin () {
      this.$router.push('/login')
    },
    handleInstrucciones () {
      Swal.fire('Para restablecer tu contraseña debereás escribir 2 veces la misma combinación')
    }
  },
  computed: {
    width () {
      return document.body.clientWidth < 1000 ? 'w-70' : 'w-50'
    }
  }
}
</script>
<style>
.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container h2 {
  margin-right: 40px;
  font-size: 30px;
}
</style>
