<template>
  <div class="w-100 file-viewer">
    <div class="w-100 h-100 d-flex flex-wrap icon-container"
      @click="handleAddFileClick"
      @drop="handleDrop"
      @dragenter="handleDragEnter"
      @dragleave="handleDragLeave"
      @dragover="handleDragOver">
      <b-icon-file-earmark-plus
        id="add-file-icon"
        class="add-file-icon"
        v-if="totalFiles.length === 0 || showDropIcon"></b-icon-file-earmark-plus>
      <template v-else>
        <FileObject v-for="file in totalFiles" :key="file.id" :file="file"></FileObject>
      </template>
      <input class="d-none" type="file" multiple id="files-input" @change="handleInputFileChange">
    </div>
  </div>
</template>
<script>
import { FileObject } from '@/components'

export default {
  name: 'UploadFile',
  components: { FileObject },
  data () {
    return {
      files: [],
      inputFiles: [],
      dragCounter: 0,
      showDropIcon: true
    }
  },
  methods: {
    handleAddFileClick () {
      document.getElementById('files-input').click()
    },
    handleDrop (e) {
      const iconEl = document.getElementById('add-file-icon')
      if (iconEl) {
        iconEl.classList.remove('icon-hover')
      }

      e.preventDefault()
      this.dragCounter = 0
      if (e.dataTransfer.items) {
        [...e.dataTransfer.items].forEach((item, i) => {
          if (item.kind === 'file') {
            const file = item.getAsFile()
            file.id = crypto.randomUUID()
            this.files.push(file)
          }
        })
      } else {
        [...e.dataTransfer.files].forEach((file, i) => {
          this.files.push(file)
        })
      }

      if (this.files.length > 0) {
        this.showDropIcon = false
      }

      this.$emit('fileschange', this.totalFiles)
    },
    handleDragOver (e) {
      e.preventDefault()
    },
    handleDragEnter (e) {
      e.preventDefault()
      this.dragCounter++
      this.showDropIcon = true

      const iconEl = document.getElementById('add-file-icon')
      if (iconEl) {
        iconEl.classList.add('icon-hover')
      }
    },
    handleDragLeave (e) {
      this.dragCounter--
      if (this.dragCounter === 0) {
        this.showDropIcon = false
        const iconEl = document.getElementById('add-file-icon')
        if (iconEl) {
          iconEl.classList.remove('icon-hover')
        }
      }
    },
    handleInputFileChange (e) {
      const inputFiles = e.target.files || e.dataTransfer.files
      if (!inputFiles.length) {
        return
      }

      const inputFilesArray = [...inputFiles]
      inputFilesArray.forEach(f => {
        f.id = crypto.randomUUID()
      })
      this.inputFiles = inputFilesArray
      if (this.inputFiles.length > 0) {
        this.showDropIcon = false
      }
      this.$emit('fileschange', this.totalFiles)
    }
  },
  computed: {
    totalFiles () {
      return [...this.files, ...this.inputFiles]
    }
  }
}
</script>
<style scoped lang="scss">
  .icon-hover {
    color: #707070 !important;
  }

  .file-viewer {
    min-height: 200px;
    max-height: 200px;
    height: 100%;
    overflow-y: scroll;
    border: 1px solid #d0d0d0;
    border-radius: .5rem;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
    .icon-container{
      .add-file-icon {
        width: 100%;
        height: 100%;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #d0d0d0;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          color: #707070
        }
      }
    }
  }
</style>
