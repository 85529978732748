<template>
  <li class="node">
    <div class="node-reference">
      {{ node.numeracion }}
    </div>
    <div class="node-header">
      <div
        :class="`node-arrow ${ open ? 'node-arrow--open' : '' } ${ !nodes.find(n => n.parentId === node.id) ? 'node-arrow--hidden' : '' }`"
        @click="toggleOpen"
      />
      <div
        class="node-info"
        @click="() => handleNodeClick(this.node)">
        <div class="node-icon" />
        <div class="node-name">
          <span :style="'color:'+ node.color" class="node-name-text">{{ node ? node.name : 'Sin nombre' }}</span>
        </div>
      </div>
    </div>
    <ul
      class="node-body"
      v-if="open">
      <template v-for="subNode in nodes">
        <ActivityTreeNode
          :key="subNode.id"
          :node="subNode"
          :nodes="nodes"
          @click="handleNodeClick"
          v-if="subNode.parentId === node.id"
        />
      </template>
    </ul>
  </li>
</template>

<script>
import ActivityProgressBar from './ActivityProgressBar'
import { ActivityStatusTag } from '../ActivityStatusTag'

export default {
  name: 'ActivityTreeNode',
  components: { ActivityProgressBar, ActivityStatusTag },
  props: [
    'nodes',
    'node'
  ],
  data () {
    return {
      open: false
    }
  },
  methods: {
    toggleOpen () {
      this.open = !this.open
    },
    handleNodeClick (node) {
      if (this.node === node) {
        this.toggleOpen()
      }
      console.log(this.node)
      this.$emit('click', node)
    }
  }
}
</script>

<style scoped lang="scss">
.node {
  margin: .40rem 0;
  .node-reference {
    opacity: 0.5;
    font-size: .85rem;
    left: 0;
    position: absolute;
  }

  .node-header {
    display: flex;
    flex-direction: row;
    padding-left: 1rem;

    .node-arrow {
      display: inline;
      content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="%232b50ed" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');

      cursor: pointer;
      transition: all 0.2s;

      &--open {
        transform: rotate(180deg);
      }

      &--hidden {
        cursor: default;
        content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="%232b50ed" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"></svg>');
      }
    }

    .node-info {
      flex: 1;
      display: flex;
      flex-direction: row;
      cursor: pointer;

      .node-icon {
        display: inline;
        content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="%232b50ed" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>');
        margin-right: .5rem;
      }

      .node-name {
        flex: 1;
        font-size: .85rem;
        color: black;
        font-weight: 600;
        max-height: 3em; /* Establece una altura máxima para el contenedor */
        overflow: hidden; /* Oculta cualquier contenido que exceda la altura máxima */
        /* Ajusta la altura máxima según tus necesidades. Aquí, he usado 3em como ejemplo */
        word-break: break-all;
      }

      .node-stats {
        right: 0;
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: .85rem;
        color: gray;
      }
    }

    &:hover,
    &:active {
      .node-name {
        color: black;
      }
    }
  }

  .node-body {
    padding: 0 0 0 1.20rem;
    list-style: none;
  }
}
</style>
