<template>
  <div :class="`status-tag ${ status ? status.toLowerCase() : 'undefined' }`">
    {{ status | statusLabel | limitCharacters(this.characters) }}
  </div>
</template>

<script>
import { ActivityState } from '@/utils/constants'

export default {
  name: 'ActivityStatusTag',
  props: ['status', 'characters'],
  filters: {
    limitCharacters (value, characters) {
      if (characters != null) {
        return value.substr(0, Number(characters))
      }
      return value
    },
    statusLabel (value) {
      switch (value) {
        case ActivityState.NOT_STARTED:
          return 'No iniciado'
        case ActivityState.STARTED:
          return 'Iniciado'
        case ActivityState.FINISHED:
          return 'Finalizado'
        case ActivityState.DELAYED:
          return 'Aplazado'
        case ActivityState.DISCARDED:
          return 'Descartado'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.status-tag {
  display: inline-block;
  font-size: .75rem;
  font-weight: bold;
  text-align: center;
  padding: .05rem .4rem;
  min-width: 1.4rem;
  border-radius: 1rem;
  color: white;
  background-color: #e5e5e5;

  &.notstarted {
    color: #5e5e5e;
  }

  &.started {
    background-color: #2e92f6;
  }

  &.finished {
    background-color: #2eb82e;
  }

  &.delayed {
    background-color: #ff852c;
  }

  &.discarded {
    background-color: #ff4343;
  }
}
</style>
