<template>
  <b-modal
    id="activity-modal"
    size="lg"
    title="Alta de Actividad"
    scrollable
    no-close-on-backdrop
    no-close-on-esc
    centered>
    <ActivityForm
      ref="activity-form"
      :data="activity"
      :responsables="responsables"
      :voluntarios="voluntarios"
      v-on:submit="handleActivityFormSubmit"
    />

    <template #modal-footer>
      <div class="w-100">
        <b-button
          class="float-left"
          variant="danger"
          size="sm"
          :title="(activity.registries && activity.registries.length > 0) ? 'No puedes eliminar una actividad con horas registradas' : ''"
          v-if="!!activity"
          :disabled="activity.registries && activity.registries.length > 0"
          @click="handleDelete">
          Eliminar
        </b-button>
        <div class="float-right">
          <b-button
            size="sm"
            style="margin-right: .5rem"
            @click="handleCancel">
            Cancelar
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            @click="() => $refs['activity-form'].submit()">
            Guardar
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ActivityForm } from '@/views/ProjectsPage/components'

export default {
  name: 'ActivityFormDialog',
  components: { ActivityForm },
  props: {
    activity: Object,
    responsables: [],
    voluntarios: Array
  },
  methods: {
    handleActivityFormSubmit (data) {
      this.$emit('submit', data)
    },
    handleCancel () {
      this.$emit('cancel')
    },
    handleDelete () {
      const { activity } = this

      if (activity.registries?.length) {
        console.warn('Tried to delete activity with hours registered')
      } else {
        this.$emit('delete', activity)
      }
    }
  }
}
</script>
