<template>
  <div style="display: flex; flex-direction: row; align-items: center">
    <div class="progress-bar__container">
      <div class="progress-bar__background" />
      <div class="progress-bar__fill" :style="`width: ${value2 * 100 / value1}%`" />
    </div>
    <span>{{ Math.floor(value2 * 100 / value1) }}%</span>
  </div>
</template>

<script>
export default {
  name: 'Ratio',
  props: ['value1', 'value2']
}
</script>

<style scoped lang="scss">
.progress-bar__container {
  position: relative;
  width: 150px;
  height: 8px;
  margin-right: 1rem;

.progress-bar__background {
  border-radius: .5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #b2b2b2;
}

.progress-bar__fill {
  border-radius: .5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  max-width: 100%;
  height: 100%;
  background-color: black;
}
}
</style>
