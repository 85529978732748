<template>
  <b-form-checkbox
    v-model="context.model"
    v-bind="attributes">
    {{ attributes.placeholder }}
  </b-form-checkbox>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'

export default {
  name: 'BootstrapCheckbox',
  mixins: [
    FormulateInputMixin
  ]
}
</script>
