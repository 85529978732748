import * as mutations from './mutations'
import axios from 'axios'
import qs from 'qs'

const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT

export const fetchCustomers = ({ commit }: any) => {
  let customers

  const data = qs.stringify({
    action: 'get_users'
  })

  const config: any = {
    method: 'post',
    url: entryPoint,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  }

  axios(config)
    .then(response => {
      customers = response.data

      commit(mutations.GET_CUSTOMERS, customers)
    })
}

export const fetchCustomerById = ({ commit }, customerId) => {
  let customer

  const data = qs.stringify({
    action: 'get_customer',
    customer_id: customerId
  })

  const config: any = {
    method: 'post',
    url: entryPoint,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  }

  axios(config)
    .then(response => {
      customer = response.data

      console.log('fetchById: ' + customer)

      commit(mutations.GET_CUSTOMER, customer)
    })
}

export default {
  fetchCustomers,
  fetchCustomerById
}
