<template>
  <AdminPageContainer title="Registro de hora">
    <Paper>
      <ActivityRegistryForm
        v-on:cancel="handleBack"
        v-on:submit="handleSubmit"
      />
    </Paper>
  </AdminPageContainer>
</template>

<script>
import { ActivityRegistryForm } from './components'
import { AdminPageContainer, Paper } from '@/components'
import { ActivityState } from '@/utils/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectsCreateActivityRegistry',
  components: { AdminPageContainer, Paper, ActivityRegistryForm },
  created () {
    const { projectId } = this.$route.params
    const projectsArray = this.$store.getters.getProjects
    this.$store.dispatch('getProjectById', { projectId, projectsArray })
  },
  computed: {
    ...mapGetters(['getProject'])
  },
  methods: {
    handleBack () {
      const { projectId } = this.$route.query

      this.$router.push('/projects' + (projectId != null ? `/${projectId}` : ''))
    },
    handleSubmit ({ activity, project, createdAt, description, hours, minutes, responsible, changeActivityState, newState, date, isWeek }) {
      if (activity.registries == null) {
        activity.registries = []
      }

      if (changeActivityState) {
        activity.state = newState
        switch (newState) {
          case ActivityState.STARTED:
            activity.startDate = date
            break
          case ActivityState.FINISHED:
            activity.endDate = date
            break
        }
      }

      const index = project.activities.findIndex(i => i.id === activity.id)
      activity.registries.push({ createdAt, description, hours, minutes, responsible })
      activity.hoursSpent = Number((parseInt(activity.hoursSpent) + parseInt(hours) + (Number(minutes) / 60)).toFixed(2))
      project.activities[index] = activity
      project.newRegistrie = { createdAt, description, hours, minutes, activityId: activity.id, responsible, isWeek: !!isWeek }
      if (changeActivityState) {
        project.newRegistrie.newState = newState
      }
      const projectsArray = this.$store.getters.getProjects

      this.$store.dispatch('updateProject', { project, projectsArray, deleteActivitiesIds: [], router: this.$router, route: this.$route })
      // Actualizo el estado en la vista
      project.status = 'Started'
      // this.$router.push('/projects/' + project.id)
    }
  }
}
</script>
