import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AdminPageTemplate } from '@/templates'
import {
  ProjectsCreatePage,
  ProjectsInfoPage,
  ProjectsPage,
  ProjectsCreateActivityRegistry,
  ProjectsUpdatePage,
  CalendarPage,
  Login,
  Contacts,
  Actividades,
  Recover,
  ResetPass,
  MapsView,
  CreateContact,
  UpdateContact
} from '@/views'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login',
    component: AdminPageTemplate,
    children: [
      {
        path: 'login',
        component: Login
      },
      {
        path: 'MapsView',
        component: MapsView
      },
      {
        path: 'recover',
        component: Recover
      },
      {
        path: 'resetPass',
        component: ResetPass
      },
      {
        path: 'projects',
        component: ProjectsPage
      },
      {
        path: 'projects/create',
        component: ProjectsCreatePage
      },
      {
        path: 'projects/activity/create',
        component: ProjectsCreateActivityRegistry
      },
      {
        path: 'projects/:projectId',
        component: ProjectsInfoPage
      },
      {
        path: 'projects/:projectId/update',
        component: ProjectsUpdatePage
      },
      {
        path: 'calendar',
        component: CalendarPage
      },
      {
        path: 'contacts',
        component: Contacts
      },
      {
        path: 'contacts/create',
        component: CreateContact
      },
      {
        path: 'contacts/:contactId/update',
        component: UpdateContact
      },
      {
        path: 'actividades',
        component: Actividades
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
