<template>
  <b-form-input
    v-model="context.model"
    v-bind="attributes"
    type="number">
  </b-form-input>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'

export default {
  name: 'BootstrapNumber',
  mixins: [
    FormulateInputMixin
  ]
}
</script>
