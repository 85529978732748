<template>
  <div>
    <template v-if="!funcion">
      <p>You don't have access to Contacts module.</p>
    </template>
    <template v-else>
    <AdminPageContainer title="Contactos">
      <b-button @click="toggleShowDisponibilidadModal(true)">
        Disponibilidad
      </b-button>
      <b-button class="ml-2" @click="$router.push(`contacts/create`)">
        Crear contacto
      </b-button>
      <DataTable :columns="columns" :items="getCustomersDelUsuario">
        <template #headerLeft>
          <FormulateForm id="formFinal" v-model="search" class="col-12 no-gutters"
            style="display: flex ; flex-direction: row; gap:5px" @submit="handleSearch">
            <FormulateInput name="first_name" v-model="first_name"  placeholder="Buscar por nombre" :class="getColumnClass" />
            <FormulateInput name="last_name" v-model="last_name" placeholder="Buscar por apellidos" :class="getColumnClass" />
            <FormulateInput name="email1" v-model="email1" placeholder="Buscar por Correo Electronico" :class="getColumnClass" />
            <FormulateInput name="funcion_c" v-model="funcion_c" type="autocomplete" :options="userTypes" :multiple="false"
              :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
              deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento"
              placeholder="Tipo de Voluntario" :class="getColumnClass" />
            <FormulateInput name="estado_intranet_c" v-model="estado_intranet_c" type="autocomplete" :options="userStates" :multiple="false"
              :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
              deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento" placeholder="Estado"
              :class="getColumnClass" />
            <FormulateInput name="proyecto" v-model="proyecto" type="autocomplete" :options="[
                {
                  name: 'Sin Mission',
                  value: '',
                  id: ''
                },
                ...UserProjects
              ]" :multiple="false"
              :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
              deselect-label="No puedes deseleccionar mientras está seleccionado 1 elemento" placeholder="Mission"
              :class="getColumnClass" />
            <FormulateInput name="grupo_c" v-model="grupo_c" placeholder="Grupo, Club o Asociación" :class="getColumnClass" />
            <FormulateInput style="display: inline-block;" type="submit" variant="primary" label="Búsqueda"
              :class="getColumnClass" />
          </FormulateForm>
          <div class="col d-sm-block" style="display: flex; flex-direction: row; gap: 5px; margin-top: 1rem;">
            <b-button class="d-none d-sm-block" style="display: flex; flex-direction: row; gap: 5px; margin-top: 1rem;"
              @click="clearFiltro()">
              Limpiar Filtro
            </b-button>
          </div>
        </template>
        <template #cell(name)="row">
          <div>
            <div class="buttons-groups" v-show="funcion !== 'voluntario'">
              <a :href="'#/contacts/' + row.item.id + '/update'">
                <b class="">{{ row.item.name }}</b>
              </a>
            </div>
            <div class="buttons-groups" v-show="funcion === 'voluntario'">
              <a>{{ row.item.name }}</a>
            </div>
          </div>
        </template>
        <template #cell(actions)="row">
          <div>
            <div class="buttons-groups">
              <a :href="'https://wa.me/34' + row.item.phone" target="_blank"> <i class="bi bi-whatsapp"
                  style="font-size: 20px;"></i></a>
            </div>
          </div>
        </template>
      </DataTable>
      <div class="loader-container" v-if="loader">
        <vue-loaders name="ball-clip-rotate-pulse" color="black" scale="1"></vue-loaders>
      </div>
      <div class="loader-container" v-if="loader">
        <vue-loaders name="ball-clip-rotate-pulse" color="black" scale="1"></vue-loaders>
      </div>
      <CheckDisponibilidad :camposFiltro="fieldsFiltro" v-on:submit="findContactos"
        v-on:cancel="() => this.toggleShowDisponibilidadModal(false)"></CheckDisponibilidad>
    </AdminPageContainer>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdminPageContainer, DataTable, createTextColumn } from '@/components'
import { CheckDisponibilidad } from '../ProjectsPage/components/CheckDisponibilidad'
import axios from 'axios'
import Swal from 'sweetalert2'

const columns = [
  createTextColumn('name', 'Nombre', null, true),
  createTextColumn('email1', 'Correo Electronico', null, true),
  createTextColumn('estado', 'Estado', null, true),
  createTextColumn('club', 'Club/Asociacion', null, true),
  createTextColumn('cargo', 'Cargo', null, true),
  createTextColumn('phone', 'Telefono', null, true),
  createTextColumn('actions', 'Acciones')
]

const contactos = []

const contactosSinFiltrar = []

export default {
  name: 'Contacts',
  components: { AdminPageContainer, DataTable, createTextColumn },
  data () {
    return {
      userStates: [
        { id: 'pendiente', name: 'Pendiente de revisar' },
        { id: 'activo', name: 'Activo' },
        { id: 'inactivo', name: 'Inactivo' },
        { id: 'duplicado', name: 'Duplicado' }
      ],
      userTypes: [
        { id: 'director', name: 'Director MMUA' },
        { id: 'coordinador', name: 'Coordinador de voluntarios' },
        { id: 'voluntario', name: 'Voluntario' }
      ],
      columns,
      isAdmin: localStorage.getItem('is_admin'),
      contactos,
      contactosSinFiltrar,
      fieldsFiltro: [],
      funcion: localStorage.getItem('funcion'),
      userId: localStorage.getItem('id'),
      search: {},
      first_name: '',
      last_name: '',
      email1: '',
      funcion_c: '',
      estado_intranet_c: '',
      proyecto: '',
      grupo_c: '',
      loader: false,
      UserProjects: {}
    }
  },
  methods: {
    showAllContacts () {
      this.loader = true
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'getAllContacts')
      axios
        .post(entryPoint, formData)
        .then((response) => {
          this.contactos = response.data
          this.contactosSinFiltrar = response.data
          this.loader = false
        })
    },
    getColumnClass () {
      const mediaQuery = window.matchMedia('(min-width: 600px)')
      if (mediaQuery.matches) {
        return 'col-4 pl-0'
      } else {
        return 'col-12'
      }
    },
    findContactos (filtro) {
      this.loader = true
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'findContactos')
      formData.append('camposFiltrados', JSON.stringify(filtro))
      axios
        .post(entryPoint, formData)
        .then(async (response) => {
          console.log(response.data)
          this.contactos = response.data
          this.toggleShowDisponibilidadModal(false)
          this.loader = false
        })
    },
    toggleShowDisponibilidadModal (show) {
      if (show) {
        this.$bvModal.show('check-disponibilidad')
      } else {
        this.$bvModal.hide('check-disponibilidad')
      }
    },
    getFilterFields () {
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'getFilterFields')
      axios
        .post(entryPoint, formData)
        .then((response) => {
          this.fieldsFiltro = response.data
        })
    },
    clearFiltro () {
      this.contactos = this.contactosSinFiltrar
      this.search = ''
      this.first_name = ''
      this.last_name = ''
      this.email1 = ''
      this.funcion_c = ''
      this.estado_intranet_c = ''
      this.proyecto = ''
      this.grupo_c = ''
    },
    handleSearch ({ search }) {
      this.loader = true
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'searchContact')
      formData.append('searchData', JSON.stringify(this.search))
      axios
        .post(entryPoint, formData)
        .then((response) => {
          this.contactos = response.data
          this.loader = false
        })
      this.showButton = true
    },
    getProjectsDelUsuario () {
      const projectsArray = this.$store.getters.getProjects
      let proyectosEncontrados = []
      if (this.funcion !== 'voluntario') {
        console.log('entro1')
        proyectosEncontrados = projectsArray
        this.UserProjects = proyectosEncontrados
      } else {
        if (!Array.isArray(projectsArray)) {
          console.log('projectsArray.projects is either empty, undefined, or not an array.')
        } else {
          projectsArray.forEach(proyecto => {
            if (proyecto.responsible) {
              const currentUserIsResponsible = proyecto.responsible.id === this.userId
              if (currentUserIsResponsible) {
                const filteredCustomers = proyecto.customer.filter((p) => p.id !== this.userId)
                filteredCustomers.forEach((customer) => {
                  if (!proyectosEncontrados.some((p) => p.id === customer.id)) {
                    proyectosEncontrados.push(proyecto)
                  }
                })
              } else if (proyecto.customer && proyecto.customer.length > 0) {
                const currentUserIsInProject = proyecto.customer.some((invitado) => invitado.id === this.userId)
                if (currentUserIsInProject) {
                  const filteredCustomers = proyecto.customer.filter((p) => p.id !== this.userId)
                  filteredCustomers.forEach((customer) => {
                    if (!proyectosEncontrados.some((p) => p.id === customer.id)) {
                      proyectosEncontrados.push(proyecto)
                    }
                  })
                  const responsibleAlreadyExists = proyectosEncontrados.some((p) => p.id === proyecto.responsible.id)
                  if (!responsibleAlreadyExists) {
                    proyectosEncontrados.push(proyecto)
                  }
                }
              }
            }
          })
        }
        return proyectosEncontrados
      }
    }
  },
  mounted () {
    this.showAllContacts()
    this.getFilterFields()
    this.getProjectsDelUsuario()
  },
  computed: {
    ...mapGetters({
      projects: 'getProjects'
    }),
    getCustomersDelUsuario () {
      const projectsArray = this.$store.getters.getProjects
      let personasEncontradas = []
      if (this.funcion !== 'voluntario') {
        personasEncontradas = this.contactos
        return personasEncontradas
      } else {
        if (!Array.isArray(projectsArray)) {
          console.log('projectsArray.projects is either empty, undefined, or not an array.')
        } else {
          projectsArray.forEach(proyecto => {
            if (proyecto.responsible) {
              const currentUserIsResponsible = proyecto.responsible.id === this.userId
              if (currentUserIsResponsible) {
                const filteredCustomers = proyecto.customer.filter((p) => p.id !== this.userId)
                filteredCustomers.forEach((customer) => {
                  if (!personasEncontradas.some((p) => p.id === customer.id)) {
                    personasEncontradas.push(customer)
                  }
                })
              } else if (proyecto.customer && proyecto.customer.length > 0) {
                const currentUserIsInProject = proyecto.customer.some((invitado) => invitado.id === this.userId)
                if (currentUserIsInProject) {
                  const filteredCustomers = proyecto.customer.filter((p) => p.id !== this.userId)
                  filteredCustomers.forEach((customer) => {
                    if (!personasEncontradas.some((p) => p.id === customer.id)) {
                      personasEncontradas.push(customer)
                    }
                  })
                  const responsibleAlreadyExists = personasEncontradas.some((p) => p.id === proyecto.responsible.id)
                  if (!responsibleAlreadyExists) {
                    personasEncontradas.push(proyecto.responsible)
                  }
                }
              }
            }
          })
        }
        return personasEncontradas
      }
    }
  },
  created () {
    const projects = this.$store.getters.getProjects
    this.$store.dispatch('fetchProjects', projects)
  }
}
</script>
<style scoped lang="scss">
.widgets-container {
  border: 1px solid black;
  max-width: 100%;

  &>div {
    height: 40rem;
    border: 1px solid red;
  }
}

.buttons-groups {
  display: inline-block;
}

@media (max-width: 500px) {
  #formFinal {
    flex-direction: column !important;
  }
}

@media (min-width: 501px) {
  #formFinal {
    flex-direction: row !important;
  }
}

.actions-dropdown {
  display: none;
}

@media (max-width: 1440px) {
  .buttons-groups {
    display: inline-block;
  }

  .actions-dropdown {
    display: inline-block;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loader {
  width: 50px;
  height: 50px;
}
</style>
