<template>
  <AdminPageContainer title="Calendario">
    <b-container fluid>
      <b-row>
        <b-col>
          <Paper>
            <CalendarFiltersForm
              @onFiltersUpdate="requestCalendarUpdate"
            />
          </Paper>
        </b-col>
        <b-col lg="9" md="12">
          <Paper>
            <FullCalendar :options="calendarOptions" />
          </Paper>
        </b-col>
      </b-row>
    </b-container>
  </AdminPageContainer>
</template>

<script>
import { AdminPageContainer, Paper } from '@/components'
import { CalendarFiltersForm } from './components'
import { mapGetters } from 'vuex'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'

export default {
  name: 'CalendarPage',
  components: { AdminPageContainer, Paper, CalendarFiltersForm, FullCalendar },
  data () {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, listPlugin],
        aspectRatio: 2,
        firstDay: 1,
        locale: esLocale,
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prevYear,prev,next,nextYear today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,listMonth'
        },
        events: []
      }
    }
  },
  methods: {
    requestCalendarUpdate (params) {
      this.$store.dispatch('fetchCalendar', params).then(() => {
        this.calendarOptions.events = this.$store.getters.getCalendar
      })
    }
  },
  computed: {
    ...mapGetters({
      calendarData: 'getCalendar'
    })
  }
}
</script>

<style scoped>

</style>
