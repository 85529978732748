<template>
  <div class="page-container">
    <header class="page-container__header">
      <div class="page-container__title">
        <h3>{{ title }}</h3>
      </div>
    </header>
    <main class="page-container__content">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: 'AdminPageContainer',
  props: ['title']
}
</script>

<style src="./_AdminPageContainer.scss" lang="scss" scoped />
