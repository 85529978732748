<template>
  <Widget paddingBody="1rem">
    <div class="card-container">
      <Card label="Proyectos" :value="data.totalProjects" />
      <!--<Card label="Total de proyectos por estado" :value="4" :status="true" />-->
      <Card>
        <p>Total de proyectos por estado</p>
        <ol>
          <li style="color: #5e5e5e">No iniciado: {{ data.projectAmountByStatus.notStarted }}</li>
          <li style="color: #2e92f6">Iniciado: {{ data.projectAmountByStatus.started }}</li>
          <li style="color: #2eb82e">Finalizado: {{ data.projectAmountByStatus.finished }}</li>
          <li style="color: #ff852c">Aplazado: {{ data.projectAmountByStatus.delayed }}</li>
          <li style="color: #ff4343">Descartado: {{ data.projectAmountByStatus.discarded }}</li>
        </ol>
      </Card>
      <Card label="Horas Realizadas vs Presupuestadas" :value="`${data.totalFinishedHours}hs / ${data.totalBudgetedHours}hs`" />
      <!--<Card label="Total de horas por estado" :value="19" :format="(x) => `${x}hs`" :status="true" />-->
      <Card>
        <p>Total de horas realizadas por estado</p>
        <ol>
          <li style="color: #5e5e5e">No iniciado: {{ data.hoursByStatus.notStarted }}hs</li>
          <li style="color: #2e92f6">Iniciado: {{ data.hoursByStatus.started }}hs</li>
          <li style="color: #2eb82e">Finalizado: {{ data.hoursByStatus.finished }}hs</li>
          <li style="color: #ff852c">Aplazado: {{ data.hoursByStatus.delayed }}hs</li>
          <li style="color: #ff4343">Descartado: {{ data.hoursByStatus.discarded }}hs</li>
        </ol>
      </Card>
    </div>
  </Widget>
</template>

<script>
import { Widget } from '../Widget'
import Card from './Card'

export default {
  name: 'OverviewWidget',
  components: { Widget, Card },
  props: ['data']
}
</script>

<style scoped lang="scss">
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > div:last-child {
    border: none;
  }
}

ol {
  text-align: start;
  list-style: none;
  padding: 0;
}

li {
  font-size: 1.5rem;
}

@media (max-width: 960px) {
  .card-container {
    flex-direction: column;
  }
}
</style>
