var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.funcion)?[_c('p',[_vm._v("You don't have access to Actividades module.")])]:[_c('AdminPageContainer',{attrs:{"title":"Actividades"}},[_c('DataTable',{attrs:{"columns":_vm.columns,"items":_vm.getProyectoDeActividad},scopedSlots:_vm._u([{key:"headerLeft",fn:function(){return [_c('FormulateForm',{staticClass:"col-12 no-gutters",staticStyle:{"display":"flex","flex-direction":"row","gap":"5px"},attrs:{"id":"formFinal"},on:{"submit":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('FormulateInput',{ref:"search_input",class:_vm.getColumnClass,attrs:{"name":"name","placeholder":"Buscar por nombre"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('FormulateInput',{class:_vm.getColumnClass,attrs:{"name":"project","type":"autocomplete","options":[
               {
                 name: 'Sin Proyecto',
                 value: '',
                 id: ''

               } ].concat( _vm.projects
             ),"preserve-search":true,"close-on-select":true,"clear-on-select":false,"track-by":"id","placeholder":"Selecciona un Proyecto"},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}}),_c('FormulateInput',{class:_vm.getColumnClass,attrs:{"name":"assigned_user_id","type":"autocomplete","options":[
               {
                 name: 'Sin Responsable',
                 value: '',
                 id: ''
               } ].concat( _vm.customers
             ),"preserve-search":true,"close-on-select":true,"clear-on-select":false,"track-by":"id","placeholder":"Selecciona un Responsable"},model:{value:(_vm.assigned_user_id),callback:function ($$v) {_vm.assigned_user_id=$$v},expression:"assigned_user_id"}}),_c('FormulateInput',{class:_vm.getColumnClass,attrs:{"name":"customer","type":"autocomplete","options":[
               {
                 name: 'Sin Invitados',
                 value: '',
                 id: ''

               } ].concat( _vm.customers
             ),"preserve-search":true,"close-on-select":true,"clear-on-select":false,"track-by":"id","placeholder":"Selecciona un Invitado"},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}}),_c('FormulateInput',{staticClass:"col d-block col-2",staticStyle:{"display":"inline-block"},attrs:{"type":"submit","variant":"primary","label":"Buscar"}})],1),_c('div',{staticClass:"col d-sm-block",staticStyle:{"display":"flex","flex-direction":"row","gap":"5px","margin-top":"1rem"}},[_c('b-button',{staticClass:"d-none d-sm-block",staticStyle:{"display":"flex","flex-direction":"row","gap":"5px","margin-top":"1rem"},on:{"click":function($event){return _vm.clearFiltro()}}},[_vm._v(" Limpiar Filtro ")])],1)]},proxy:true},{key:"cell(name)",fn:function(row){return [_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.funcion !== 'voluntario'),expression:"funcion !== 'voluntario'"}],staticClass:"buttons-groups"},[_c('a',{style:({ color: row.item.color }),attrs:{"href":'#/projects/' + row.item.id_proyecto}},[_c('b',{},[_vm._v(_vm._s(row.item.name))])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.funcion === 'voluntario'),expression:"funcion === 'voluntario'"}],staticClass:"buttons-groups"},[_c('b',{},[_vm._v(_vm._s(row.item.name))])])])]}}])}),(_vm.loader)?_c('div',{staticClass:"loader-container"},[_c('vue-loaders',{attrs:{"name":"ball-clip-rotate-pulse","color":"black","scale":"1"}})],1):_vm._e(),_c('CheckDisponibilidad',{attrs:{"camposFiltro":_vm.fieldsFiltro},on:{"submit":_vm.findActividad,"cancel":function () { return this$1.toggleShowDisponibilidadModal(false); }}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }