<template>
  <div class="file">
    <div>
      <div class="d-flex text-center">
        <i :class="'bi ' + getFileClass(file.type)" class="col-12"></i><br>
      </div>
      <div class="w-100 text-center">
        <span class="col-12 file-name">{{ file.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FileObject',
  data () {
    return {
      fileClassConversor: {
        'application/pdf': 'bi-filetype-pdf',
        'text/csv': 'bi-filetype-csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'bi-filetype-xlsx',
        'application/vnd.ms-excel': 'bi-filetype-xls',
        'image/png': 'bi-filetype-png',
        'image/jpeg': 'bi-filetype-jpg',
        'text/plain': 'bi-filetype-txt',
        'application/x-zip-compressed': 'bi-file-zip'
      }
    }
  },
  props: ['file'],
  methods: {
    getFileClass (type) {
      if (!this.fileClassConversor[type]) return 'bi-file-earmark'

      return this.fileClassConversor[type]
    }
  }
}
</script>
<style scoped lang="scss">
  .file {
    padding: 1rem;
    max-width: 20%;
   .bi {
      font-size: 4rem;
    }
    .bi-filetype-pdf {
      color: red;
    }
    .bi-filetype-csv {
      color: green;
    }
    .bi-filetype-xlsx {
      color: #21F22A;
    }
    .bi-filetype-xls {
      color: #21F22A;
    }
    .bi-filetype-png {
      color: lightblue;
    }
    .bi-filetype-jpg {
      color: blue;
    }
    .bi-file-zip {
      color: purple;
    }
    .bi-file-earmark {
      color: gray;
    }
  }

  .file-name {
    overflow-wrap: break-word;
  }
</style>
