<template>
    <li>
      <template v-if="activity.activities && activity.activities.length > 0">
        <li><input v-model="activity.download" type="checkbox" @change="handleDownloadChange" :disabled="disabled">{{ activity.name }}
          <ul>
            <PDFActivityListItem v-for="a in activity.activities" :activity="a" :key="a.crmId" :disabled="!activity.download" :ref="'actRef' + a.crmId"></PDFActivityListItem>
          </ul>
        </li>
      </template>
      <template v-else><input v-model="activity.download" type="checkbox" @change="handleDownloadChange" :disabled="disabled"> {{ activity.name }}</template>
    </li>
</template>
<script>
export default {
  name: 'PDFActivityListItem',
  props: ['activity', 'disabled'],
  methods: {
    handleDownloadChange () {
      if (this.activity.activities && this.activity.activities.length > 0) {
        this.activity.activities.forEach(a => {
          a.download = false
          this.$refs['actRef' + a.crmId][0].handleDownloadChange()
          this.$refs['actRef' + a.crmId][0].$forceUpdate()
        })
      }
      this.$forceUpdate()
    }
  }
}
</script>
