<template>
  <AdminPageContainer title="Dashboard">
    <b-container fluid>
      <b-row class="mb-4">
        <b-col>
          <FiltersWidget @change="handleFilterChange" />
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col>
          <OverviewWidget
            :data="overview"
          />
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col>
          <Paper
            title="Registro diario"
            style="overflow: unset"
            shadow-less
            border-less>
              <DataTable
                :columns="columnsDailyRegistry"
                :items="dailyRegistriesData.data"
                shadow-less
                border-less></DataTable>
              <template #headerRight>
                <FormulateInput
                  name="dailyRegistryDate"
                  label="Fecha"
                  style="flex: 1;"
                  type="date"
                  v-model="dailyRegistriesData.date"
                  placeholder="Fecha" />
              </template>
            </Paper>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col>
          <BarChartWidget
            title="Horas realizadas por mes"
            :chart-data="hoursDataset"
            :options="hoursOptions"
            :stacked="true"
          >
            <template #headerRight>
              <b-button @click="openRegistryReport">Reporte</b-button>
            </template>
          </BarChartWidget>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col>
          <Paper
            title="Empleados"
            shadow-less
            border-less>
            <DataTable
              :columns="columns"
              :items="employees"
              shadow-less
              border-less>
              <template #cell(ratio)="row">
                <Ratio :value1="row.item.totalAssignedHours" :value2="row.item.totalFinishedHours" />
              </template>
            </DataTable>
          </Paper>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col>
          <BarChartWidget
            title="Horas Asignadas vs Realizadas de Empleados por Mes"
            :chart-data="employeesDataset"
            :options="employeeOptions"
            :stacked="true">
            <template #headerRight>
              <FormulateInput
                name="employees"
                label=""
                style="flex: 1"
                type="autocomplete"
                :options="employees"
                :multiple="true"
                :preserve-search="true"
                :close-on-select="false"
                :clear-on-select="false"
                v-model="selectedEmployees"
                track-by="id"
                placeholder="Selecciona empleados"
              />
            </template>
          </BarChartWidget>
        </b-col>
      </b-row>
    </b-container>
  </AdminPageContainer>
</template>

<script>
import { AdminPageContainer, createTextColumn, DataTable, Paper, Ratio } from '@/components'
import { OverviewWidget, FiltersWidget, BarChartWidget } from './components'
import { mapGetters } from 'vuex'
import moment from 'moment'
import {
  capitalizeFirstLetter,
  getFirstDayOfYear,
  getLastDayOfYear,
  activityIsParent,
  monthDiff,
  dateRange
} from '@/utils/functionsUtils'

function isMobile () {
  if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

const columns = !isMobile() ? [
  createTextColumn('name', 'Empleado'),
  createTextColumn('amountProjects', 'Cantidad de Proyectos'),
  createTextColumn('totalAssignedHours', 'Total de horas asignadas'),
  createTextColumn('totalFinishedHours', 'Total de horas realizadas'),
  createTextColumn('ratio', 'Ratio')
] : [
  createTextColumn('name', 'Empleado'),
  createTextColumn('amountProjects', 'Cant. Proy.'),
  createTextColumn('ratio', 'Ratio')
]

const columnsDailyRegistry = [
  createTextColumn('responsible', 'Responsable'),
  createTextColumn('hours', 'Horas'),
  createTextColumn('minutes', 'Minutos'),
  createTextColumn('working_hours', 'Jornada')
]

export default {
  name: 'Dashboard',
  components: { AdminPageContainer, OverviewWidget, FiltersWidget, BarChartWidget, DataTable, Paper, Ratio },
  watch: {
    selectedEmployees () {
      this.buildEmployeeDataset()
    },
    projects: {
      deep: true,
      handler () {
        this.projectList = this.projects
        this.calculateHoursChart()
        this.calculateDailyRegistries()
      }
    },
    'dailyRegistriesData.date': function () {
      this.calculateDailyRegistries()
    }
  },
  methods: {
    buildEmployeeDataset () {
      let employees = this.selectedEmployees

      if (employees?.length <= 0) {
        employees = this.employees
      }

      const labels = employees.map(e => `Enero 2020 - ${e.name}`)
      this.employeesDataset = {
        labels,
        datasets: [{
          label: 'Horas Presupuestadas',
          type: 'bar',
          stack: 'Base',
          backgroundColor: 'rgb(42,144,222)',
          data: [30, 31, 32]
        }, {
          label: 'Horas Realizadas',
          type: 'bar',
          stack: 'Base',
          backgroundColor: 'rgb(229,125,60)',
          data: [15, 16, 17]
        }]
      }
    },
    calculateSpentHours () {
      const projectsCopy = this.filterProjects(this.projectList)
      return projectsCopy.reduce((acc, project) => acc + project.spentHoursSum, 0)
    },
    calculateBudgetedHours () {
      const projectsCopy = this.filterProjects(this.projectList)
      return projectsCopy.reduce((acc, project) => acc + project.budgetedHoursSum, 0)
    },
    calculateHoursChart () {
      const projectsCopy = this.filterProjects(this.projectList)

      const startDate = new Date(this.filters.startDate && this.filters.endDate ? this.filters.startDate : getFirstDayOfYear())
      const endDate = new Date(this.filters.endDate && this.filters.endDate ? this.filters.endDate : getLastDayOfYear())

      const labels = dateRange(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')) // Array like ['Diciembre (2021)', 'Enero (2022)', 'Febrero (2022)'...]
      const monthNumbers = {}

      const spentDataset = Array(labels.length).fill(0)

      projectsCopy.forEach(project => {
        project.activities.forEach(activity => {
          activity.registries.forEach(registry => {
            const registryDateMoment = moment(registry.createdAt)
            const startDateMoment = moment(startDate)
            const endDateMoment = moment(endDate)
            if (registryDateMoment.isBetween(startDateMoment, endDateMoment, undefined, '[]')) {
              const registryDateFormatted = capitalizeFirstLetter(registryDateMoment.format('MMMM')) + ` (${registryDateMoment.format('YYYY')})`
              spentDataset[labels.indexOf(registryDateFormatted)] += Number(registry.hours) // We get the index from the value inserted in labels using the registry date with the same format as the label
            }
          })
        })
      })

      this.hoursDataset = {
        labels,
        datasets: [
          {
            label: 'Horas realizadas',
            data: spentDataset,
            backgroundColor: 'rgb(42,144,222)'
          }
        ]
      }
    },
    calculateEmployeesTable () {
      const projectsCopy = this.filterProjects(this.projectList)

      const employees = []

      projectsCopy.forEach(project => {
        if (project.responsible.length <= 0) return

        if (!employees.some(employee => employee.id === project.responsible[0].id)) {
          employees.push(this.getEmployeeForTable(project.responsible[0]))
        }
        project.activities.forEach(activity => {
          if (!activity.assignedTo || activityIsParent(activity.crmId, project.activities)) return

          if (!employees.some(employee => employee.id === activity.assignedTo.id)) {
            employees.push(this.getEmployeeForTable(activity.assignedTo))
          }

          activity.registries.forEach(registry => {
            if (!registry.responsible) return

            if (!employees.some(employee => employee.id === registry.responsible?.id)) {
              employees.push(this.getEmployeeForTable(registry.responsible))
            }

            employees.find(employee => employee.id === registry.responsible.id).totalFinishedHours += Number(registry.hours)
          })

          employees.find(employee => employee.id === activity.assignedTo.id).totalAssignedHours += Number(activity.expectedHours)
        })

        if (project.responsible && project.responsible.length > 0) {
          employees.find(employee => employee.id === project.responsible[0].id).amountProjects++
        }
      })
      return employees
    },
    handleFilterChange (filters) {
      this.filters = filters
      this.calculateHoursChart()
    },
    filterProjects (projects) {
      let projectsCopy = [...projects]

      if (this.filters.customer) {
        projectsCopy = this.filterProjectsByCustomer(projectsCopy, this.filters.customer.id)
      }

      if (this.filters.status) {
        projectsCopy = this.filterProjectsByStatus(projectsCopy, this.filters.status)
      }

      if (this.filters.responsible) {
        projectsCopy = this.filterProjectsByResponsible(projectsCopy, this.filters.responsible.id)
      }

      if (this.filters.startDate && this.filters.endDate) {
        projectsCopy = this.filterProjectsByDate(projectsCopy, this.filters.startDate, this.filters.endDate)
      }

      return projectsCopy
    },
    filterProjectsByCustomer (projects, customerId) {
      return projects.filter(project => project.customer.id === customerId)
    },
    filterProjectsByStatus (projects, status) {
      return projects.filter(project => project.status === status)
    },
    filterProjectsByResponsible (projects, responsibleId) {
      return projects.filter(project => {
        if (project.responsible.length > 0) {
          return project.responsible[0].id === responsibleId
        }
        return false
      })
    },
    filterProjectsByDate (projects, startDate, endDate) {
      const startMoment = moment(startDate)
      const endMoment = moment(endDate)
      return projects.filter(project => {
        const projectMoment = moment(project.createdAt)
        return projectMoment.isBetween(startMoment, endMoment, undefined, '[]')
      })
    },
    getEmployeeForTable (employee) {
      return {
        id: employee.id,
        name: employee.name,
        amountProjects: 0,
        totalAssignedHours: 0,
        totalFinishedHours: 0
      }
    },
    calculateDailyRegistries () {
      const data = {}
      const projects = this.projects

      projects.forEach(project => {
        project.activities.forEach(activity => {
          activity.registries.forEach(registry => {
            if (registry.createdAt === this.dailyRegistriesData.date) {
              if (registry.responsible?.id) {
                const responsibleId = registry.responsible.id

                if (!data[responsibleId]) {
                  data[responsibleId] = {
                    minutes: 0,
                    working_hours: registry.responsible.working_hours
                  }
                }

                data[responsibleId].responsible = registry.responsible.name
                data[responsibleId].minutes += registry.hours * 60
                data[responsibleId].minutes += registry.minutes
              }
            }
          })
        })
      })

      const dataArray = []

      Object.entries(data).forEach(d => {
        const obj = {}
        obj.responsible = d[1].responsible
        obj.hours = Math.floor(d[1].minutes / 60)
        obj.minutes = d[1].minutes % 60
        obj.working_hours = d[1].working_hours

        dataArray.push(obj)
      })

      this.dailyRegistriesData.data = dataArray
    },
    openRegistryReport () {
      window.open('index.php?entryPoint=informe_registro_horario', '_blank')
    }
  },
  data () {
    return {
      columns,
      columnsDailyRegistry,
      selectedEmployees: [],
      hoursDataset: {
        labels: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre'
        ],
        datasets: []
      },
      hoursOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      },
      employeesDataset: {
        labels: ['Enero 2022 - Ezequiel Herrera', 'Enero 2022 - Elias Herrera', 'Febrero 2022 - Juan Carlos'],
        datasets: [{
          label: 'Horas Presupuestadas',
          type: 'bar',
          stack: 'Base',
          backgroundColor: 'rgb(42,144,222)',
          data: [30, 31, 32]
        }, {
          label: 'Horas Realizadas',
          type: 'bar',
          stack: 'Base',
          backgroundColor: 'rgb(229,125,60)',
          data: [15, 16, 17]
        }]
      },
      employeeOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              maxRotation: 0,
              minRotation: 0
            }
          }],
          yAxes: [{
            stacked: true
          }]
        }
      },
      dailyRegistriesData: {
        date: new Date().toISOString().split('T')[0],
        data: []
      },
      projectList: [],
      filters: {}
    }
  },
  computed: {
    ...mapGetters({
      projects: 'getProjects'
    }),
    overview: function () {
      return {
        totalProjects: this.projectList.length,
        projectAmountByStatus: {
          notStarted: this.projectList.reduce((acc, project) => { return project.status === 'NotStarted' ? ++acc : acc }, 0),
          started: this.projectList.reduce((acc, project) => { return project.status === 'Started' ? ++acc : acc }, 0),
          finished: this.projectList.reduce((acc, project) => { return project.status === 'Finished' ? ++acc : acc }, 0),
          delayed: this.projectList.reduce((acc, project) => { return project.status === 'Delayed' ? ++acc : acc }, 0),
          discarded: this.projectList.reduce((acc, project) => { return project.status === 'Discarded' ? ++acc : acc }, 0)
        },
        totalFinishedHours: Number(this.calculateSpentHours().toFixed(2)),
        totalBudgetedHours: Number(this.calculateBudgetedHours().toFixed(2)),
        hoursByStatus: {
          notStarted: Number(this.projectList.reduce((acc, project) => { return project.status === 'NotStarted' ? acc + project.spentHoursSum : acc }, 0).toFixed(2)),
          started: Number(this.projectList.reduce((acc, project) => { return project.status === 'Started' ? acc + project.spentHoursSum : acc }, 0).toFixed(2)),
          finished: Number(this.projectList.reduce((acc, project) => { return project.status === 'Finished' ? acc + project.spentHoursSum : acc }, 0).toFixed(2)),
          delayed: Number(this.projectList.reduce((acc, project) => { return project.status === 'Delayed' ? acc + project.spentHoursSum : acc }, 0).toFixed(2)),
          discarded: Number(this.projectList.reduce((acc, project) => { return project.status === 'Discarded' ? acc + project.spentHoursSum : acc }, 0).toFixed(2))
        }
      }
    },
    employees: function () {
      return this.calculateEmployeesTable()
    }
  },
  mounted: function () {
    this.$store.dispatch('fetchProjects', [])
  }
}
</script>

<style scoped lang="scss">
.widgets-container {
  border: 1px solid black;
  max-width: 100%;

  & > div {
    height: 40rem;
    border: 1px solid red;
  }
}
</style>
