import { ActividadesModuleState } from './state'

const root = 'actividades'
export const GET_ACTIVIDADES = `${root}/GET_ACTIVIDADES`

export default {
  [GET_ACTIVIDADES] (state: ActividadesModuleState, actividades: any[]) {
    state.actividades = actividades
  }
}
