<template>
  <Widget :title="title">
    <div class="chart-container">
      <DoughnutChart
        :chart-data="processedChartData"
      />
    </div>
  </Widget>
</template>

<script>
import { Widget } from '../Widget'
import DoughnutChart from './DoughnutChart'
import { GraphColors } from '@/utils/constants'

export default {
  name: 'DoughnutChartWidget',
  props: ['title', 'chartData'],
  components: { Widget, DoughnutChart },
  data () {
    return {
      processedChartData: null
    }
  },
  mounted () {
    this.processedChartData = {
      labels: this.chartData.map(d => d.label),
      datasets: [
        {
          data: this.chartData.map(d => d.value),
          backgroundColor: GraphColors
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
