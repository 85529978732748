export interface ProjectsModuleState {
  projects: any[]
  project: any
}

export const state: ProjectsModuleState = {
  projects: [],
  project: null
}

export default state
