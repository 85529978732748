import { GET_ACTIVIDADES } from './mutations'
import axios from 'axios'
import qs from 'qs'
import { activityIsParent } from '@/utils/functionsUtils'

const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT

export const fetchActividades = ({ commit }: any, chargedActividades) => {
  let actividades

  if (chargedActividades.length === 0) {
    const data = qs.stringify({
      action: 'get_actividades'
    })

    const config: any = {
      method: 'post',
      url: entryPoint,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    }

    axios(config)
      .then(response => {
        actividades = response.data

        for (let i = 0; i < actividades.length; i++) {
          actividades[i].id = i + 1
        }

        commit(GET_ACTIVIDADES, actividades)
      })
  } else {
    actividades = chargedActividades.map(project => ({ ...project }))
    commit(GET_ACTIVIDADES, actividades)
  }
}

export const getActividadById = ({ commit }, params) => {
  const actividades: any[] = params.actividadesArray
  const actividad = actividades.find(p => p.id === Number(params.actividadId))

  commit(GET_ACTIVIDADES, actividades)
}

export default {
  fetchActividades,
  getActividadById
}
