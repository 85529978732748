

























import Vue from 'vue'
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'
import TreeViewItem from './TreeViewItem.vue'

export default Vue.extend({
  name: 'TreeView',
  mixins: [
    FormulateInputMixin
  ],
  components: { TreeViewItem },
  methods: {
    handleClickNode (node) {
      this.$emit('click-node', node)
    },
    handleClickAdd (parentNode) {
      this.$emit('click-add-node', parentNode)
    },
    handleClickSubNode (node) {
      this.$emit('click-sub-node', node)
    },
    handleClickAddSubNode (parentNode) {
      this.$emit('click-add-sub-node', parentNode)
    }
  }
})
