<template>
  <b-form-select
    :class="`status-tag ${ context.model && context.model ? context.model.toLowerCase() : 'undefined' }`"
    v-model="context.model"
    v-bind="attributes">
    <b-form-select-option
      v-for="option in this.states"
      :key="option.text"
      :value="option.value"
      :class="`status-tag ${ option.value ? option.value.toLowerCase() : 'undefined' }`">
      {{ option.value | statusLabel }}
    </b-form-select-option>
  </b-form-select>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'
import { ActivityState } from '@/utils/constants'

export default {
  name: 'BootstrapActivityStatusSelect',
  mixins: [
    FormulateInputMixin
  ],
  filters: {
    statusLabel (value) {
      switch (value) {
        case ActivityState.NOT_STARTED:
          return 'No iniciado'
        case ActivityState.STARTED:
          return 'Iniciado'
        case ActivityState.FINISHED:
          return 'Finalizado'
        case ActivityState.DELAYED:
          return 'Aplazado'
        case ActivityState.DISCARDED:
          return 'Descartado'
      }
    }
  },
  data () {
    return {
      states: [
        { text: 'No iniciado', value: ActivityState.NOT_STARTED },
        { text: 'Iniciado', value: ActivityState.STARTED },
        { text: 'Finalizado', value: ActivityState.FINISHED },
        { text: 'Aplazado', value: ActivityState.DELAYED },
        { text: 'Descartado', value: ActivityState.DISCARDED }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.status-tag {
  display: inline-block;
  font-size: .90rem;
  font-weight: bold;
  text-align: left;
  padding: .05rem .4rem;
  min-width: 1.4rem;
  border-radius: .25rem;
  color: #e5e5e5;

  &.notstarted {
    color: #5e5e5e;
  }

  &.started {
    color: #2e92f6;
  }

  &.finished {
    color: #2eb82e;
  }

  &.delayed {
    color: #ff852c;
  }

  &.discarded {
    color: #ff4343;
  }
}
</style>
