<template>
  <Widget :title="title">
    <div class="chart-container">
      <LineChart
        :chart-data="chartData"
      />
    </div>
  </Widget>
</template>

<script>
import { Widget } from '../Widget'
import LineChart from './LineChart'

export default {
  name: 'LineChartWidget',
  props: ['title', 'chartData'],
  components: { Widget, LineChart }
}
</script>

<style scoped lang="scss">
.chart-container {
  flex-grow: 1;
  min-height: 0;

  > div {
    position: relative;
    height: 100%;
  }
}
</style>
