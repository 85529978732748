















import { NavBar, SideBar, PageFooter } from '@/components'

export default {
  name: 'AdminPageTemplate',
  components: { PageFooter, NavBar, SideBar },
  data () {
    return {
      sidebarOpen: false
    }
  },
  methods: {
    toggleSidebar () {
      this.sidebarOpen = !this.sidebarOpen
    },

    closeSidebar () {
      this.sidebarOpen = false
    }
  }
}

