<template>
  <div class="card-root">
    <div class="card-container">
      <div class="card__value">
        {{ format ? format(value) : value }}
      </div>
      <div class="card__title">
        {{ label }}
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: ['label', 'value', 'status', 'format']
}
</script>

<style scoped lang="scss">
.card-root {
  padding: 0 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid #d0d0d0;
  text-align: center;

  .card__title {
    color: gray;
  }
  .card__value {
    font-size: 2rem;
    font-weight: bold;
  }

  .card__select {
    margin: 1rem;
    min-width: 10rem;
  }
}
@media (max-width: 960px) {
  .card-root {
    justify-content: center;
    padding: 2rem;
    border-right: none;
    border-bottom: 1px solid #d0d0d0;
  }
}
</style>
