import Vue from 'vue'
import Vuex from 'vuex'
import { projects, users, customers, calendar, actividades } from '@/store/modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    projects,
    users,
    customers,
    calendar,
    actividades
  }
})
