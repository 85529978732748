export interface TreeOptions {
  idProperty?: string
  labelProperty?: string
  parentIdProperty?: string
  childrenProperty?: string
}

export class Tree {
  private readonly _options: TreeOptions

  private _nodes: any[] = []

  constructor (options?: TreeOptions) {
    this._options = {
      idProperty: 'id',
      labelProperty: 'label',
      childrenProperty: 'children',
      parentIdProperty: 'parentId',
      ...options
    }
  }

  getNodeById (nodeId): any {
    return this._nodes.find(n => n.id === nodeId)
  }

  addNode (node, parentNodeId?): void {
    node.parentId = parentNodeId
    this._nodes.push(node)
  }

  editNode (nodeId, newNode, parentNodeId?): void {
    this.deleteNode(nodeId)
    this.addNode(newNode, parentNodeId)
  }

  deleteNode (nodeId): void {
    this._nodes = this._nodes.filter(n => n.id !== nodeId)
  }

  get nodes (): any[] {
    return this._nodes
  }
}
