<template>
  <div class="d-flex justify-content-center align-items-center" style="height: 70vh;">
    <div :class="width">
      <Paper title="Recuperación de contraseña">
        <FormulateForm name="login-user" @submit="enviarCorreo">
          <b-container>
            <b-row class="justify-content-center align-items-center">
              <b-col md="12" lg="8">
                <label for="email">Correo</label>
                <input name="email" id="email" class="form-control" type="email" placeholder="Correo" v-model="email" required>
              </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center">
              <b-col md="12" lg="3">
                <FormulateInput style="display: inline-block; margin-left: .5rem; margin-top: 1rem;" type="submit"
                  variant="primary" label="Enviar correo" />
              </b-col>
            </b-row>
          </b-container>
        </FormulateForm>
      </Paper>
      <div class="mt-2">
        <b-button @click="handleBackLogin"> Volver al login </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Paper } from '@/components'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'SelectDepartmentPage',
  components: { Paper },
  data () {
    return {
      email: ''
    }
  },
  methods: {
    enviarCorreo () {
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'recover_pass_send_mail')
      formData.append('email', this.email)
      axios
        .post(entryPoint, formData)
        .then((response) => {
          if (response.data.error) {
            Swal.fire({
              icon: 'warning',
              title: response.data.msg,
              showConfirmButton: false,
              timer: 1500
            })
          }
        })
    },
    handleBackLogin () {
      this.$router.push('/login')
    }
  },
  computed: {
    width () {
      return document.body.clientWidth < 1000 ? 'w-70' : 'w-50'
    }
  }
}
</script>
<style>
.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container h2 {
  margin-right: 40px;
  font-size: 30px;
}
</style>
