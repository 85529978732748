import { UsersModuleState } from './state'

const root = 'users'
export const GET_USERS = `${root}/GET_USERS`
export const GET_CURRENT_USER = `${root}/GET_CURRENT_USER`
export const GET_USER = `${root}/GET_USER`

export default {
  [GET_USERS] (state: UsersModuleState, users: any[]) {
    state.users = users
  },
  [GET_CURRENT_USER] (state: UsersModuleState, currentUser: any) {
    state.currentUser = currentUser
  },
  [GET_USER] (state: UsersModuleState, user: any) {
    state.user = user
  }
}
