import * as mutations from './mutations'
import { activityIsParent } from '@/utils/functionsUtils'

export const fetchCalendar = ({ commit }, filters: any) => {
  let activities: any[] = []
  const events: any[] = []

  if (filters.project) {
    activities = filters.project.activities.filter(activity => !activityIsParent(activity.id, activities))
  }

  if (filters.state) {
    activities = activities.filter(activity => activity.state === filters.state)
  }

  if (filters.responsible) {
    activities = activities.filter(activity => activity.assignedTo.id === filters.responsible.id)
  }

  activities.forEach(activity => {
    events.push({
      start: activity.startDate,
      end: activity.endDate,
      title: `${filters.project.name} (${activity.name})`
    })
  })

  commit(mutations.GET_CALENDAR, events)
}

export default {
  fetchCalendar
}
