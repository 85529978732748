<template>
  <div class="navbar__root fixed-top">
    <header class="header navbar navbar-expand-sm">
      <div v-show="!showImg" class="flex-container" :class="(showImg) ? '' : 'paddingleft'">
        <p v-show="!width">ActProject. Tu Misión deportiva</p>
        <p v-show="width">Software de voluntarios</p>
        <img src="./imgs/OIP.png" alt="" width="75" height="65">
        <p style="padding-left: 10px;">Desarrollado por</p>
        <img src="./imgs/activalink.png" alt="" width="80" height="50" style="margin-right: 2px;">
      </div>
      <ul class="navbar-item flex-row logo-container d-flex">
        <router-link to="/">
          <b-icon v-show="showImg" icon="house" font-scale="2"></b-icon>
        </router-link>
      </ul>

      <div v-show="showImg" @click="toggleSidebar" class="sidebarCollapse">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-list">
          <line x1="8" y1="6" x2="21" y2="6"></line>
          <line x1="8" y1="12" x2="21" y2="12"></line>
          <line x1="8" y1="18" x2="21" y2="18"></line>
          <line x1="3" y1="6" x2="3" y2="6"></line>
          <line x1="3" y1="12" x2="3" y2="12"></line>
          <line x1="3" y1="18" x2="3" y2="18"></line>
        </svg>
      </div>

      <ul class="offset-1 navbar-item flex-row search-ul d-flex justify-content-center pl-0">
        <img v-show="showImg" src="./imgs/OIP.png" alt="" width="110" height="90">
        <img v-show="showImg" src="./imgs/activalink.png" alt="" width="100" height="60">
      </ul>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      form: {},
      logged: false
    }
  },
  methods: {
    toggleSidebar () {
      this.$emit('toggleSidebar')
    }
  },
  computed: {
    showImg: function () {
      return this.$route.path !== '/login' && this.$route.path !== '/recover' && this.$route.path !== '/resetPass'
    },
    width () {
      return document.body.clientWidth < 1000
    }
  }
}
</script>

<style src="./_NavBar.scss" lang="scss" scoped />
