<template>
  <div>
    <template v-if="!funcion">
      <p>You don't have access to Missions module.</p>
    </template>
    <template v-else>
    <AdminPageContainer title="Misiones">
      <div class="d-none d-sm-block" style="display: flex; flex-direction: row; gap: 5px; margin-top: 1rem;">
        <b-button variant="outline-primary" @click="$router.push('/projects/activity/create')">
          Registrar horas
        </b-button>
        <b-button @click="$router.push('/projects/create')">
          Crear proyecto
        </b-button>
      </div>
      <br>
      <DataTable :columns="columns" :sort-desc="false" :items="proyectosConResponsable">
        <template #headerLeft>
          <div class="container-fluid">
            <FormulateForm id="formFinal" v-model="search" name="activity-form" class="row no-gutters" @submit="handleSearch">
              <FormulateInput v-model="name" name="name" placeholder="Buscar por nombre" ref="search_input" :class="getColumnClass" />
              <FormulateInput v-model="tag" name="etiquetas" type="autocomplete" :options="[
                {
                  name: 'Sin Etiqueta',
                  value: '',
                  id: ''
                },
                ...tags
              ]" :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
                placeholder="Selecciona una Etiqueta" :class="getColumnClass" />
              <FormulateInput v-model="assigned_user_id" name="assigned_user_id" type="autocomplete" :options="[
                {
                  name: 'Sin Responsable',
                  value: '',
                  id: ''
                },
                ...customers
              ]" :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
                placeholder="Selecciona un Responsable" :class="getColumnClass" />
              <FormulateInput v-model="customer" name="customer" type="autocomplete" :options="[
                {
                  name: 'Sin Invitados',
                  value: '',
                  id: ''
                },
                ...customers
              ]" :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
                placeholder="Selecciona un Invitado" :class="getColumnClass" />
                <FormulateInput v-model="typeMission" name="typeMission" type="autocomplete" :options="[
                {
                  name: 'Sin Modalidad',
                  value: '',
                  id: ''
                },
                ...missionOptions
              ]" :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
                placeholder="Selecciona una Modalidad" :class="getColumnClass" />
              <FormulateInput v-model="status_c" name="status" type="autocomplete"
                :options="statuses" :preserve-search="true" :close-on-select="true" :clear-on-select="false" track-by="id"
                placeholder="Seleccione un estado" :class="getColumnClass" />
              <FormulateInput
                style="display: inline-block;"
                type="submit"
                variant="primary"
                label="Buscar"
                :class="getColumnClass" />
            </FormulateForm>
          </div>
          <div class="col d-sm-block" style="display: flex; flex-direction: row; gap: 5px; margin-top: 1rem;" >
            <b-button class="d-none d-sm-block" style="display: flex; flex-direction: row; gap: 5px; margin-top: 1rem;" @click="clearFiltro()">
              Limpiar Filtro
            </b-button>
          </div>
        </template>
        <template #cell(name)="data">
          <a :href="'#/projects/' + data.item.id">
            <b class="">{{ data.item.name }}</b>
          </a>
        </template>
        <template #cell(category)="row">
          <b style="background-color:black;  border-radius: 1rem; width: 100%; padding: 0.07rem 0.4rem; color: white;"> {{ row.item.category }} </b>
        </template>
        <template #cell(status)="row">
          <ActivityStatusTag :status="row.item.status" />
        </template>
        <template #cell(actions)="row">
          <div>
            <div v-show="funcion !== 'voluntario' || (funcion == 'coordinador' && row.item.type !== 'interno')" class="buttons-groups">
              <b-button size="sm" class="mr-1" @click="$router.push(`projects/${row.item.id}`)">
                Ver
              </b-button>
              <b-button size="sm" class="mr-1"
                @click="$router.push(`projects/${row.item.id}/update`)">
                Editar
              </b-button>
              <b-button size="sm" class="mr-1" variant="danger"
                @click="() => handleTableDelete(row)">
                Borrar
              </b-button>
            </div>
            <div v-show="funcion !== 'voluntario' || (funcion == 'coordinador' && row.item.type !== 'interno')" class="actions-dropdown">
              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <b-icon icon="three-dots-vertical" variant="dark"></b-icon>
                </template>
                <b-dropdown-item @click.prevent="$router.push(`projects/${row.item.id}`)">
                  Ver
                </b-dropdown-item>
                <b-dropdown-item @click.prevent="$router.push(`projects/${row.item.id}/update`)">
                  Editar
                </b-dropdown-item>
                <b-dropdown-item @click.prevent="() => handleTableDelete(row)">
                  Borrar
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </template>
      </DataTable>
      <div class="loader-container"  v-if="loader">
        <vue-loaders name="ball-clip-rotate-pulse" color="black" scale="1"></vue-loaders>
      </div>
      <DeleteDialog id="project-delete-dialog" title="Eliminar proyecto"
        :message="`Está por eliminar el proyecto ${selectedProject ? selectedProject.name : ''}, ¿Desea continuar?`"
        :show="!!selectedProject" :element="selectedProject" @cancel="handleCancelDelete" @delete="handleDelete" />
    </AdminPageContainer>
  </template>
  </div>
</template>

<style scoped lang="scss">
.buttons-groups {
  display: inline-block;
}

.actions-dropdown {
  display: none;
}

@media (max-width: 1440px) {
  .buttons-groups {
    display: none;
  }

  .actions-dropdown {
    display: inline-block;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'
import {
  AdminPageContainer,
  DataTable,
  createTextColumn,
  DeleteDialog,
  ActivityStatusTag
} from '@/components'
import moment from 'moment'
import axios from 'axios'

function isMobile () {
  if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

const columns = !isMobile() ? [
  createTextColumn('numeracion', 'Nº', null, true),
  createTextColumn('category', 'Categoria', null, true),
  createTextColumn(
    'typeMission',
    'Modalidad',
    (c) => `${(c && c.length > 0) ? `${c[0].name}${c.length > 1 ? ` y ${c.length - 1} más` : ''}` : 'Sin Modalidad'}`,
    true
  ),
  createTextColumn('name', 'Nombre', null, true),
  createTextColumn('createdAt',
    'Fecha de Inicio',
    (x) => moment(x).format('DD/MM/YYYY'),
    true
  ),
  createTextColumn('status', 'Estado', null, true),
  createTextColumn(
    'responsible',
    'Responsable',
    (x) => {
      return x?.name || 'Sin responsable'
    }
  ),
  createTextColumn(
    'customer',
    'Invitados',
    (c) => `${(c && c.length > 0) ? `${c[0].name}${c.length > 1 ? ` y ${c.length - 1} más` : ''}` : 'Sin invitados'}`,
    true
  ),
  createTextColumn('actions', 'Acciones')
] : [
  createTextColumn('id', 'ID', null, true),
  createTextColumn('name', 'Nombre', null, true),
  createTextColumn('status', 'Estado', null, true),
  createTextColumn('actions', 'Acciones')
]

export default {
  name: 'ProjectsPage',
  components: { AdminPageContainer, DataTable, DeleteDialog, ActivityStatusTag },
  data () {
    return {
      isAdmin: localStorage.getItem('is_admin'),
      funcion: localStorage.getItem('funcion'),
      showDeleteDialog: false,
      selectedProject: null,
      projectList: [],
      projectListSinFiltrar: [],
      search: '',
      columns,
      status_c: '',
      customer: '',
      assigned_user_id: '',
      tags: [
      ],
      tag: '',
      typeMission: '',
      statuses:
        [
          {
            id: 1,
            name: 'No iniciado',
            value: 'NotStarted'
          },
          {
            id: 2,
            name: 'Iniciado',
            value: 'Started'
          },
          {
            id: 3,
            name: 'Finalizado',
            value: 'Finished'
          },
          {
            id: 4,
            name: 'Aplazado',
            value: 'Delayed'
          },
          {
            id: 5,
            name: 'Descartado',
            value: 'Discarded'
          }
        ],
      missionOptions: [
        {
          name: '164k',
          id: '164k'
        },
        {
          name: '42k',
          id: '42k'
        },
        {
          name: '24k',
          id: '24k'
        },
        {
          name: '15k',
          id: '15k'
        },
        {
          name: 'Vertical',
          id: 'Vertical'
        }
      ],
      selectedStatus: null,
      loader: false
    }
  },
  computed: {
    ...mapGetters({
      projects: 'getProjects',
      customers: 'getCustomers'
    }),
    getColumnClass () {
      const mediaQuery = window.matchMedia('(min-width: 600px)')
      if (mediaQuery.matches) {
        return 'col-4'
      } else {
        return 'col-12'
      }
    },
    proyectosConResponsable () {
      const projectsArray2 = this.projectList
      let userProjects = []
      if (this.isAdmin === '0') {
        console.log('netro')
        if (!Array.isArray(projectsArray2)) {
          console.log('projectsArray.projects is either empty, undefined, or not an array.')
        } else if (this.funcion === 'director') {
          console.log('netro1')
          userProjects = this.projectList
        } else if (localStorage.getItem('funcion') === 'coordinador') {
          console.log('netro2')
          projectsArray2.forEach(proyecto => {
            const projectIsInterno = proyecto.type === 'interno'
            if (projectIsInterno) {
              console.log('netro3')

              if (proyecto.customer && proyecto.customer.length > 0) {
                const currentUserIsInProject = proyecto.customer.some((invitado) => invitado.id === localStorage.getItem('id'))
                if (currentUserIsInProject) {
                  userProjects.push(proyecto)
                }
                if (localStorage.getItem('id') === proyecto.responsible?.id && !currentUserIsInProject) {
                  userProjects.push(proyecto)
                }
              }
            } else {
              userProjects.push(proyecto)
            }
          })
        } else {
          projectsArray2.forEach(proyecto => {
            if (proyecto.customer && proyecto.customer.length > 0) {
              const currentUserIsInProject = proyecto.customer.some((invitado) => invitado.id === localStorage.getItem('id'))
              if (currentUserIsInProject) {
                userProjects.push(proyecto)
              }
              if (localStorage.getItem('id') === proyecto.responsible?.id && !currentUserIsInProject) {
                userProjects.push(proyecto)
              }
            }
          })
        }
      } else {
        console.log('netrodirector')

        userProjects = this.projectList
      }
      userProjects.forEach(project => {
        if (project.etiquetas && project.etiquetas.length > 0) {
          project.etiquetas.forEach(tag => {
            const tagExists = this.tags.some(existingTag => existingTag.id === tag.text)
            if (!tagExists) this.tags.push({ id: tag.text, name: tag.text })
          })
        }
      })
      return userProjects
    }
  },
  created () {
    const projects = this.$store.getters.getProjects
    this.$store.dispatch('fetchProjects', projects)
    this.$store.dispatch('fetchCustomers')
    this.$store.dispatch('getCurrentUsers')
  },
  mounted () {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'F3' || (e.ctrlKey && e.key === 'f')) {
        const input = this.$refs.search_input.$el.firstElementChild.firstElementChild
        if (document.activeElement === input) {
          return true
        } else {
          e.preventDefault()
          input.focus()
        }
      }
    })
  },
  watch: {
    projects: {
      deep: true,
      handler () {
        this.projectList = this.projects
        this.projectListSinFiltrar = this.projects
      }
    }
  },
  methods: {
    toggleDeleteDialog (toggle) {
      if (toggle) {
        this.$bvModal.show('project-delete-dialog')
      } else {
        this.$bvModal.hide('project-delete-dialog')
      }
    },
    handleTableDelete (row) {
      this.selectedProject = row.item
      this.toggleDeleteDialog(true)
    },
    handleCancelDelete () {
      this.toggleDeleteDialog(false)
    },
    handleDelete (obj) {
      const projectsArray = this.$store.getters.getProjects
      this.$store.dispatch('deleteProject', { projectId: obj.id, projectsArray, crmId: obj.crmId })
      this.toggleDeleteDialog(false)
    },
    handleSearch () {
      this.loader = true
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const formData = new FormData()
      formData.append('action', 'searchProject')
      formData.append('searchData', JSON.stringify(this.search))

      axios.post(entryPoint, formData)
        .then((response) => {
          console.log(response.data)

          const projects = response.data.map((projectFromResponse) => {
            const matchingProject = this.projectListSinFiltrar.find(
              project => project.name === projectFromResponse.name
            )

            if (matchingProject) {
              return {
                ...projectFromResponse,
                id: matchingProject.id
              }
            } else {
              return projectFromResponse
            }
          })

          console.log(projects)
          this.projectList = projects
        })
        .finally(() => {
          this.loader = false
          this.showButton = true
        })
    },
    clearFiltro () {
      this.projectList = this.projectListSinFiltrar
      this.search = ''
      this.name = ''
      this.status_c = ''
      this.customer = ''
      this.assigned_user_id = ''
      this.typeMission = ''
      this.tag = ''
    }
  }
}
</script>

<style scoped lang="scss">
.widgets-container {
  border: 1px solid black;
  max-width: 100%;

  &>div {
    height: 40rem;
    border: 1px solid red;
  }
}

.buttons-groups {
  display: inline-block;
}

@media (max-width: 500px) {
  #formFinal {
    flex-direction: column !important;
  }
}

@media (min-width: 501px) {
  #formFinal {
    flex-direction: row !important;
  }
}

.actions-dropdown {
  display: none;
}

@media (max-width: 1440px) {
  .buttons-groups {
    display: inline-block;
  }

  .actions-dropdown {
    display: inline-block;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loader {
  width: 50px;
  height: 50px;
}
</style>
