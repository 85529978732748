<template>
  <div :class="`calendar-group_root ${active ? 'active' : ''}`">
    <div class="calendar-group_header" v-if="!hideHeader">
      <div class="calendar-group_header-separator" />
      <div class="calendar-group_header-info">
        <p class="calendar-group_day-name">{{ date.format('dddd') }}</p>
        <span class="calendar-group_day-number">{{ date.format('DD') }}</span>
      </div>
    </div>
    <div class="calendar-group_content">
      <div
        class="calendar-group_activity"
        v-for="activity in activities"
        v-bind:key="activity.crmId">
        {{ activity.responsible.name }} <br> {{ activity.spentHours }} h.
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CalendarGroup',
  props: ['date', 'activities', 'hideHeader'],
  data () {
    return {
      active: moment().startOf('day').isSame(this.date.startOf('day'))
    }
  }
}
</script>

<style scoped lang="scss">
.calendar-group_root {
  min-width: 10rem;
  width: 100%;
  height: 100%;
  user-select: none;

  &.active {
    .calendar-group_day-name {
      color: var(--secondary);
    }
    .calendar-group_day-number {
      color: white;
      background-color: var(--secondary);
    }
    .calendar-group_header {
      border-color: var(--secondary);
    }
  }
}
.calendar-group_header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-bottom: 1px solid #dcdcdc;
  text-transform: capitalize;

  .calendar-group_header-separator {
    height: 1rem;
    border-left: 1px solid #dcdcdc;
  }

  .calendar-group_header-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .5rem 1rem;
    font-size: 1.1rem;
  }
}
.calendar-group_day-name {
  margin: 0;
}
.calendar-group_day-number {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 1.3rem;
}
.calendar-group_content {
  height: 100%;
  padding: .5rem;
  border-left: 1px solid #dcdcdc;
}
.calendar-group_activity {
  color: white;
  margin-bottom: .5rem;
  padding: .5rem .75rem;
  font-weight: bold;
  font-size: 1rem;
  border-radius: .25rem;
  cursor: pointer;
  background-color: var(--secondary);
}
</style>
