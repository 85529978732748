import { ADD_PROJECT, GET_PROJECT, GET_PROJECTS, DELETE_PROJECT, UPDATE_PROJECT } from './mutations'
import axios from 'axios'
import qs from 'qs'
import { activityIsParent } from '@/utils/functionsUtils'

const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
const openPDFEntryPoint = process.env.VUE_APP_OPEN_PDF_ENTRYPOINT

export const fetchProjects = ({ commit }: any, chargedProjects) => {
  let projects

  if (chargedProjects.length === 0) {
    const data = qs.stringify({
      action: 'get_projects'
    })

    const config: any = {
      method: 'post',
      url: entryPoint,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    }

    axios(config)
      .then(response => {
        projects = response.data

        for (let i = 0; i < projects.length; i++) {
          projects[i].id = i + 1
        }

        projects.forEach(project => {
          project.budgetedHoursSum = project.activities.reduce((acc, { budgetedHours, crmId }) => {
            if (!activityIsParent(crmId, project.activities)) {
              return acc + Number(budgetedHours)
            }
            return acc
          }, 0)

          project.spentHoursSum = project.activities.reduce((acc, { hoursSpent, crmId }) => {
            if (!activityIsParent(crmId, project.activities)) {
              return acc + Number(hoursSpent)
            }
            return acc
          }, 0)
        })

        commit(GET_PROJECTS, projects)
      })
  } else {
    projects = chargedProjects.map(project => ({ ...project }))
    commit(GET_PROJECTS, projects)
  }
}

export const getProjectById = ({ commit }, params) => {
  const projects: any[] = params.projectsArray
  const project = projects.find(p => p.id === Number(params.projectId))

  commit(GET_PROJECT, project)
}

export const createProject = ({ commit }, { project, projectsArray, router, tags, currentUser }) => {
  project.id = projectsArray.length + 1
  const data = qs.stringify({
    action: 'create_project',
    project: JSON.stringify(project),
    etiquetas: JSON.stringify(tags),
    currentUser: JSON.stringify(currentUser)
  })

  const config: any = {
    method: 'post',
    url: entryPoint,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  }

  axios(config)
    .then(response => {
      project = response.data
      projectsArray.push(project)
      commit(ADD_PROJECT, projectsArray)
      router.push('/projects')
    })
}

export const updateProject = ({ commit }, { project, projectsArray, deleteActivitiesIds, router, route, cb }) => {
  const data = qs.stringify({
    action: 'update_project',
    project: JSON.stringify(project),
    delete_activities_ids: JSON.stringify(deleteActivitiesIds)
  })

  const config: any = {
    method: 'post',
    url: entryPoint,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  }

  axios(config)
    .then(response => {
      const index = projectsArray.indexOf(projectsArray.find(p => p.id === project.id))
      if (response.data.project) {
        projectsArray[index] = response.data.project
      } else {
        if (response.data.updateParent) {
          project.activities.forEach(activity => {
            if (activity.id === response.data.parentId) {
              activity.state = response.data.newParentState
            }
          })
        }
        projectsArray[index] = project
      }
      delete project.newRegistrie

      commit(UPDATE_PROJECT, projectsArray)

      if (router) {
        if (route.query.projectId) {
          router.push('/projects/' + project.id)
        } else {
          router.push('/projects')
        }
      }
      if (cb) {
        cb()
      }
    })
}

export const deleteProject = ({ commit }, { projectId, projectsArray, crmId }) => {
  const projects: any[] = projectsArray.filter(p => p.id !== Number(projectId))

  const data = qs.stringify({
    action: 'delete_project',
    project_id: crmId
  })

  const config: any = {
    method: 'post',
    url: entryPoint,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  }

  axios(config)
    .then(() => {
      commit(DELETE_PROJECT, projects)
    })
}

export const downloadPDF = ({ commit }, { project, showModalCb }) => {
  const data = qs.stringify({
    action: 'download_project_pdf',
    project: JSON.stringify(project)
  })

  const config: any = {
    method: 'post',
    url: entryPoint,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  }

  axios(config)
    .then(response => {
      const path = openPDFEntryPoint + '&pdf_path=' + response.data
      window.open(path, '_blank')
      showModalCb(false)
    })
}

export const relateDocuments = ({ commit }, { files, uploadFilesModule, moduleId, moduleItem, showModalCb, projectsArray, activity }) => {
  const formData = new FormData()
  files.forEach((f, i) => {
    formData.append('document' + i, f)
  })
  formData.append('action', 'relate_document')
  formData.append('module', uploadFilesModule)
  formData.append('id', moduleId)
  formData.append('activity', JSON.stringify(activity))
  axios.post(entryPoint, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(response => {
    moduleItem.documents.push(...response.data)
    commit(UPDATE_PROJECT, projectsArray)
    showModalCb(false)
  })
}

export const unRelateDocument = ({ commit }, { id, projects, project }) => {
  const formData = new FormData()
  formData.append('action', 'delete_files')
  formData.append('id', id)
  axios
    .post(entryPoint, formData)
    .then(response => {
      const pIndex = projects.findIndex(p => p.id === project.id)
      projects[pIndex].documents = projects[pIndex].documents.filter((doc) => doc.document_id !== id)
      commit(UPDATE_PROJECT, projects)
    })
}
export default {
  fetchProjects,
  getProjectById,
  createProject,
  updateProject,
  deleteProject,
  downloadPDF,
  relateDocuments,
  unRelateDocument
}
