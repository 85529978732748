<template>
  <div class="data-table__root">
    <Paper
      :shadow-less="shadowLess"
      :border-less="borderLess">
      <div
        class="data-table__actions">
        <div class="header-left">
          <slot name="headerLeft">
            <h5 style="overflow-wrap: anywhere;">{{ title }}</h5>
          </slot>
        </div>
        <div class="header-right">
          <slot name="headerRight" />
        </div>
      </div>
      <div class="data-table__table-container" v-if="(items && items.length > 0)">
        <b-table
          :fields="columns"
          :items="items"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :per-page="perPage"
          :current-page="currentPage"
          thead-class="data-table__table-head"
          thead-tr-class="data-table__table-tr-head"
          tbody-class="data-table__table-body"
          tbody-tr-class="data-table__table-tr-body"
          tfoot-class="data-table__table-foot"
          tfoot-tr-class="data-table__table-tr-foot"
          foot-clone
          responsive
          hover>
          <template
            v-for="(_, name) of $scopedSlots"
            v-slot:[name]="scope">
            <slot :name="name" v-bind="scope" />
          </template>
        </b-table>
      </div>
      <div v-if="!(items && items.length > 0)" style="margin: 2rem 0 1rem; text-align: center">
        {{ noItemsLabel }}
      </div>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </Paper>
  </div>
</template>

<script>
import Vue from 'vue'
import { Paper } from '@/components'

export default Vue.extend({
  name: 'Datatable',
  components: { Paper },
  props: {
    title: String,
    columns: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    borderLess: {
      type: Boolean,
      default: () => false
    },
    shadowLess: {
      type: Boolean,
      default: () => false
    },
    sortBy: {
      type: String,
      default: () => 'createdAt'
    },
    sortDesc: {
      type: Boolean,
      default: () => true
    },
    noItemsLabel: String
  },
  data () {
    return {
      currentPage: 1,
      perPage: 25
    }
  },
  methods: {
    handleDelete (row) {
      const projectsArray = this.$store.getters.getProjects
      this.$store.dispatch('deleteProject', { projectId: row.item.id, projectsArray, crmId: row.item.crmId })
    }
  },
  computed: {
    rows () {
      return this.items.length
    }
  }
})
</script>

<style src="./_DataTable.scss" lang="scss" scoped />
