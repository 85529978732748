import { UsersModuleState } from './state'

export const getUsers = (state: UsersModuleState): any[] => {
  return state.users
}
export const getcurrentUser = (state: UsersModuleState): any => {
  return state.currentUser
}
export const getUser = (state: UsersModuleState): any => {
  return state.user
}

export default {
  getcurrentUser,
  getUsers,
  getUser
}
