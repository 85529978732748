<template>
  <vue-multiselect
    label="name"
    v-model="context.model"
    v-bind="attributes"
    :options="context.options">
  </vue-multiselect>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'

export default {
  name: 'ALMultiSelect',
  mixins: [
    FormulateInputMixin
  ]
}
</script>
