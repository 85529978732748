import { ActividadesModuleState } from './state'

export const getActividades = (state: ActividadesModuleState): any[] => {
  return state.actividades
}

export const getActividad = (state: ActividadesModuleState): any => {
  return state.actividad
}

export default {
  getActividades,
  getActividad
}
