<template>
  <AdminPageContainer title="Ver Proyecto">
    <b-container fluid>
      <b-row align-v="stretch">
        <b-col sm="12" lg="6" style="margin-bottom: 2rem">
          <Paper title="Información">
              <template #headerRight>
                <div class="buttons-groups">
                  <b-button
                    v-show="false"
                    class="mr-2"
                    @click="handleClickDownloadPDF">
                      Descargar PDF
                  </b-button>
                  <b-button
                    class="mr-2"
                    @click="handleClickUploadLink('act_Gestion_Proyectos', getProject)">
                    <b-icon-upload></b-icon-upload> Añadir Enlace
                  </b-button>
                  <b-button
                    class="mr-2"
                    @click="handleClickUploadFile('act_Gestion_Proyectos', getProject)">
                    <b-icon-upload></b-icon-upload> Subir archivos
                  </b-button>
                  <b-button
                  v-show="funcion !== 'voluntario' || (funcion == 'coordinador' && row.item.type !== 'interno')"
                    @click="handleEditProject">
                    Editar proyecto
                  </b-button>
                </div>
                <div class="actions-dropdown" v-show="isAdmin === '1'">
                  <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <b-icon icon="three-dots-vertical" variant="dark"></b-icon>
                    </template>
                    <b-dropdown-item  @click="handleClickDownloadPDF" v-show="false">
                      Descargar PDF
                    </b-dropdown-item>
                    <b-dropdown-item @click="handleClickUploadFile('act_Gestion_Proyectos', getProject)">
                      Añadir Enlace
                    </b-dropdown-item>
                    <b-dropdown-item @click="handleClickUploadFile('act_Gestion_Proyectos', getProject)">
                      Subir archivos
                    </b-dropdown-item>
                    <b-dropdown-item v-show="funcion !== 'voluntario' || (funcion == 'coordinador' && row.item.type !== 'interno')" @click="handleEditProject">
                      Editar
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            <div class="project-info">
              <p>Numeración: <span>{{ getProject.numeracion }}</span></p>
              <p>Nombre del proyecto: <span>{{ getProject.name }}</span></p>
              <p>Fecha de creación: <span>{{ getProject.createdAt | moment }} por {{ getProject.createdBy }}</span></p>
              <p>Tipo de proyecto: <span>{{ getProject.type }}</span></p>
              <p>Estado: <ActivityStatusTag :status="getProject.status" /></p>
              <p>Responsable: <span>{{ getProject.responsible.name }}</span></p>
              <p>Invitados: <span>{{ getCustomers }}</span></p>
              <p>Categoria:  <vue-tags-input v-model="tag" :tags="getProject.etiquetas" @tags-changed="newTags => form.etiquetas = newTags" :disabled="true" :placeholder="''" /></p>
              <div></div>
              <p>Creado por: {{ getProject.createdBy }}</p>
            </div>
            <hr />
            <div class="activities">
              <p>Actividades: </p>
              <span>Haga click en una actividad para ver los registros de hora</span>
              <ActivityTree
                :nodes="getProject.activities"
                v-on:click-node="viewActivityRegistry"
              />
            </div>
            <hr />
            <div class="documents">
              <p>Documentos</p>
              <span v-if=" Array.isArray(getProject.documents) && getProject.documents.length === 0">No hay documentos adjuntos.</span>
              <ol>
                <li v-for="document in getProject.documents" :key="document.document_id" class="row">
                  <div class="col-md-11 col-7 mt-1 doc_title">
                    <b><a :href="'https://utmacadmin.activalink.com/public/' + document.revision_id + '.' + document.file_ext" target="_blank">{{   document.name }}</a></b>
                  </div>
                  <div class="col-md-1 col mt-1">
                  <button class="btn btn-danger smaller-center align-center" @click="deleteDocument(document.document_id)">X</button>
                  </div>
                </li>
              </ol>
            </div>
            <hr />
            <div class="enlaces">
              <p>Enlaces</p>
              <span v-if=" Array.isArray(getProject.enlaces) && getProject.enlaces.length === 0">No hay enlaces adjuntos.</span>
              <ol>
                <li v-for="enlace in getProject.enlaces" :key="enlace.link_id" class="row">
                  <a :href="enlace.url" target="_blank">{{ enlace.nombre }}</a>
                  <div class="col-md-1 col mt-1">
                    <button class="btn smaller-center" style="margin-top: -13px;" @click="deleteLink(enlace.link_id)">X</button>
                  </div>
                </li>
              </ol>
            </div>
            <b-button
              @click="handleBack">
              Volver
            </b-button>
          </Paper>
        </b-col>
        <b-col sm="12" lg="6" style="margin-bottom: 2rem;">
          <Paper title="Actividades">
            <b-row>
              <b-col sm="12" lg="12" style="margin-bottom: 2rem;">
                <Paper v-if="selectedActivity != null">
                  <p>Numeración: <span>{{ selectedActivity.numeracion }}</span></p>
                  <p>Actividad: <span>{{ selectedActivity.name }}</span></p>
                  <p>Asignado a: <span>{{ selectedActivity.assignedTo.name }}</span></p>
                  <p>Invitados:</p>
                    <ul>
                      <li v-for="guest in selectedActivity.invited" :key="guest.id">
                        <span>{{ guest.name }}</span>
                      </li>
                    </ul>
                  <p>Fecha Estimada Inicio: <span>{{ selectedActivity.startDate }}</span></p>
                  <p>Fecha Estimada Fin: <span>{{ selectedActivity.endDate }}</span></p>
                  <p>Horas Presupuestadas: <span>{{ selectedActivity.budgetedHours }}</span></p>
                  <p>Horas previstas: <span>{{ selectedActivity.expectedHours }}</span></p>
                  <p>Descripcion: <span>{{ selectedActivity.description }}</span></p>
                </Paper>
              </b-col>
            </b-row>
            <DataTable
              :title="selectedActivity && selectedActivity.name || ''"
              :columns="columns"
              :items="cloneArray(selectedActivityRegistries)"
              v-if="selectedActivity != null"
              no-items-label="No hay horas registradas en esta actividad">
              <template #headerRight>
                <b-button @click="createActivityRegistry" v-if="selectedActivity.state != 'Finished'">
                  Crear registro de hora
                </b-button>
              </template>
              <template #cell(description)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                  {{ row.detailsShowing ? 'Ocultar' : 'Mostrar'}}
                </b-button>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col>{{ row.item.description }}</b-col>
                  </b-row>
                </b-card>
              </template>
            </DataTable>
            <span v-if="selectedActivity == null">Seleccione una actividad de nivel hoja para registrar y ver las horas asignadas.</span>
          </Paper>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 400px;">
            <gmap-marker :position="markerPosition"/>
          </gmap-map>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-4">
          <DataTable
            title="Registro de horas totales"
            :columns="columns2"
            :items="cloneArray(registeredHours)">
            <template #cell(refcrm)="row">
              <a target="_blank" :href="`${crmUrl}action=ajaxui#ajaxUILoc=index.php%3Fmodule%3Dact_registros_actividades%26action%3DDetailView%26record%3D${row.item.crmId}`">
                <b-button
                  size="sm">
                  Abrir
                </b-button>
              </a>
            </template>
            <template #cell(actions)="row">
              <b-button
                size="sm"
                class="mr-1"
                @click="$router.push(`projects/${row.item.id}`)">
                Ver
              </b-button>
              <b-button
                size="sm"
                class="mr-1"
                @click="$router.push(`projects/${row.item.id}/update`)">
                Editar
              </b-button>
              <b-button
                size="sm"
                class="mr-1"
                variant="danger"
                @click="() => handleTableDelete(row)">
              </b-button>
            </template>
            <template #headerRight>
              <download-excel
                class="btn btn-default"
                type="xls"
                :data="registeredHours"
                :fields="downloadExcelFields"
                :worksheet="getProject.name"
                :name="'Registro de horas ' + getProject.name + '.xls'">
                <b-button
                  v-show="isAdmin === '1'"
                  style="margin-right: 1rem"
                  variant="outline-primary"
                >
                  Descargar Excel
                  <b-icon-download class="ml-1"></b-icon-download>
                </b-button>
              </download-excel>
            </template>
            <template #cell(description2)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                  {{ row.detailsShowing ? 'Ocultar' : 'Mostrar'}}
                </b-button>
              </template>
              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col>{{ row.item.description }}</b-col>
                  </b-row>
                </b-card>
              </template>
          </DataTable>
        </b-col>
      </b-row>
    </b-container>
    <div class="loader-container"  v-if="loader">
      <vue-loaders name="ball-clip-rotate-pulse" color="black" scale="1"></vue-loaders>
    </div>
    <UploadFileDialog
      @cancel="handleClickCancelUploadFile"
      @submit="handleSubmitUploadFile">
    </UploadFileDialog>
    <DownloadPDFDialog
      :project="getProject"
      @cancel="handleClickCancelDownloadPDF"
      @submit="handleSubmitDownloadPDF"></DownloadPDFDialog>
  </AdminPageContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  AdminPageContainer,
  createTextColumn,
  DataTable,
  Paper,
  ActivityTree,
  ActivityStatusTag
} from '@/components'
import moment from 'moment'
import { activityIsParent } from '@/utils/functionsUtils'
import { DownloadPDFDialog, UploadFileDialog } from './components'
import Swal from 'sweetalert2'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'

const columns = [
  createTextColumn('responsible', 'Responsable', (x) => x?.name || 'Sin responsable', true),
  createTextColumn('createdAt',
    'Fecha de Inicio',
    (x) => moment(x).format('DD/MM/YYYY'),
    true),
  createTextColumn('hours', 'Horas', null, true),
  createTextColumn('minutes', 'Minutos'),
  createTextColumn('description', 'Comentario')
]

const columns2 = [
  createTextColumn('responsible', 'Responsable', (x) => x?.name || 'Sin responsable', true),
  createTextColumn('createdAt',
    'Fecha de Inicio',
    (x) => moment(x).format('DD/MM/YYYY'),
    true),
  createTextColumn('hours', 'Horas', null, true),
  createTextColumn('minutes', 'Minutos'),
  createTextColumn('refcrm', 'Ir al CRM'),
  createTextColumn('description2', 'Comentario')
]

export default {
  name: 'ProjectsInfoPage',
  components: { AdminPageContainer, Paper, DataTable, ActivityTree, ActivityStatusTag, DownloadPDFDialog, UploadFileDialog },
  data () {
    return {
      loader: false,
      center: { lat: 0, lng: 0 },
      zoom: 10,
      markerPosition: { lat: 0, lng: 0 },
      tag: '',
      columns,
      columns2,
      funcion: localStorage.getItem('funcion'),
      selectedActivity: null,
      activities: [],
      selectedActivityRegistries: [],
      registeredHours: [],
      isAdmin: localStorage.getItem('is_admin'),
      downloadExcelFields: {
        Actividad: 'activityName',
        Responsable: 'responsible.name',
        'Fecha de registro': 'createdAt',
        Semana: {
          field: 'createdAt',
          callback: (value) => {
            const currentDate = new Date(value)
            const oneJan = new Date(currentDate.getFullYear(), 0, 1)
            const numberOfDays = Math.floor((currentDate - oneJan) / (24 * 60 * 60 * 1000))
            const result = Math.ceil((currentDate.getDay() + 1 + numberOfDays) / 7)
            return `Semana ${result}`
          }
        },
        Descripcion: 'description',
        Name: 'name',
        'Horas registradas': 'hours'
      },
      downloadExcelMeta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      crmUrl: process.env.VUE_APP_CRM_URL
    }
  },
  created () {
    const { projectId } = this.$route.params
    const projectsArray = this.$store.getters.getProjects
    this.$store.dispatch('getProjectById', { projectId, projectsArray })
    this.$store.dispatch('fetchCustomerById', projectId.customerId)
    const projectElement = projectsArray.find((element) => element.id === parseInt(projectId))
    this.markerPosition = { lat: projectElement.marcador.lat, lng: projectElement.marcador.lng }
    this.center = { lat: projectElement.marcador.lat, lng: projectElement.marcador.lng }
    window.scrollTo(0, 0)
  },
  filters: {
    moment (string) {
      return moment(string).format('DD/MM/YYYY')
    }
  },
  computed: {
    ...mapGetters(['getProject', 'getCustomer']),

    hoursSpent: function () {
      let totalHours = 0
      const parentActivities = this.getProject.activities.filter((a) => !a.parentId)
      parentActivities.forEach((a) => { totalHours += Number(a.hoursSpent) })
      return totalHours
    },
    budgetedHoursSum: function () {
      let totalHours = 0

      const project = JSON.parse(JSON.stringify(this.getProject))

      totalHours = project.activities.reduce((acc, { budgetedHours, crmId }) => {
        if (!activityIsParent(crmId, project.activities)) {
          return acc + Number(budgetedHours)
        }
        return acc
      }, 0)

      return totalHours
    },
    getCustomers: function () {
      if (this.getProject.customer && this.getProject.customer.length > 0) {
        const customerNames = this.getProject.customer.map(e => e.name).join(', ')
        return customerNames
      } else {
        return 'No hay invitados.'
      }
    }
  },
  watch: {
    'getProject.activities' (val) {
      this.registeredHours = this.getRegistries(val)
    }
  },
  methods: {
    getChildrenActivities (activity) {
      return this.activities.filter(a => a.parentId === activity.id)
    },
    getRegistries (activities) {
      let registeredHours = []

      activities.forEach((a) => {
        if (a.activities?.length) {
          registeredHours.push(this.getRegistries(a.activities))
        } else if (a.registries?.length) {
          a.registries.forEach(function (r) {
            r.activityName = a.name
            registeredHours.push(r)
          })
        }
      })

      registeredHours = registeredHours.sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())

      return registeredHours
    },
    viewActivityRegistry (node) {
      const { activities } = this.getProject
      if (!activities.find(a => a.parentId === node.id)) {
        this.selectedActivity = node
        this.selectedActivityRegistries = node?.registries || []
      } else {
        this.selectedActivity = undefined
        this.selectedActivityRegistries = []
      }
    },
    createActivityRegistry () {
      const { selectedActivity } = this
      const { projectId } = this.$route.params
      this.$router.push({ path: '/projects/activity/create', query: { projectId, activityId: selectedActivity?.id } })
    },
    handleBack () {
      this.$router.push('/projects')
    },
    handleEditProject () {
      this.$router.push(`/projects/${this.$route.params.projectId}/update`)
    },
    toggleShowDownloadPDFModal (show) {
      if (show) {
        this.$bvModal.show('download-pdf')
      } else {
        this.$bvModal.hide('download-pdf')
      }
    },
    handleClickDownloadPDF () {
      this.toggleShowDownloadPDFModal(true)
    },
    handleClickCancelDownloadPDF () {
      this.toggleShowDownloadPDFModal(false)
    },
    handleSubmitDownloadPDF (project) {
      this.$store.dispatch('downloadPDF', { project, showModalCb: this.toggleShowDownloadPDFModal })
    },
    toggleShowUploadFileModal (show) {
      if (show) {
        this.$bvModal.show('upload-file')
      } else {
        this.$bvModal.hide('upload-file')
      }
    },
    toggleShowLinkModal (show) {
      if (show) {
        Swal.fire({
          title: 'Añadir Enlace',
          // Codigo HTML customizado que se muestra en la alerta Es un formulario con los campos customizables.
          html: `
                  <div>
                      <label for="input_nombre" style="width: 150px;">Nombre:</label>
                      <input type="text" id="input_nombre" class="swal2-input" placeholder="Nombre" value="">
                  </div>
                  <div>
                      <label for="input_link" style="width: 150px;">Link:</label>
                      <input type="url" id="input_link" class="swal2-input" placeholder="Link" value="https://">
                  </div>
                  `,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Añadir',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          width: 630,
          preConfirm: () => {
            const nombre = Swal.getPopup().querySelector('#input_nombre').value
            const link = Swal.getPopup().querySelector('#input_link').value
            return { nombre: nombre, link: link }
          }
        }).then((result) => {
          this.loader = true
          if (result.isConfirmed) {
            const formData = new URLSearchParams()
            formData.append('action', 'upload_link')
            formData.append('name', result.value.nombre)
            formData.append('link', result.value.link)
            formData.append('project', this.getProject.crmId)
            axios
              .post(process.env.VUE_APP_BACKEND_ENTRYPOINT, formData)
              .then(response => {
                this.loader = false
                const newEnlace = {
                  nombre: result.value.nombre,
                  link_id: response.data,
                  url: result.value.link
                }
                this.getProject.enlaces.push(newEnlace)
                Swal.fire({
                  text: 'Enlace añadido correctamente',
                  icon: 'success',
                  timer: 2000
                })
              }).catch(error => {
                this.loader = false
                console.error('Error añadiendo enlace:', error)
              })
          }
        })
      }
    },
    handleClickUploadFile (uploadFileModule, uploadFileItem) {
      this.uploadFilesModule = uploadFileModule
      this.uploadFilesId = uploadFileItem.crmId
      this.uploadFileItem = uploadFileItem
      this.toggleShowUploadFileModal(true)
    },
    handleClickUploadLink (uploadLinkModule, uploadLinkNameItem, uploadLinkItem) {
      this.uploadFilesModule = uploadLinkModule
      this.uploadLinkItem = uploadLinkItem
      this.uploadLinkNameItem = uploadLinkNameItem
      this.toggleShowLinkModal(true)
    },
    handleClickCancelUploadFile () {
      this.toggleShowUploadFileModal(false)
    },
    handleSubmitUploadFile (files, activity) {
      const projectsArray = this.$store.getters.getProjects
      this.$store.dispatch('relateDocuments', { files, uploadFilesModule: this.uploadFilesModule, moduleId: this.uploadFilesId, moduleItem: this.uploadFileItem, showModalCb: this.toggleShowUploadFileModal, projectsArray, activity })
    },
    deleteDocument (fileId) {
      const projectsArray = this.$store.getters.getProjects
      const { projectId } = this.$route.params
      const project = this.getProject
      this.$store.dispatch('unRelateDocument', { id: fileId, projects: projectsArray, project })
    },
    deleteLink (linkId) {
      this.loader = true
      const formData = new URLSearchParams()
      formData.append('action', 'delete_link')
      formData.append('linkId', linkId)
      axios
        .post(process.env.VUE_APP_BACKEND_ENTRYPOINT, formData)
        .then(response => {
          this.loader = false
          this.getProject.enlaces = this.getProject.enlaces.filter(enlace => enlace.link_id !== linkId)
          Swal.fire({
            text: 'Enlace borrado correctamente',
            icon: 'success',
            timer: 2000
          })
        }).catch(error => {
          this.loader = false
          console.error('Error borrando enlace:', error)
        })
    },
    cloneArray (array) {
      return JSON.parse(JSON.stringify(array))
    }
  }
}
</script>

<style scoped lang="scss">
.ti-actions {
    display: none !important;
  }

.activities {
  margin-top: .60rem;

  p {
    margin: 0;
    color: black;
    font-weight: bold;
  }

  span {
    display: inline-block;
    font-size: .75rem;
    margin-bottom: .60rem;
  }
}
p {
  margin-bottom: .60rem;
  color: black;
  font-weight: bold;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    color: #3b3f5c;
    font-weight: normal;
  }
}

progress {
  border-radius: 0.5rem;
  background-color: #b2b2b2;
  width: 150px;
  height: 8px;
}

progress::-webkit-progress-bar {
  background-color: #b2b2b2;
  border-radius: 0.5rem;
}

progress::-webkit-progress-value {
  background-color: black;
  border-radius: 0.5rem;
}

\deep\ .paper__root {
  height: 100%;
}

.buttons-groups {
  display: inline-block;
}

.actions-dropdown {
  display: none;
}

@media (max-width: 1440px) {
  .buttons-groups {
    display: none;
  }

  .actions-dropdown {
    display: inline-block;
  }
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.loader {
  width: 50px;
  height: 50px;
}
</style>
