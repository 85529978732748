<template>
  <div class="footer__root">
    <p>Copyright © 2023 <a href="https://activalink.com/" target="_blank">ACTIVALINK S.L</a>, All rights reserved.</p>
  </div>
</template>

<script>
export default {
  name: 'PageFooter'
}
</script>

<style src="./_PageFooter.scss" lang="scss" scoped />
