<template>
  <b-button v-bind="attributes" type="submit">
    {{ context.label }}
  </b-button>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'

export default {
  name: 'BootstrapButton',
  mixins: [
    FormulateInputMixin
  ]
}
</script>

<style scoped>

</style>
