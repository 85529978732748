import { render, staticRenderFns } from "./TreeViewItem.vue?vue&type=template&id=f67835d4&scoped=true&"
import script from "./TreeViewItem.vue?vue&type=script&lang=ts&"
export * from "./TreeViewItem.vue?vue&type=script&lang=ts&"
import style0 from "./_TreeView.scss?vue&type=style&index=0&id=f67835d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f67835d4",
  null
  
)

export default component.exports