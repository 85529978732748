export interface ActividadesModuleState {
  actividades: any[]
  actividad: any
}

export const state: ActividadesModuleState = {
  actividades: [],
  actividad: null
}

export default state
