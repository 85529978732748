<template>
  <div class="d-flex justify-content-center align-items-center" style="height: 70vh;">
    <div :class="width">
      <Paper title="Login Utmac">
        <FormulateForm name="login-user" @submit="handleSubmit" v-model="form">
          <b-container>
            <b-row class="justify-content-center align-items-center">
              <b-col md="12" lg="8">
                <FormulateInput name="email" label="Correo" validation="required" type="text" placeholder="Correo" />
              </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center">
              <b-col md="12" lg="8">
                <label for="contraseña">Contraseña</label>
                <input name="contraseña" id="contraseña" class="form-control" type="password" placeholder="Contraseña"
                  required>
              </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center">
              <b-col md="12" lg="2">
                <FormulateInput class="mt-1" style="display: flex; justify-content: center;" type="submit"
                  variant="primary" label="Entrar" />
              </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center mt-2">
              <b-col md="12" lg="5">
                <div style="display: flex; justify-content: center;">
                  <a href="#/recover">¿Olvidaste la contraseña?</a>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </FormulateForm>
      </Paper>
    </div>
  </div>
</template>

<script>
import { Paper } from '@/components'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'SelectDepartmentPage',
  components: { Paper },
  data () {
    return {
      form: {}
    }
  },
  methods: {
    handleSubmit () {
      const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT
      const inputContraseña = document.getElementById('contraseña')
      const valorContrasenya = inputContraseña.value
      const formData = new FormData()
      formData.append('action', 'check_credentials')
      formData.append('email', this.form.email)
      formData.append('password', valorContrasenya)
      axios
        .post(entryPoint, formData)
        .then((response) => {
          console.log(response.data)
          localStorage.setItem('is_admin', response.data.is_admin)
          localStorage.setItem('id', response.data.id)
          localStorage.setItem('logged', response.data.logged)
          localStorage.setItem('username', response.data.username)
          localStorage.setItem('funcion', response.data.funcion)
          if (response.data.logged) {
            this.$router.push('projects')
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Usuario o contraseña incorrectos',
              showConfirmButton: false,
              timer: 1500
            })
          }
        })
    }
  },
  computed: {
    width () {
      return document.body.clientWidth < 1000 ? 'w-70' : 'w-50'
    }
  }
}
</script>
<style>
.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container h2 {
  margin-right: 40px;
  font-size: 30px;
}
</style>
