<template>
  <div>
    <b-modal
      id="check-disponibilidad"
      size="lg"
      title="Consultar disponibilidad voluntarios"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      centered>
      <FormulateInput
        v-for="(campo, id) in camposFiltro"
        :key="id"
        :label="campo.label"
        track-by="id"
        validation="required"
        type="autocomplete"
        :options="campo.datos ? campo.datos : []"
        :multiple="true"
        :preserve-search="true"
        :close-on-select="false"
        :clear-on-select="false"
        v-model="filtro[campo.field]"
      />
      <template #modal-footer>
        <p>Una busqueda sin parámetros mostrará la BBDD completa</p>
        <b-button
            size="sm"
            style="margin-right: .5rem"
            @click="handleCancel">
            Cancelar
          </b-button>
        <b-button
          variant="primary"
          size="sm"
          @click="handleSubmit">
          Buscar invitados
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CheckDisponibilidad',
  data () {
    return {
      filtro: {}
    }
  },
  props: ['camposFiltro'],
  methods: {
    handleSubmit () {
      this.$emit('submit', this.filtro)
    },
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>
