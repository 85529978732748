import { GET_USERS, GET_CURRENT_USER, GET_USER } from './mutations'
import axios from 'axios'
import qs from 'qs'

const entryPoint = process.env.VUE_APP_BACKEND_ENTRYPOINT

export const fetchUsers = ({ commit }: any) => {
  let users
  const data = qs.stringify({
    action: 'get_users'
  })

  const config: any = {
    method: 'post',
    url: entryPoint,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  }

  axios(config)
    .then(response => {
      users = response.data

      commit(GET_USERS, users)
    })
}

export const getUserById = ({ commit }: any, params) => {
  const users: any[] = params.usersArray
  const user = users.find(u => u.id === params.userId)

  commit(GET_USER, user)
}

export const getCurrentUsers = ({ commit }: any) => {
  let currentUser

  const data = qs.stringify({
    action: 'get_current_users'
  })

  const config: any = {
    method: 'post',
    url: entryPoint,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  }

  axios(config)
    .then(response => {
      currentUser = {
        id: response.data.id,
        label: '',
        name: response.data.name,
        value: ''
      }

      commit(GET_CURRENT_USER, currentUser)
    })
}

export default {
  fetchUsers,
  getCurrentUsers,
  getUserById
}
