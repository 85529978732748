<template>
  <div class="activity-tree">
    <ul>
      <template v-for="node in nodes">
        <ActivityTreeNode
          :key="node.id"
          :nodes="nodes"
          :node="node"
          v-if="!node.parentId"
          v-on:click="handleClickNode"
        />
      </template>
    </ul>
  </div>
</template>

<script>
import ActivityTreeNode from './ActivityTreeNode'

export default {
  name: 'ActivityTree',
  components: { ActivityTreeNode },
  props: ['nodes'],
  methods: {
    handleClickNode (node) {
      this.$emit('click-node', node)
    }
  }
}
</script>

<style scoped lang="scss">
.activity-tree {
  ul {
    position: relative;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    overflow: hidden;
  }
}
.node-reference {
  color: gray;
}
</style>
